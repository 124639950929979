import { searchActions } from '../search'
import { jobConstants, jobService } from './' 

export const jobActions = {
    getJob,
    getAllJobs,
    getSkillAIJobs
}

function getJob(greenhouse_public_id) {
    return dispatch => {
        dispatch(request())
        jobService.getJob(greenhouse_public_id).then(
            data => {
                dispatch(success(data))
            }
        ).catch(
            error => {
                dispatch(failure(error))
            }
        )
    }
    
    function request(){return {type: jobConstants.GET_JOB_REQUEST, }}
    function success(data){return {type: jobConstants.GET_JOB_SUCCESS, data}}
    function failure(error){return {type: jobConstants.GET_JOB_FAILURE, error}}
}

function getAllJobs(){
    return dispatch => {
        dispatch(request())

        jobService.getAllJobs().then(
            data => {
                dispatch(success(data))
            }
        ).catch(
            error => {
                dispatch(failure(error))
            }
        )
    }
    
    function request() {return {type: jobConstants.GET_ALL_JOBS_REQUEST, }}
    function success(data){return {type: jobConstants.GET_ALL_JOBS_SUCCESS, data}}
    function failure(error){return {type: jobConstants.GET_ALL_JOBS_FAILURE, error}}
}

function getSkillAIJobs(jidsList, allJobs) {
    // If the AI returned no job IDs, just return all jobs instead
    if (jidsList.length === 0) {
        return dispatch => {
            dispatch(request())
            dispatch(success({
                ...allJobs,
                // resume was uploaded to skills ai
                uploadedResume: true,
                // adds message saying no recommended jobs
                noRecommended: true
            }))
        }
    }

    return dispatch => {
        dispatch(request())

        jobService.getSkillAIJobs(jidsList).then(
            data => {
                // Return all jobs with the recommended jobs first
                let recJobs = data.jobs
                let jobs = recJobs.concat(allJobs.filter(job => {
                    // Make sure there are no duplicates of the recommended jobs
                    // Adds all other jobs for future viewing
                    for (let recJob of recJobs) {
                        if (job.greenhouse_public_id === recJob.greenhouse_public_id) {
                            return false
                        }
                    }
                    return true
                }))

                data.jobs = jobs

                dispatch(success({
                    ...data,
                    // Resume was uploaded to skills ai
                    // This makes the 'match your resume' button disappear
                    uploadedResume: true
                }
                ))
                dispatch(searchActions.setSearchQueries({
                    // Filter out jobs from view that are not recommended
                    showRecJobs: true
                }))
                dispatch(searchActions.searchJobs(jobs, {showRecJobs: true}))
            }
        ).catch(
            error => {
                dispatch(failure(error))
            }
        )
    }

    function request() { return { type: jobConstants.GET_ALL_JOBS_REQUEST, } }
    function success(data) { return { type: jobConstants.GET_ALL_JOBS_SUCCESS, data } }
    function failure(error) { return { type: jobConstants.GET_ALL_JOBS_FAILURE, error } }
}