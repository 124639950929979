import axios from 'axios';

import { API_ROOT } from '../../settings';

export const applicationService = {
    applyToJob,
};

async function applyToJob(greenhouse_public_id, data, eeoc) {
    console.log('Checkpoint.');
    let payload = new FormData();

    payload.append('resume', data.resume);
    payload.append('first_name', data.first_name);
    payload.append('last_name', data.last_name);
    payload.append('email', data.email);
    payload.append('phone', data.phone);
    payload.append('us_eligible', data.USeligibile);
    payload.append('visa_required', data.visa);
    payload.append('gender', eeoc.gender);
    payload.append('race', eeoc.ethnicity);
    payload.append('disability_status', eeoc.disability);
    payload.append('veteran_status', eeoc.veteran);

    let query = new URLSearchParams(data.param);
    let referralToken = query.get('gh_src');

    if (referralToken) {
        payload.append('mapped_url_token', referralToken);
    }

    let appKeyResponse = await axios.get(`${API_ROOT}/requestAppKey`);

    let key = appKeyResponse.data;
    let encodedKey = Buffer.from(`${key}:`).toString('base64');

    let response = await axios.post(
        `${API_ROOT}/application/${greenhouse_public_id}`,
        payload,
        {
            headers: {
                Authorization: `Basic ${encodedKey}`,
            },
        }
    );

    return response.data;
}
