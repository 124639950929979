import React from 'react'
export default function AdPixel({ id }) {
    const a = String(Math.floor(Math.random() * 10000000000000000));
    const imgSrc = `https://pubads.g.doubleclick.net/activity;xsp=${id};ord=${a}?`
    return (
    <>
        <img src={imgSrc} alt="" width="1" height="1" border="0" />
    </>
    )
}
