import { resumeConstants } from './'

export const resumeActions = {
    setResume,
}

function setResume(blobURL, file) {
    return dispatch => {
        let data = {
            blobURL: blobURL,
            fileName: file.name,
            fileModified: file.lastModified
        }
        dispatch(success(data))
    }

    function success(data){ return {type: resumeConstants.SET_RESUME, data}}
}