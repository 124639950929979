import { Button } from '@mui/material';
import React, { useState } from 'react'

export function FileUpload(props) {
    const [dragging, setDragging] = useState(false)
    const [dragCounter, setDragCounter] = useState(0)

    const handleDrag = (e) => {
        e.preventDefault()
        e.stopPropagation()
    }

    const handleDragIn = (e) => {
        e.preventDefault()
        e.stopPropagation()
        setDragCounter(dragCounter + 1)
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            setDragging(true)
        }
    }

    const handleDragOut = (e) => {
        e.preventDefault()
        e.stopPropagation()
        if (dragCounter === 1) {
            setDragging(false)
        }
        setDragCounter(dragCounter - 1)
    }

    const handleDrop = (e) => {
        e.preventDefault()
        e.stopPropagation()
        setDragging(false)
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            let file = e.dataTransfer.files[0]
            if (props.accept) {
                if (props.accept.includes(file.type)) {
                    props.handleUpload(e.dataTransfer.files[0])
                }
            }
            else {
                props.handleUpload(e.dataTransfer.files[0])
            }
            e.dataTransfer.clearData()
            setDragCounter(0)
        }
    }

    const handleUpload = async (e) => {
        let file = e.target.files[0]
        if (file) {
            props.handleUpload(file)
        }
    }

    return (
        <Button component="label" style={{ padding: 0, width: props.fullWidth && '100%' }}>
            <div
                style={{ display: 'inline-block', position: 'relative' }}

                onDragEnter={handleDragIn}
                onDragOver={handleDrag}
                onDrop={handleDrop}
                onDragLeave={handleDragOut}
            >
                <input type="file" name="file" accept={props.accept} hidden onChange={handleUpload} />
                {dragging &&
                    <div
                        style={{
                            border: 'dashed grey 4px',
                            backgroundColor: 'rgba(255,255,255,.8)',
                            position: 'absolute',
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                            zIndex: 9999
                        }}
                    >
                        <div
                            style={{
                                position: 'absolute',
                                top: '25%',
                                right: 0,
                                left: 0,
                                textAlign: 'center',
                                color: 'grey',
                                fontSize: 36
                            }}
                        >
                            <div>drop here</div>
                        </div>
                    </div>
                }
                {props.children}
            </div>
        </Button>
    )
}