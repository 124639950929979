import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunkMiddleware from 'redux-thunk'
import { createLogger } from 'redux-logger'

import { ENV } from '../settings'
import rootReducer from './'

const loggerMiddleWare = createLogger()
let middleware = applyMiddleware(thunkMiddleware, loggerMiddleWare)
let store = createStore(rootReducer, composeWithDevTools(middleware))

if (ENV === 'production'){
    middleware = applyMiddleware(thunkMiddleware)
    store = createStore(rootReducer, middleware)
}

export default store