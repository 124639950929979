import { useRef, useState } from 'react';
import {
    AppBar as MuiAppBar,
    Button,
    ClickAwayListener,
    Collapse,
    Dialog,
    DialogContent,
    Divider,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Popper,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Close, KeyboardArrowDown, KeyboardArrowUp, Menu } from '@mui/icons-material';
import LPIcon from '../assets/LPIcon';
import { colors } from '../theme/colors';
import Commerce from '../assets/navbar/commerce.svg';
import Care from '../assets/navbar/care.svg';
import Telco from '../assets/navbar/telco.svg';
import Healthcare from '../assets/navbar/healthcare.svg';
import Finserv from '../assets/navbar/finserv.svg';
import Retail from '../assets/navbar/retail.svg';
import Travel from '../assets/navbar/travel.svg';
import Automotive from '../assets/navbar/automotive.svg';
import Crypto from '../assets/navbar/crypto.svg';
import ArrowExternal from '../assets/navbar/arrow-external.svg';
import ConversationalCloud from '../assets/navbar/convCloud.png';
import Voice from '../assets/navbar/voice.png';
import BellaHealth from '../assets/navbar/bellaHealth.png';
import SuccessStories from '../assets/navbar/successStories.png';

export default function NavBar(props) {
    const { width } = props;
    const classes = useStyles();

    const [mobileOpen, setMobileOpen] = useState(false);
    let isMobile = width < 1080;

    const [open, setOpen] = useState(-1);

    const contentList = [
        {
            content: solutionsContent,
            title: 'Solutions',
        },
        {
            content: successContent,
            title: 'Success Services',
        },
        {
            content: resourcesContent,
            title: 'Resources',
        },
        {
            content: humanContent,
            title: 'Curiously Human AI',
        },
        {
            content: companyContent,
            title: 'About',
        },
    ];

    if (isMobile) {
        return (
            <MuiAppBar position="static" elevation={0} color="transparent">
                <div
                    className={mobileOpen && classes.mobileMenuContainer}
                    style={{ paddingLeft: props.margin, paddingRight: props.margin }}
                >
                    <Grid
                        container
                        style={{ height: '72px' }}
                        justifyContent="space-between"
                        alignItems="center"
                        direction="row"
                    >
                        <Grid item>
                            <a className={classes.lpLink} href="https://www.liveperson.com">
                                <LPIcon />
                            </a>
                        </Grid>
                        <Grid item>
                            <IconButton onClick={() => setMobileOpen(!mobileOpen)} size="large">
                                {mobileOpen ? <Close className={classes.icon} /> : <Menu className={classes.icon} />}
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Dialog fullScreen open={mobileOpen} onClose={() => setMobileOpen(false)}>
                        <DialogContent style={{ padding: '12px ' + props.margin }}>
                            <div className={classes.mobileDialogTitleBar}>
                                <a className={classes.lpLink} href="https://www.liveperson.com">
                                    <LPIcon />
                                </a>
                                <IconButton onClick={() => setMobileOpen(false)} size="large">
                                    <Close style={{ color: colors.webKit.primary.text }} />
                                </IconButton>
                            </div>
                            <List>
                                {/*contentList.map((tab, i) => <NavMenuMobile key={i} content={tab.content} title={tab.title} {...props} />)*/}
                                <ListItem style={{ paddingLeft: 0, paddingRight: 0 }}>
                                    <ListItemText>
                                        <a
                                            className={classes.signInLinkMobile}
                                            href="https://authentication.liveperson.net/login.html"
                                        >
                                            Sign in
                                        </a>
                                    </ListItemText>
                                </ListItem>
                            </List>
                        </DialogContent>
                    </Dialog>
                </div>
            </MuiAppBar>
        );
    }

    return (
        <MuiAppBar position="static" elevation={0} color="transparent">
            <div style={{ maxWidth: '1920px', marginLeft: props.margin, marginRight: props.margin }}>
                <Grid container justifyContent="space-between" alignItems="center" direction="row">
                    <Grid item>
                        <a className={classes.lpLink} href="https://www.liveperson.com">
                            <LPIcon />
                        </a>
                    </Grid>
                    <div style={{ display: 'flex' }}>
                        {/*contentList.map((tab, index) =>
                            <NavMenu key={index} content={tab.content} title={tab.title} open={open === index} setTab={setOpen} index={index} {...props} />
                        )*/}
                    </div>
                    <Grid item>
                        <a className={classes.lpLink} href="https://authentication.liveperson.net/login.html">
                            <Button variant="outlined" color="secondary" style={{ padding: '10px 20px' }}>
                                Sign in
                            </Button>
                        </a>
                    </Grid>
                </Grid>
            </div>
        </MuiAppBar>
    );
}

const useStyles = makeStyles(() => ({
    title: {
        padding: '24px 0px 24px 16px',
    },
    menu1: {
        textTransform: 'uppercase',
        paddingBottom: '12px',
    },
    menu2: {
        fontFamily: 'Space Grotesk',
        fontWeight: 700,
    },
    menu1Mobile: {
        color: colors.webKit.primary.text,
        fontSize: '1rem',
        fontWeight: 300,
        lineHeight: '1.8em',
    },
    menuLinkMobile: {
        fontSize: '1rem',
        fontWeight: 700,
        lineHeight: '1.8em',
    },
    page: {
        maxWidth: '220px',
        whiteSpace: 'pre-wrap',
        padding: '10px',
        '&:hover': {
            backgroundColor: colors.webKit.primary.veryLightGrey,
        },
    },
    largeIconPage: {
        backgroundColor: 'white',
        maxWidth: '320px',
        whiteSpace: 'pre-wrap',
        padding: '10px',
        margin: '10px',
        borderRadius: 100,
        '&:hover': {
            backgroundColor: colors.webKit.primary.grey,
        },
    },
    link: {
        textDecoration: 'none',
        color: colors.webKit.primary.text,
    },
    pointer: {
        transform: 'translate(2em, 0px)',
        width: 0,
        height: 0,
        borderLeft: '12px solid transparent',
        borderRight: '12px solid transparent',
        borderBottom: '10px solid ' + 'white',
    },
    menuContainer: {
        backgroundColor: 'white',
        display: 'flex',
    },
    mobileMenuContainer: {
        backgroundColor: 'white',
        boxShadow: '3px 3px 6px -4px ' + colors.webKit.navys.navy,
        zIndex: 1000,
        position: 'absolute',
        left: '0%',
        top: '0%',
        right: '0px',
        bottom: '0%',
    },
    mobileMenuTitleContainer: {
        paddingLeft: 0,
        paddingRight: 0,
    },
    signInLinkMobile: {
        textDecoration: 'none',
    },
    icon: {
        color: colors.webKit.primary.text,
    },
    mobileDialogTitleBar: {
        display: 'flex',
        justifyContent: 'space-between',
        color: colors.webKit.primary.text,
        alignItems: 'center',
    },
    lpLink: {
        textDecoration: 'none',
        display: 'flex',
        alignItems: 'center',
    },
}));

function NavMenu(props) {
    const { title, content, open, setTab, index } = props;
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const ref = useRef();

    return (
        <Grid item style={{ padding: 0 }}>
            <div
                onClick={() => {
                    // set the current open popper to this menu
                    setAnchorEl(ref.current);
                    setTab(index);
                }}
            >
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Typography ref={ref} className={classes.title} variant="subtitle2">
                        {' '}
                        {title}{' '}
                    </Typography>
                    {open ? (
                        <KeyboardArrowUp className={classes.icon} />
                    ) : (
                        <KeyboardArrowDown className={classes.icon} />
                    )}
                </div>
                <Popper open={open} anchorEl={anchorEl} placement={'top-start'}>
                    <ClickAwayListener
                        onClickAway={() =>
                            // if clicking anywhere outside of the popper (menu title, other menu title, outside of menu, etc.)
                            // then close the popper if another was not opened
                            setTimeout(() => {
                                if (open) setTab(-1);
                            }, 10)
                        }
                    >
                        <div className={classes.menuContainer}>
                            {content.map((column, columnIndex) => (
                                <div style={{ display: 'block' }} key={'column-' + columnIndex}>
                                    {column.map((section, sectionIndex) => (
                                        <div
                                            key={'section-' + sectionIndex}
                                            style={{
                                                padding: '40px 30px 48px 30px',
                                                backgroundColor: section.greyBox && colors.webKit.primary.veryLightGrey,
                                            }}
                                        >
                                            {section.title && (
                                                <Typography className={classes.menu1} variant="h6">
                                                    {section.title}
                                                </Typography>
                                            )}
                                            {section.title === ' ' && <div style={{ height: '20px' }} />}
                                            {section.pages.map((page, pageIndex) => (
                                                <div
                                                    key={pageIndex}
                                                    className={page.largeIcon ? classes.largeIconPage : classes.page}
                                                >
                                                    <a href={page.link} className={classes.link} key={page.label}>
                                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                            <img src={page.largeIcon} style={{ margin: '6px' }} />
                                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        flexDirection: 'row',
                                                                    }}
                                                                >
                                                                    {page.icon && (
                                                                        <img
                                                                            src={page.icon}
                                                                            style={{ paddingRight: '4px' }}
                                                                        />
                                                                    )}
                                                                    <Typography
                                                                        variant="subtitle2"
                                                                        className={classes.menu2}
                                                                    >
                                                                        {page.label}
                                                                    </Typography>
                                                                    {page.external && (
                                                                        <img
                                                                            src={ArrowExternal}
                                                                            style={{
                                                                                marginLeft: '4px',
                                                                                height: 12,
                                                                                width: 12,
                                                                            }}
                                                                        />
                                                                    )}
                                                                </div>
                                                                <Typography variant="caption">
                                                                    {page.description}
                                                                </Typography>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            ))}
                                            {
                                                // display an extra space between sections, unless it
                                                // is the end of a column
                                                sectionIndex !== column.length - 1 && <p></p>
                                            }
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </ClickAwayListener>
                </Popper>
            </div>
        </Grid>
    );
}

function NavMenuMobile(props) {
    const { title, content } = props;
    const [open, setOpen] = useState(false);
    const classes = useStyles();

    return (
        <>
            <ListItem
                onClick={() => setOpen(!open)}
                className={classes.mobile}
                className={classes.mobileMenuTitleContainer}
                key={title}
            >
                <ListItemText>
                    <Typography variant="subtitle2">{title}</Typography>
                </ListItemText>
                <ListItemIcon>
                    {open ? (
                        <KeyboardArrowUp className={classes.icon} />
                    ) : (
                        <KeyboardArrowDown className={classes.icon} />
                    )}
                </ListItemIcon>
            </ListItem>
            <Collapse in={open}>
                <Divider light />
                {content.map((column, index) => (
                    <>
                        {column.map((section, section_index) => (
                            <>
                                {section.title && section.title !== ' ' && index !== 0 && <Divider />}
                                {section.title && (
                                    <Typography className={classes.menu1} variant="h6">
                                        {section.title}
                                    </Typography>
                                )}
                                {section.pages.map((page) => (
                                    <div
                                        className={page.largeIcon ? classes.largeIconPage : classes.page}
                                        style={{ maxWidth: '100%' }}
                                    >
                                        <a href={page.link} className={classes.link} key={page.label}>
                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                <img src={page.largeIcon} style={{ margin: '6px' }} />
                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            flexDirection: 'row',
                                                        }}
                                                    >
                                                        {page.icon && (
                                                            <img src={page.icon} style={{ paddingRight: '4px' }} />
                                                        )}
                                                        <Typography variant="subtitle2" className={classes.menu2}>
                                                            {page.label}
                                                        </Typography>
                                                        {page.external && (
                                                            <img
                                                                src={ArrowExternal}
                                                                style={{ marginLeft: '4px', height: 12, width: 12 }}
                                                            />
                                                        )}
                                                    </div>
                                                    <Typography variant="caption">{page.description}</Typography>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                ))}
                            </>
                        ))}
                    </>
                ))}
            </Collapse>
            <Divider light />
        </>
    );
}

export const solutionsContent = [
    [
        {
            title: 'By Need',
            footerTitle: 'Solutions By Need',
            pages: [
                {
                    label: 'Commerce',
                    link: 'https://www.liveperson.com/solutions/conversational-commerce/',
                    icon: Commerce,
                    description: 'Increase revenue with Conversational Commerce and marketing experiences',
                },
                {
                    label: 'Customer Care',
                    link: 'https://www.liveperson.com/solutions/customer-care/',
                    icon: Care,
                    description: 'Deliver personalized customer service at scale by understanding customers’ intent',
                },
            ],
        },
    ],
    [
        {
            title: 'By Industry',
            footerTitle: 'Solutions By Industry',
            pages: [
                {
                    label: 'Telco & Cable',
                    link: 'https://www.liveperson.com/solutions/telco/',
                    icon: Telco,
                    description: 'Maximize retention, minimize costs with Conversational AI and chatbots in telecom',
                },
                {
                    label: 'Healthcare',
                    link: 'https://www.liveperson.com/solutions/healthcare/',
                    icon: Healthcare,
                    description: 'Streamline call center operations, improve CSAT with healthcare chatbot technology',
                },
                {
                    label: 'Financial Services',
                    link: 'https://www.liveperson.com/solutions/finserv/',
                    icon: Finserv,
                    description: 'Proactively support customers with Conversational AI for financial services',
                },
                {
                    label: 'Retail',
                    link: 'https://www.liveperson.com/solutions/retail/',
                    icon: Retail,
                    description: 'Transform the in-store and ecommerce customer experience at scale',
                },
            ],
        },
    ],
    [
        {
            title: ' ',
            pages: [
                {
                    label: 'Travel & Hospitality',
                    link: 'https://www.liveperson.com/solutions/travel/',
                    icon: Travel,
                    description: 'Guide travelers through their journey with Conversational AI and a chatbot assistant',
                },
                {
                    label: 'Automotive',
                    link: 'https://www.livepersonautomotive.com/',
                    icon: Automotive,
                    description: 'Improve car shopping and servicing with automotive chat and messaging tools',
                    external: true,
                },
                {
                    label: 'Decentralized Finance',
                    link: 'https://www.liveperson.com/solutions/decentralized-finance/',
                    icon: Crypto,
                    description: 'Personalize and scale customer service for crypto and DeFi communities',
                },
            ],
        },
    ],
    [
        {
            title: 'Products',
            greyBox: true,
            pages: [
                {
                    label: 'Conversational Cloud',
                    link: 'https://www.liveperson.com/products/conversational-cloud/',
                    largeIcon: ConversationalCloud,
                    description:
                        'Deliver personalized digital experiences with an AI platform that identifies customer intent',
                },
                {
                    label: 'Voice',
                    link: 'https://www.liveperson.com/products/voice/',
                    largeIcon: Voice,
                    description:
                        'Improve CX with voice analytics, create better experiences, do more with Conversational AI',
                },
                {
                    label: 'BELLA Health',
                    link: 'https://www.liveperson.com/products/bella-health/',
                    largeIcon: BellaHealth,
                    description: 'Increase confidence in COVID health and safety with our BELLA Health™ program',
                },
                {
                    label: 'Successs Stories',
                    link: 'https://www.liveperson.com/services/success-management/',
                    largeIcon: SuccessStories,
                    description: 'Our team of experts co-innovate with you to define and deliver success your way',
                },
            ],
        },
    ],
];

export const successContent = [
    [
        {
            footerTitle: 'Success Services',
            pages: [
                {
                    label: 'Professional Services',
                    link: 'https://www.liveperson.com/services/professional-services/',
                    description: 'Our professional services experts prepare you to engage in the conversational world',
                },
                {
                    label: 'Gainshare',
                    link: 'https://www.liveperson.com/company/careers/#:~:text=Gainshare,operations%20at%20scale',
                    description:
                        'Our fully managed contact center solution transforms your Conversational operations at scale',
                },
                {
                    label: 'Success Management',
                    link: 'https://www.liveperson.com/services/success-management/',
                    description: 'We understand your business and partner with you to drive ROI with our solutions',
                },
                {
                    label: 'Technical Support',
                    link: 'https://www.liveperson.com/services/technical-support/',
                    description:
                        'Our technical support is always on, delivering 24/7 help through trusted Conversational AI',
                },
            ],
        },
    ],
];

export const resourcesContent = [
    [
        {
            footerTitle: 'Resources',
            pages: [
                {
                    label: 'Developer Center',
                    link: 'https://developers.liveperson.com',
                    external: true,
                },
                {
                    label: 'Knowledge Center',
                    link: 'https://knowledge.liveperson.com',
                    external: true,
                },
                {
                    label: 'Security',
                    link: 'https://www.liveperson.com/resources/security/',
                },
                {
                    label: 'Blog',
                    link: 'https://www.liveperson.com/blog',
                    external: true,
                },
                {
                    label: 'Events',
                    link: 'https://www.liveperson.com/Events',
                },
                {
                    label: 'Customer Case Studies',
                    link: 'https://www.liveperson.com/resources/#success-story',
                    external: true,
                },
            ],
        },
    ],
];

export const humanContent = [
    [
        {
            footerTitle: 'Curiously Human AI',
            pages: [
                {
                    label: 'What is Conversational AI?',
                    link: 'https://www.liveperson.com/conversational-ai/',
                    description:
                        'We combine NLU, NLP, and machine learning to create Curiously Human automated conversations',
                },
            ],
        },
    ],
    [
        {
            pages: [
                {
                    label: 'Data Science',
                    link: 'https://www.liveperson.com/data-science/',
                    description: 'LivePerson data scientists and expert engineers solve difficult business challenges',
                },
            ],
        },
    ],
];

export const companyContent = [
    [
        {
            footerTitle: 'About',
            pages: [
                {
                    label: 'Our Values',
                    link: 'https://www.liveperson.com/company/',
                },
                {
                    label: 'News',
                    link: 'https://www.liveperson.com/news/',
                },
                {
                    label: 'Partners',
                    link: 'https://www.liveperson.com/partners/',
                },
                {
                    label: 'Leadership',
                    link: 'https://www.liveperson.com/company/leadership/',
                },
                {
                    label: 'Investor Relations',
                    link: 'https://ir.liveperson.com/',
                    external: true,
                },
                {
                    label: 'Careers',
                    link: 'https://www.liveperson.com/company/careers',
                },
            ],
        },
    ],
];
