import React, { useEffect } from 'react'
import {
    useDispatch, 
    useSelector,
} from 'react-redux'
import { Button } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import { colors } from "../../theme/colors"
import { candidateActions } from '../../redux/candidate'
import { LoadingIndicator } from '../../components/LoadingIndicator'

const useStyles = makeStyles((theme) => ({
    page : {
        backgroundColor: colors.webKit.transparencies.darkTransparent,
        borderRadius: '16px',
        padding: '2.5% 0% 2.5% 0%'
    },
    body: {
        padding: '0 2%'
    },
    button: {
        borderRadius: '100px',
        padding: '10px 49px',
        fontSize: '20px',
        lineHeight: '24px',
    },
    loading: {
        textAlign: "center",
    }
}))

export function CandidateDeletion(props){
    const classes = useStyles()
    const dispatch = useDispatch()

    const email = props.match.params.email
    const { loading, titleList, error } = useSelector(state => state.queryAvailableAction)
    const { error:deleteFailure, success } = useSelector(state => state.deleteAvailableApplications)

    const submitRequest = async () => {
        dispatch(candidateActions.deleteAvailableData(email))
    }


    const noAction = () => {
        return (
            <div>
                There is no available action for you to take at this time
            </div>
        )
    }

    const completedAction = () => {
        return (
            <div>
                Request for deletion is completed
            </div>
        )
    }

    const availableAction = () => {
        return ( 
            <>
                <div>
                    You have an option to delete your application data for one of the roles in which you've applied for.
                </div>

                <div>
                    Here is a list of all of the roles you've applied for and are eligible to be deleted for.
                </div>

                <div>
                    <ul>
                        {titleList.map((title) => (<li key={title}> {title} </li>))}
                    </ul>
                </div>

                <div>
                    If you would like to delete these applications at this time, click yes below
                </div>
                
                <br/>

                <div>
                    <Button
                        className={classes.button}
                        variant="contained" 
                        color="primary"
                        onClick={submitRequest}
                    > 
                        Yes 
                    </Button>
                </div>
            </>
        )
    }

    const errorMsg = (
        <>
            Network error, try again later
        </> 
    )

    const message = (
        <>
            <h2> Hi, {email} </h2> 
            {error && noAction()}
            { 
                titleList && availableAction()
            }
        </>
    )

    const loadingMsg = (
        <div className={classes.loading}>
            <LoadingIndicator/>
        </div>
    )

    useEffect(() => {
        dispatch(candidateActions.queryAvailableAction(email))
    },[])

    return (
        <div className={classes.page}>
            <div className={classes.body}>
              
                { loading ? loadingMsg : message }
                { deleteFailure && errorMsg }
                { success && completedAction() }

            </div>
        </div>

    )

}