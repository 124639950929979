import { analyticsConstants } from "./constants";

const initialState = {
    savedSearch: {
        categoriesQuery: [],
        careerStageQuery: [],
        cityQuery: [],
        countryQuery: [],
        remoteQuery: false,
        titleQuery: '',
        showRecJobs: null,
        showSmartApply: null,
    },
    loading: false,
    matchClicked: false
}

export function analytics(state = initialState, action) {
    switch (action.type) {
        case analyticsConstants.TRACK_CLICK_JOB_REQUEST:
            return {
                ...state,
                matchClicked: true
            }
        case analyticsConstants.SAVE_SEARCH:
            return {
                ...state,
                savedSearch: {
                    ...initialState.savedSearch,
                    categoriesQuery: [...action.data.categoriesQuery],
                    careerStageQuery: [...action.data.careerStageQuery],
                    cityQuery: [...action.data.cityQuery],
                    countryQuery: [...action.data.countryQuery],
                    remoteQuery: action.data.remoteQuery,
                    titleQuery: action.data.titleQuery,
                    showRecJobs: action.data.showRecJobs,
                    showSmartApply: action.data.showSmartApply,
                }
            }
        default:
            return state
    }
}

/**
 * Sending analytics reducer that keep track of the status of the sending analytic api call to the backend
 * @param {*} state The current state of the reducer
 * @param {*} action Any action that will change the state
 * @returns 
 */
export function sendAnalytics(state = {}, action) {
    switch(action.type){
        case analyticsConstants.SEND_ANALYTICS_REQUEST:
            return {
                loading: true,
                analytic_payload: action.payload 
            }
        case analyticsConstants.SEND_ANALYTICS_SUCCESS:
            return {
                loading: false
            }
        case analyticsConstants.SEND_ANALYTICS_FAILURE:
            return {
                loading: false,
                error: action.error
            }
        default:
            return state
    }
}

/**
 * Requesting new session to the backend to link events to it. (Also create new user if none exists)
 * @param {*} state The current state of the reducer
 * @param {*} action Current action and response from the request_session call
 * @returns 
 */
export function requestSession(state = {}, action){
    switch(action.type){
        case analyticsConstants.SESSION_REQUEST:
            return {
                loading: true,
            }
        case analyticsConstants.SESSION_SUCCESS:
            return {
                loading: false,
                payload: action.data,
            }
        case analyticsConstants.SESSION_FAILURE:
            return {
                loading: false,
                error: action.error
            }
        default:
            return state
    }
}