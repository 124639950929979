import { Collapse, Divider, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Add, Remove } from "@mui/icons-material";
import { useState } from "react";
import { ResumeMatchBanner } from '../components/ResumeMatchBanner'
import { BreadCrumbs } from "../components/BreadCrumbs";
import { colors } from "../theme/colors";
import FAQImage from '../assets/FAQ.svg'

const useStyles = makeStyles(() => ({
    container: {
        margin: "80px 0px"
    },
}))

export function FileUpload(props) {
    const { isMobile } = props;
    const classes = useStyles();

    return (
        <>
            <BreadCrumbs isMobile={isMobile} currentPageTitle="Upload Your Resume" />
            <div className={classes.container}>
                <ResumeMatchBanner isMobile={isMobile} />
            </div>
            <FAQsList isMobile={isMobile} />
        </>
    )
}

const useFAQStyles = makeStyles(() => ({
    titleContainer: {
        textAlign: 'left',
        marginBottom: '32px',
        marginRight: '32px'
    },
    subtitle: {
        fontSize: '20px',
        lineHeight: '28px',
        fontWeight: 300,
        marginTop: '24px'
    },
    questionTitle: {
        fontWeight: 'bold',
        fontSize: '24px',
        lineHeight: '28px',
    },
    expandIcon: {
        color: colors.webKit.primary.text
    },
    answer: {

    }
}))

function FAQsList(props) {
    const { isMobile } = props;
    const classes = useFAQStyles();

    // if (isMobile)
    //     return (<></>)

    return (
        <div style={{ display: isMobile ? 'block' : 'flex' }}>
            <div className={classes.titleContainer}>
                <Typography variant="h2">
                    Frequently Asked Questions
                </Typography>
                <Typography variant="body1" className={classes.subtitle}>
                    Everything you need to know about our product.
                </Typography>
                {!isMobile && <img src={FAQImage} style={{ width: '40vw', maxWidth: '390px' }} />}
            </div>
            <div style={{ display: 'flex', gap: '30px', alignItems: 'flex-start' }}>
                <List style={{ marginTop: 0 }} >
                    <Divider light/>
                    {FAQs.map((question) =>
                        <FAQ isMobile={isMobile}
                            title={question.title}
                            content={question.content}
                        />
                    )}
                </List>
            </div>
        </div>)
}

function FAQ(props) {
    const { title, content, isMobile } = props;
    const [open, setOpen] = useState(false);

    const classes = useFAQStyles();

    return (<>
        <ListItem button disableRipple onClick={() => setOpen(!open)}
            style={{ paddingTop: '24px', paddingBottom: open ? "0px" : "24px" }}
        >
            <ListItemText disableTypography
                primary={
                    <Typography className={classes.questionTitle}>
                        {title}
                    </Typography>
                }
            />
            <ListItemIcon>
                {
                    open ?
                        <Remove className={classes.expandIcon} />
                        :
                        <Add className={classes.expandIcon} />
                }
            </ListItemIcon>
        </ListItem>
        <Collapse in={open}
        >
            <ListItem className={classes.listItem} style={{ paddingTop: '0px' }}>
                <ListItemText disableTypography
                    secondary={
                        <Typography className={classes.answer}>
                            {content}
                        </Typography>
                    }
                />
            </ListItem>
        </Collapse>
        <Divider light/>
    </>)
}


const FAQs = [
    {
        title: "How does this work?",
        content: "The resume recommendation engine is an AI algorithm developed internally to scan a resume for skills and match the results to requirements of open roles using meta tags. We did this so you don’t have to manually search for job opportunities that could be a good fit. The more resumes the algorithm sees the more data it processes and the better the matching to those meta tags. You may see roles that at first may not make sense, but your skills may be a good fit for some of the requirements of a role."
    },
    {
        title: "How do you limit or prevent bias?",
        content: "Our team have all gone through LivePerson’s AI Native Foundations course to learn about bias in AI and algorithms. By design we do not use your name or the name(s) of your academic institutions as part of matching since research shows they introduce bias. The AI only looks for skills and previous work experience and makes personalized recommendations for potential jobs without limiting visibility into other roles or using the data to make screening decisions. Lastly, we trained and tested the model on diverse sets of resumes."
    },
    {
        title: "Is my data safe?",
        content: "Yes we are a GDPR compliant recruiting team, plus we don’t store your resume when you use this recommendation engine."
    },
    {
        title: "What kind of files can I upload?",
        content: "We only take in PDF resume files for now. We will be adding support for docx, txt, and other kinds of files in the next few weeks."
    },
    {
        title: "How do I provide feedback?",
        content: <>To provide feedback on the Resume Recommendation engine, please email <a href="mailto:marlon-support-LP@liveperson.com.">marlon-support-LP@liveperson.com</a> All feedback, positive or negative, helps us optimize the product and future candidate experiences.</>
    }
]