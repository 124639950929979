

/**
 * Function that construct an event object and check to see if the event object was properly constructed
 * @param {String} name The name of the event
 * @param {String} type The type of the event ( i.e. click, result )
 * @param {String} source The current source of the event ( i.e. career )
 * @param {Object} custom_parameters Any additional data that accompanies the event
*/
export function eventBuilder(
    name,
    type,
    source,
    custom_parameters = {}) {

    if ( !name || !type || !source ){
        throw new Error("Event builder function is used incorrectly")
    }  

    return {
        name: name,
        type: type,
        source: source,
        custom_parameters: custom_parameters
    }
}

/**
 * Append all of the filter attribute into a single list and remove title from each results
 * and return back data to be stored in analytics
 * @param { Object } filter the savedSearch from the search reducer to be appended into single list
 * @param { Object } results the results from the search to be appended into a single list
 * @return { Object } The analytics data to be plugged into custom_parameters of eventBuilder
 */
export function processSearch(filter, results){
    
    for(const result of results){
        delete results.title
    }

    return {
        "filter": filter,
        "results": results
    }
}