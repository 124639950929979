import React from 'react'
import { Box, Card, CardContent, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { colors } from '../theme/colors';

import FastForward from '../assets/fast-forward-button.svg'
import TargetHit from '../assets/target-hit.svg'
import SpeechBallon from '../assets/speech-balloon.svg'

const useStyles = makeStyles(() => ({
    card: {
        background: colors.webKit.gradients.bluePink1,
        borderRadius: '16px',
        padding: 2,
        maxWidth: '304px'
    },
    cardHeader: {
        textAlign: 'center',
    },
    cardContent: {
        margin: 0,
        backgroundColor: colors.webKit.primary.white,
        borderRadius: '16px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: "column",
    },
}));

const useMobileStyles = makeStyles(() => ({
    cardHeader: {
        textAlign: 'center',
    },
}));

export function SmartApplyHero(props) {
    const { show, isMobile } = props;
    const classes = useStyles();
    const mobileClasses = useMobileStyles();
    const timelineClasses = timelineStyles();
    const mobileTimelineClasses = mobileTimelineStyles();

    if (!show)
        return <> </>
    
    const title = "For eligible roles, Smart Apply enhances your experience"
    const fastTrackText = "Fast tracks your initial screening"// through Conversational Recruiting"
    const autoApplyText = "Automatically applies you to similar roles"// based on your answers"
    const answersQuestionsText = "Answers your basic questions about the role"

    if (isMobile) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Card className={classes.card}>
                    <CardContent className={classes.cardContent}>
                        <Typography className={mobileClasses.cardHeader} variant="h4">
                            {title}
                        </Typography>
                        <Box className={mobileTimelineClasses.timeline} >
                            <Box style={{ width: '100%' }}>
                                <TimelineItem start isMobile
                                    icon={FastForward} alt="fast forward"
                                    description={fastTrackText}
                                />
                            </Box>
                            <Box style={{ 'width': '100%' }}>
                                <TimelineItem isMobile
                                    icon={TargetHit} alt="arrow hitting target"
                                    description={autoApplyText}
                                />
                            </Box>
                            <Box style={{ 'width': '100%' }}>
                                <TimelineItem end isMobile
                                    icon={SpeechBallon} alt="chat bubble"
                                    description={answersQuestionsText}
                                />
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            </div>
        )
    }

    return (
        <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
                <Typography className={classes.cardHeader} variant="h4" >
                    {title}
                </Typography>
                <Box className={timelineClasses.timeline} >
                    <Box style={{ width: '100%' }}>
                        <TimelineItem start
                            icon={FastForward} alt="fast forward"
                            description={fastTrackText}
                        />
                    </Box>
                    <Box style={{ 'width': '100%' }}>
                        <TimelineItem
                            icon={TargetHit} alt="arrow hitting target"
                            description={autoApplyText}
                        />
                    </Box>
                    <Box style={{ 'width': '100%' }}>
                        <TimelineItem end
                            icon={SpeechBallon} alt="chat bubble"
                            description={answersQuestionsText}
                        />
                    </Box>
                </Box>
            </CardContent>
        </Card>
    )
}

const timelineStyles = makeStyles(() => ({
    timeline: {
        listStyleType: 'none',
        display: 'flex',
        marginTop: '30px',
        // maxWidth: '600px'
    },
    timelineItem: {
        // width: '100%',
        "&::after": { content: "''" },
    },
    timelineDescription: {
        fontWeight: 300,
        marginLeft: '5px',
        marginRight: '5px',
        marginTop: '10px',
        textAlign: 'center',
    },
    icon: {
        height: '24px',
        width: '24px'
    },
    iconContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
    },
    iconBorder: {
        border: '2px solid ' + colors.webKit.primary.lightBlue,
        padding: '23px',
        borderRadius: '100px',
        backgroundColor: colors.webKit.primary.white
    },
    lineBefore: {
        "&::before": {
            content: "''",
            flex: "1 1",
            // borderBottom: "2px solid " + colors.webKit.primary.lightBlue,
            margin: "auto",
        },
        "&::after": {
            content: "''",
            flex: "1 1",
        },
    },
    lineAfter: {
        "&::before": {
            content: "''",
            flex: "1 1",
        },
        "&::after": {
            content: "''",
            flex: "1 1",
            // borderBottom: "2px solid " + colors.webKit.primary.lightBlue,
            margin: "auto",
        },
    }
}));

const mobileTimelineStyles = makeStyles(() => ({
    timeline: {
        listStyleType: 'none',
        marginTop: '30px',
        // maxWidth: '350px'
    },
    timelineItem: {
        width: '100%',
        "&::after": { content: "''" },
    },
    timelineDescription: {
        marginLeft: '24px',
        fontWeight: 400,
        textAlign: 'left',
    },
    icon: {
        height: '24px',
        width: '24px'
    },
    iconContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    iconBorder: {
        border: '2px solid ' + colors.webKit.primary.lightBlue,
        padding: '16px',
        borderRadius: '100px',
        backgroundColor: colors.webKit.primary.white
    },
    lineBefore: {
        "&::before": {
            content: "''",
            display: 'block',
            height: '9px',
            // borderLeft: "2px solid " + colors.webKit.primary.lightBlue,
            marginLeft: '32px',
        },
        "&::after": {
            // content: "''",
        },
    },
    lineAfter: {
        "&::before": {
            // content: "''",
        },
        "&::after": {
            content: "''",
            display: 'block',
            height: '9px',
            // borderLeft: "2px solid " + colors.webKit.primary.lightBlue,
            marginLeft: '32px',
        },
    }
}))



function TimelineItem(props) {
    const { icon, alt, description, start, end, isMobile } = props
    const classes = timelineStyles()
    const mobileClasses = mobileTimelineStyles()

    if (isMobile) {
        return (
            <div className={mobileClasses.timelineItem}>
                {!start && <div className={mobileClasses.lineBefore} />}
                <div className={mobileClasses.iconContainer}>
                    <div className={mobileClasses.iconBorder}>
                        <div className={mobileClasses.icon}>
                            <img src={icon} alt={alt} className={mobileClasses.icon} />
                        </div>
                    </div>
                    <Typography variant="body2" className={mobileClasses.timelineDescription}>
                        {description}
                    </Typography>
                </div>
                {!end && <div className={mobileClasses.lineAfter} />}
            </div>
        )
    }

    let iconClass = classes.iconContainer
    if (!start)
        iconClass += ' ' + classes.lineBefore
    if (!end)
        iconClass += ' ' + classes.lineAfter

    return (
        <div className={classes.timelineItem}>
            <div className={iconClass}>
                <div className={classes.iconBorder}>
                    <div className={classes.icon}>
                        <img src={icon} alt={alt} className={classes.icon} />
                    </div>
                </div>
            </div>
            <Typography variant="body2"
                className={classes.timelineDescription}>
                {description}
            </Typography>
        </div>
    )
}