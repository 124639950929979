import { Button, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import UploadIcon from "../assets/UploadCloudFilled.svg"
import gradientBG from '../assets/lp-gradient-10p-11.png'
import { colors } from "../theme/colors"

const useStyles = makeStyles((theme) => ({
    banner: {
        background: `url('${gradientBG}')`,
        padding: '20px 0px',
        width: '100%',

        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gridGap: '10px'
    },
    mobileContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gridGap: '10px'
    },
    title: {
        textAlign: 'center',
        color: colors.webKit.primary.white,
    },
    mobileTitle: {
        textAlign: 'center',
        color: colors.webKit.primary.white,
    }
}))

export function GetRecJobsLink(props) {
    const { isMobile } = props;
    const { showRecJobs } = useSelector(state => state.search.queries)
    const { uploadedResume, noRecommended } = useSelector(state => state.jobs)
    const classes = useStyles();

    // if (uploadedResume)
    //     return (
    //         <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
    //             <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    //                 <Typography variant="h2">
    //                     {
    //                         showRecJobs === true ?
    //                             "Recommended Jobs"
    //                             :
    //                             "All Jobs"
    //                     }
    //                 </Typography>
    //                 {noRecommended &&
    //                     <Typography variant="body1">
    //                         Sorry, we couldn't find any matching roles. Try using search and filter to browse all our roles.
    //                     </Typography>
    //                 }
    //             </div>
    //         </div>
    //     )

    // if (isMobile) {

    // }

    return (
        <div className={classes.banner} >
            <div className={isMobile ? classes.mobileContainer : classes.container}
                style={{ paddingLeft: props.margin, paddingRight: props.margin }}>
                <Typography variant="h2" className={isMobile ? classes.mobileTitle : classes.title} >
                    {/* Upload your resume to find curated roles */}
                    <div style={{ height: '45px' }}>{' '}</div>
                </Typography>
                {/* <Link to="/upload" style={{ textDecoration: 'none' }}>
                    <Button variant="contained" color="primary"
                        style={{
                            paddingTop: '8px',
                            paddingBottom: '8px',
                            paddingLeft: props.isMobile ? "50px" : "70px",
                            paddingRight: props.isMobile ? "50px" : "70px",
                            whiteSpace: 'nowrap'
                        }}
                        startIcon={<img style={{ height: '15px' }} src={UploadIcon} alt="upload cloud" />}
                    >
                        Get Matched
                    </Button>
                </Link> */}
            </div>
        </div >
    )
}