import { 
    utilityConstants
} from './'

export const utilityAction = {
    setQuery
}

function setQuery(query){
     
    return dispatch => {
        dispatch(setParam(query))
    }

    function setParam(query){return {type: utilityConstants.SET_QUERY_PARAM, query}}
}