import { combineReducers } from "redux";

import { queryAvailableAction, deleteAvailableApplications } from './candidate'
import { job, jobs } from './job'
import { search } from './search'
import { applyToJob } from './application'
import { analytics, sendAnalytics, requestSession } from './analytics'
import { resume } from './resume'
import { queryParam } from './utility'

export default combineReducers({
    queryAvailableAction,
    deleteAvailableApplications,
    job,
    jobs,
    search,
    applyToJob,
    analytics,
    sendAnalytics,
    requestSession,
    resume,
    queryParam
})