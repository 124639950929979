import { applicationConstants } from './'

const initialState = {
    loading: false,
    error: false,
    success: false,
}

export function applyToJob(state = initialState, action){
    switch(action.type){
        case applicationConstants.APPLY_TO_JOB_REQUEST:
            return{
                ...state,
                loading: true,
                error: false,
            }
        case applicationConstants.APPLY_TO_JOB_SUCCESS:
            return{
                ...state,
                success: true,
                loading: false
            }
        case applicationConstants.APPLY_TO_JOB_FAILURE:
            return {
                ...state,
                error: action.error.message,
                success: false, 
                loading: false
            }
        case applicationConstants.APPLY_RESET:
            return initialState
        default:
            return state 
    }
}