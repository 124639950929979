import React, { useEffect, useRef, useState } from 'react';
import ReactHtmlParser from 'html-react-parser';
import Modal from '@mui/material/Modal';
import NavigationPrompt from '../Utility/NavigationPrompt';
import AdPixel from '../AdPixel';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { applicationActions } from '../../redux/application';
import { analyticsActions } from '../../redux/analytics';

// MUI
import {
    Grid,
    Typography,
    Button,
    InputLabel,
    TextField,
    MenuItem,
    Checkbox,
    FormControlLabel,
    RadioGroup,
    Radio,
    InputAdornment,
    Collapse,
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';

// Custom
import UploadCloudIcon from '../../assets/UploadCloud.svg';
import FileIcon from '../../assets/file.svg';
import AlertProblem from '../../assets/AlertProblem.svg';
import { FileUpload } from '../FileUpload';
import { SmartApplyExplainer } from './SmartApplyExplainer';
import { colors } from '../../theme/colors';

import { ENGAGEMENT_ID, GOOGLE_ANALYTICS_ENABLED } from '../../settings';
import { TextDivider } from '../TextDivider';
import { LoadingIndicator } from '../LoadingIndicator';
import { EEOSurvey } from './EEOSurvey';
import { Navigate } from 'react-router';
import { Link } from '../../components/Utility/Link';

const buttonsWidth = 500;
const buttonsHeight = 50;

const useStyles = makeStyles((theme) => ({
    contentContainer: {
        paddingTop: '28px',
    },
    descriptionContent: {
        paddingTop: '0px',
        display: 'block',
    },
    link: {
        textDecoration: 'none',
    },
    inputLabel: {
        color: colors.webKit.primary.text,
        whiteSpace: 'normal',
        paddingBottom: '4px',
    },
    privacyLinks: {
        textDecoration: 'none',
    },
    navLinkCurrent: {
        color: colors.webKit.primary.text,
    },
    linkedInButton: {
        backgroundColor: colors.linkedIn.blue,
        color: colors.linkedIn.white,
        padding: '16px',
        fontSize: '1.2rem',
        marginTop: '4px',
    },
}));

export function ApplyOptions(props) {
    const dispatch = useDispatch();
    const { job } = props;
    const [formOpen, setFormOpen] = useState(false);
    const classes = useStyles();

    const [botLoading, setBotLoading] = useState(true);
    const [clickedBot, setClickedBot] = useState(false);

    if (job.conversational) {
        const handleApplicationFormOpenClick = () => {
            if (!botLoading) {
                setFormOpen(!formOpen);
                // there is no ga in window on staging or local
                // Track click on optional form apply button with Google Analytics
                if (clickedBot) {
                    if (GOOGLE_ANALYTICS_ENABLED)
                        window.ga(
                            'send',
                            'event',
                            'apply',
                            'click',
                            'abandoned-smart-apply'
                        );
                    dispatch(
                        analyticsActions.sendAnalytics(
                            'abandoned-smart-apply',
                            'click',
                            'career',
                            {}
                        )
                    );
                }
                if (GOOGLE_ANALYTICS_ENABLED)
                    window.ga(
                        'send',
                        'event',
                        'apply',
                        'click',
                        'initiate-smart-form-apply'
                    );
                dispatch(
                    analyticsActions.sendAnalytics(
                        'initiate-smart-form-apply',
                        'click',
                        'career',
                        {}
                    )
                );
            }
        };
        const handleEngagementButtonClick = () => {
            setClickedBot(true);
            // there is no ga in window on staging or local
            // Track click on smart apply engagement button with Google Analytics
            if (GOOGLE_ANALYTICS_ENABLED)
                window.ga(
                    'send',
                    'event',
                    'apply',
                    'click',
                    'initiate-smart-apply'
                );
            dispatch(
                analyticsActions.sendAnalytics(
                    'initiate-smart-apply',
                    'click',
                    'career',
                    {}
                )
            );
        };

        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <p>
                    By clicking apply for this job you acknowledge that
                    information submitted to LivePerson is subject to
                    LivePerson's
                    <Link className={classes.privacyLinks} to="/files/gdpr">
                        {
                            ' General Data Protection Regulation Candidate Privacy Notice '
                        }
                    </Link>
                    (applicable only to EU candidates) and
                    <Link className={classes.privacyLinks} to="/files/ccpa">
                        {' California Consumer Privacy Act Privacy Notice '}
                    </Link>
                    (applicable only to California candidates). For all other
                    applicants, you may disregard this notice and click confirm
                    to submit your application.
                </p>
                <div id="application" />
                <div style={{ maxWidth: buttonsWidth, marginTop: '32px' }}>
                    <BotButton
                        {...props}
                        setBotLoading={setBotLoading}
                        onClick={handleEngagementButtonClick}
                    />
                    <SmartApplyExplainer />
                </div>

                <TextDivider
                    text="OR"
                    color={colors.webKit.primary.white}
                    margin={8}
                />

                <Button
                    onClick={handleApplicationFormOpenClick}
                    variant="outlined"
                    color="primary"
                    style={{
                        height: buttonsHeight,
                        width: buttonsWidth,
                        marginBottom: '32px',
                        maxWidth: '90vw',
                    }}
                >
                    {botLoading ? (
                        <LoadingIndicator color="inherit" size={25} />
                    ) : formOpen ? (
                        'Hide Application Form'
                    ) : (
                        'Fill out Application Form'
                    )}
                </Button>

                <Collapse in={formOpen} timeout={1000}>
                    <ApplicationForm {...props} />
                </Collapse>
            </div>
        );
    }

    return <ApplicationForm style={{ marginTop: '32px' }} {...props} />;
}

var openedWindows = [];
window._open = window.open;
window.open = function (url, name, params) {
    openedWindows.push(window._open(url, name, params));
};
function clickEnd(count) {
    let endConversationButton = document.querySelector(
        '[aria-label="End conversation"]'
    );

    if (endConversationButton) {
        endConversationButton.click();
        clickConfirm(0);
    } else if (count < 30) {
        // wait for chat window to load in, it may take a few seconds
        setTimeout(() => {
            clickEnd(count + 1);
        }, 200);
    }
}
function clickConfirm(count) {
    let confirmButton = document.getElementsByClassName('lp_confirm_button')[0];
    if (confirmButton) {
        confirmButton.click();
        setTimeout(() => {
            confirmButton =
                document.getElementsByClassName('lp_confirm_button')[0];

            // Make sure the confirm screen has gone away before clicking submit
            if (confirmButton && count < 5) {
                setTimeout(() => {
                    clickConfirm(count + 1);
                }, 50);
            } else clickSubmit(0);
        }, 50);
    } else if (count < 5) {
        setTimeout(() => {
            clickConfirm(count + 1);
        }, 100);
    }
}
function clickSubmit(count) {
    let submitButton = document.getElementsByClassName(
        'lpc_survey-area__cancel-button'
    )[0];
    if (submitButton) {
        submitButton.click();
        setTimeout(() => {
            submitButton = document.getElementsByClassName(
                'lpc_survey-area__cancel-button'
            )[0];
            // Make sure the submit screen has gone away
            if (submitButton && count < 5)
                setTimeout(() => {
                    clickSubmit(count + 1);
                }, 50);
        }, 50);
    } else if (count < 5) {
        setTimeout(() => {
            clickSubmit(count + 1);
        }, 100);
    }
}
function closeOpenChats() {
    // if chat is in a popup window / incognito mode
    if (openedWindows.length > 0) {
        openedWindows[0].close();
    } else {
        let chatWindow = document.getElementById('lpChat');
        if (chatWindow) {
            // hide bot so that user doesn't see buttons being pressed
            chatWindow.style = 'opacity: 0';
            clickEnd(0);
        }
    }
}

function BotButton(props) {
    const { job, botType } = props;
    const [clicked, setClicked] = useState(false);

    useEffect(() => {
        // Inserting bot engagement script into body
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.innerHTML =
            "window.lpTag=window.lpTag||{ },'undefined'==typeof window.lpTag._tagCount?(window.lpTag={wl:lpTag.wl||null,scp:lpTag.scp||null,site:'82400935'||'',section:lpTag.section||'',tagletSection:lpTag.tagletSection||null,autoStart:lpTag.autoStart!==!1,ovr:lpTag.ovr||{ },_v:'1.10.0',_tagCount:1,protocol:'https:',events:{bind:function(t,e,i){lpTag.defer(function () { lpTag.events.bind(t, e, i) }, 0)},trigger:function(t,e,i){lpTag.defer(function () { lpTag.events.trigger(t, e, i) }, 1)}},defer:function(t,e){0 === e ? (this._defB = this._defB || [], this._defB.push(t)) : 1 === e ? (this._defT = this._defT || [], this._defT.push(t)) : (this._defL = this._defL || [], this._defL.push(t))},load:function(t,e,i){var n=this;setTimeout(function(){n._load(t, e, i)},0)},_load:function(t,e,i){var n=t;t||(n=this.protocol+'//'+(this.ovr&&this.ovr.domain?this.ovr.domain:'lptag.liveperson.net')+'/tag/tag.js?site='+this.site);var o=document.createElement('script');o.setAttribute('charset',e?e:'UTF-8'),i&&o.setAttribute('id',i),o.setAttribute('src',n),document.getElementsByTagName('head').item(0).appendChild(o)},init:function(){this._timing = this._timing || {}, this._timing.start = (new Date).getTime();var t=this;window.attachEvent?window.attachEvent('onload',function(){t._domReady('domReady')}):(window.addEventListener('DOMContentLoaded',function(){t._domReady('contReady')},!1),window.addEventListener('load',function(){t._domReady('domReady')},!1)),'undefined'===typeof window._lptStop&&this.load()},start:function(){this.autoStart = !0},_domReady:function(t){this.isDom || (this.isDom = !0, this.events.trigger('LPT', 'DOM_READY', { t: t })), this._timing[t] = (new Date).getTime()},vars:lpTag.vars||[],dbs:lpTag.dbs||[],ctn:lpTag.ctn||[],sdes:lpTag.sdes||[],hooks:lpTag.hooks||[],identities:lpTag.identities||[],ev:lpTag.ev||[]},lpTag.init()):window.lpTag._tagCount+=1;";
        script.id = 'screeningBot';
        document.body.appendChild(script);

        // Making sure loading symbol is removed when the bot appears
        checkBot();
        // reloading bot if necessary
        reloadBot();

        // Fires when user tries to reload page or go back
        window.addEventListener('beforeunload', onUnload);

        return function cleanup() {
            // Always close chat when leaving page
            closeOpenChats();
            window.removeEventListener('beforeunload', onUnload);
        };
    }, []);

    // Fires when user tries to reload page or go back
    // Makes a 'Changes may not be saved' pop up
    const onUnload = (event) => {
        if (!document.getElementById('lpChat')) return;
        if (typeof event === 'undefined') event = window.event;
        if (event) event.returnValue = '';
        return '';
    };

    // Check whether the bot entry point is loaded
    // This allows a loading symbol to appear in the meantime
    const [loading, setLoading] = useState(true);
    const checkBot = () => {
        setTimeout(() => {
            var imgElement = document.getElementById('apply-img');
            if (imgElement === null) {
                checkBot();
            } else {
                setLoading(false);
                props.setBotLoading(false);
            }
        }, 100);
    };
    // If the bot has not loaded in, request it reloads every few seconds until it does
    const reloadBot = () => {
        setTimeout(() => {
            var imgElement = document.getElementById('apply-img');
            if (imgElement === null) {
                if (
                    'lpTag' in window &&
                    'newPage' in window.lpTag &&
                    typeof window.lpTag.newPage === 'function'
                ) {
                    window.lpTag.newPage(window.location.href);
                }
                reloadBot();
            }
        }, 3000);
    };

    // If the bot category in the URL does not match the current job's category
    // then redirect to the correct URL
    if (job.jobCategory !== botType) {
        return (
            <Navigate
                to={`/job/${job.jobCategory}/${job.greenhouse_public_id}`}
            />
        );
    }

    return (
        <>
            {/* <Prompt
                // Prompt user when trying to leave page through a react router link
                // if the user has clicked the smart apply button
                when={clicked}
                message="Are you sure you want to leave the conversation?"
            /> */}

            <NavigationPrompt when={clicked}>
                {(isOpen, onConfirm, onCancel) => (
                    <Modal show={isOpen}>
                        <div>
                            <p>
                                Are you sure you want to leave the conversation?
                            </p>
                            <button onClick={onCancel}>Cancel</button>
                            <button onClick={onConfirm}>Ok</button>
                        </div>
                    </Modal>
                )}
            </NavigationPrompt>

            <Button
                variant="contained"
                color="primary"
                onClick={() => {
                    // If the bot has not been opened yet, make sure that any existing bots are closed
                    if (!clicked) {
                        closeOpenChats();
                    }
                    props.onClick();

                    // Record that the bot button has been clicked, so changes may have been made
                    // thus, the user should be warned away from leaving the site
                    // ALSO we don't want to close the chat after someone has opened one
                    // just because they clicked the button again
                    setClicked(true);
                }}
                style={{
                    height: buttonsHeight,
                    width: buttonsWidth,
                    maxWidth: '90vw',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <div
                    id={ENGAGEMENT_ID[botType]}
                    data-lp-event="click"
                    style={{ height: buttonsHeight }}
                >
                    {' '}
                </div>{' '}
                {loading && <LoadingIndicator color="inherit" size={25} />}
            </Button>
        </>
    );
}

function ApplicationForm(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { job, isMobile } = props;
    const { results } = useSelector((state) => state.search);
    const { jobs } = useSelector((state) => state.jobs);
    const [errorPrompt, setErrorPrompt] = useState(false); // Error is not displayed on load, only on submit
    const { query: param } = useSelector((state) => state.queryParam);
    const [answers, setAnswers] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        privacy_confirm: false,
        visa: '',
        USeligibile: '',
        resume: null,
        linkedin: null,
        param: param,
    });
    const [eeoc, setEEOC] = useState({});

    const [startedForm, setStartedForm] = useState(false);

    const [applicationSubmited, setApplicationSubmited] = useState(false);

    const [appSubmitPixelId, setAppSubmitPixelId] = useState('5070215');

    const _Timeout = (delay) => {
        return new Promise((res) => setTimeout(res, delay));
    };

    const handleChange = (e) => {
        // there is no ga in window on staging or local
        if (!startedForm) {
            if (job.conversational) {
                // Track job application initial interaction in Google Analytics
                setStartedForm(true);
                if (GOOGLE_ANALYTICS_ENABLED)
                    window.ga(
                        'send',
                        'event',
                        'apply',
                        'click',
                        'initiate-smart-form-fill',
                        job.greenhouse_public_id
                    );
                dispatch(
                    analyticsActions.sendAnalytics(
                        'initiate-smart-form-fill',
                        'click',
                        'career',
                        {}
                    )
                );
            } else {
                // Track job application initial interaction in Google Analytics
                setStartedForm(true);
                if (GOOGLE_ANALYTICS_ENABLED)
                    window.ga(
                        'send',
                        'event',
                        'apply',
                        'click',
                        'initiate-regular-form-fill',
                        job.greenhouse_public_id
                    );
                dispatch(
                    analyticsActions.sendAnalytics(
                        'initiate-regular-form-fill',
                        'click',
                        'career',
                        {}
                    )
                );
            }
        }

        const { value, id } = e.target;
        let newAnswers = {
            ...answers,
            [id]: value,
        };
        if (id === 'phone') {
            // Only taking in numbers for phone field
            let enteredKey = value[value.length - 1];
            let reg = /^\d+$/;

            if (enteredKey === undefined) {
                setAnswers(newAnswers);
                return;
            }

            if (!reg.test(enteredKey)) return;
        }

        setAnswers(newAnswers);
    };
    const fieldsFilled = (a) => {
        const {
            first_name,
            last_name,
            email,
            phone,
            privacy_confirm,
            visa,
            USeligibile,
            resume,
            linkedin,
        } = a;
        if (!(resume !== null || linkedin !== null)) return false;
        if (first_name === '') return false;
        if (last_name === '') return false;
        if (email === '') return false;
        if (phone === '') return false;
        if (job.countries && job.countries.includes('United States')) {
            if (USeligibile === '') return false;
            if (visa === '') return false;
        }
        if (!privacy_confirm) return false;
        return true;
    };

    const errorCheck = (field) => {
        /*
                Basic check which only check it is an empty string
                */

        return (
            errorPrompt &&
            (answers[field] === '' ||
                answers[field] === null ||
                answers[field] === false)
        );
    };

    const handleSubmitApplication = async () => {
        setErrorPrompt(true);
        if (fieldsFilled(answers)) {
            setApplicationSubmited(true);
            await _Timeout(150);

            dispatch(
                applicationActions.applyToJob(
                    job.greenhouse_public_id,
                    answers,
                    eeoc
                )
            );
            const [matched, match_index] = wasRecommended();

            // there is no ga in window on staging or local
            if (job.conversational) {
                if (GOOGLE_ANALYTICS_ENABLED)
                    window.ga(
                        'send',
                        'event',
                        'apply',
                        'click',
                        'submit-smart-application-form',
                        job.greenhouse_public_id
                    );
                // Send application info to analytics
                dispatch(
                    analyticsActions.sendAnalytics(
                        'submit-smart-application-form',
                        'submission',
                        'career',
                        {
                            greenhouse_public_id: job.greenhouse_public_id,
                            matched: matched,
                            match_index: match_index,
                        }
                    )
                );
            } else {
                // Track application in Google Analytics
                if (GOOGLE_ANALYTICS_ENABLED)
                    window.ga(
                        'send',
                        'event',
                        'apply',
                        'click',
                        'submit-application-form',
                        job.greenhouse_public_id
                    );
                // Send application info to analytics
                dispatch(
                    analyticsActions.sendAnalytics(
                        'submit-application-form',
                        'submission',
                        'career',
                        {
                            greenhouse_public_id: job.greenhouse_public_id,
                            matched: matched,
                            match_index: match_index,
                        }
                    )
                );
            }
        }
    };

    const wasRecommended = () => {
        if (results) {
            let results_index;
            // Check whether this job was from the resume AI
            // Also get the index of the job within the resume AI list
            let match = !!jobs.filter((j, i) => {
                if (j.greenhouse_public_id === job.greenhouse_public_id) {
                    results_index = i;
                    return true;
                }
                return false;
            })[0].match;

            // If this job was not from the resume AI, get the index of
            // where it showed up in search results
            if (!match) {
                results.map((j, i) => {
                    if (j.greenhouse_public_id === job.greenhouse_public_id) {
                        results_index = i;
                    }
                });
            }
            return [match, results_index];
        }
    };

    return (
        <Grid container spacing={4}>
            <div id="application" />
            {/* <Grid item xs={12} md={6}>
                    <Typography>
                        Your full LinkedIn Profile will be shared.
                        <a className={classes.privacyLinks}
                            href="https://www.linkedin.com/help/linkedin/answer/72063"> Learn More</a>
                    </Typography>
                    <a style={{ textDecoration: 'none' }} href="https://www.linkedin.com/uas/connect/user-signin?trk=applyWithLinkedin">
                        <Button variant="contained"
                            className={classes.linkedInButton}
                        >
                            Apply with LinkedIn
                        </Button>
                    </a>
                </Grid> */}
            {/* <Grid item xs={12} md={12}>
                <ResumeUpload answers={answers} setAnswers={setAnswers} errorPrompt={errorPrompt} isMobile={isMobile} />
            </Grid>

            <Grid item xs={12} md={6}>
                <InputLabel className={classes.inputLabel} htmlFor={'first_name'}>
                    First Name*
                </InputLabel>
                <TextField required fullWidth className={classes.inputText} variant="outlined"
                    error={errorCheck("first_name")}
                    onChange={(e) => handleChange(e)}
                    id={'first_name'}
                    value={answers['first_name']}
                    InputProps={{
                        endAdornment:
                            <InputAdornment position="end">
                                {errorCheck("first_name") && <img className={classes.alertIcon} src={AlertProblem} alt="error" />}
                            </InputAdornment>
                    }}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <InputLabel className={classes.inputLabel} htmlFor={'last_name'}>
                    Last Name*
                </InputLabel>
                <TextField required fullWidth className={classes.inputText} variant="outlined"
                    error={errorCheck("last_name")}
                    onChange={(e) => handleChange(e)}
                    id={'last_name'}
                    value={answers['last_name']}
                    InputProps={{
                        endAdornment:
                            <InputAdornment position="end">
                                {errorCheck("last_name") && <img className={classes.alertIcon} src={AlertProblem} alt="error" />}
                            </InputAdornment>
                    }}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <InputLabel className={classes.inputLabel} htmlFor={'email'}>
                    Email*
                </InputLabel>
                <TextField required fullWidth className={classes.inputText} variant="outlined"
                    error={errorCheck("email")}
                    onChange={(e) => handleChange(e)}
                    id={'email'}
                    value={answers['email']}
                    InputProps={{
                        endAdornment:
                            <InputAdornment position="end">
                                {errorCheck("email") && <img className={classes.alertIcon} src={AlertProblem} alt="error" />}
                            </InputAdornment>
                    }}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <InputLabel className={classes.inputLabel} htmlFor={'phone'}>
                    Phone Number*
                </InputLabel>
                <TextField required fullWidth className={classes.inputText} variant="outlined"
                    error={errorCheck("phone")}
                    onChange={(e) => handleChange(e)}
                    id={'phone'}
                    value={answers['phone']}
                    InputProps={{
                        endAdornment:
                            <InputAdornment position="end">
                                {errorCheck("phone") && <img className={classes.alertIcon} src={AlertProblem} alt="error" />}
                            </InputAdornment>
                    }}
                />
            </Grid>

            {
                job.countries && job.countries.includes("United States") &&
                <>
                    <Grid item xs={12}>
                        <p style={{ margin: 0 }}>
                            Are you currently eligibile to work in the United States? *
                        </p>
                        <RadioGroup row name="US-eligible" id="USeligible">
                            <FormControlLabel value="yes" label="Yes"
                                control={
                                    <Radio checked={answers['USeligibile'] === 'yes'}
                                        onChange={() => setAnswers({ ...answers, USeligibile: 'yes' })} />
                                }
                            />
                            <FormControlLabel value="no" label="No"
                                control={
                                    <Radio checked={answers['USeligibile'] === 'no'}
                                        onChange={(e) => setAnswers({ ...answers, USeligibile: 'no' })} />
                                }
                            />
                        </RadioGroup>
                    </Grid>
                    <Grid item xs={12}>
                        <p style={{ margin: 0 }}> Do you now or in the future require visa sponsorship to continue working in the United States? * </p>
                        <RadioGroup row name="visa" id="visa">
                            <FormControlLabel value="yes" label="Yes"
                                control={
                                    <Radio checked={answers['visa'] === 'yes'}
                                        onChange={() => setAnswers({ ...answers, visa: 'yes' })} />
                                }
                            />
                            <FormControlLabel value="no" label="No"
                                control={
                                    <Radio checked={answers['visa'] === 'no'}
                                        onChange={(e) => setAnswers({ ...answers, visa: 'no' })} />
                                }
                            />
                        </RadioGroup>
                    </Grid>
                </>
            }
            {
                job.compliance && job.compliance.length > 0 &&
                <EEOSurvey answers={eeoc} setAnswers={setEEOC} />
            }

            <Grid item xs={12}>
                <Typography variant="h2" className={classes.inputLabel} htmlFor={'privacy_confirm'}>
                    Privacy Policy
                </Typography>
                <p>
                    You acknowledge that information submitted to LivePerson is subject to LivePerson's
                    <Link className={classes.privacyLinks} to="/files/gdpr">
                        {" General Data Protection Regulation Candidate Privacy Notice "}
                    </Link>
                    (applicable only to EU candidates) and
                    <Link className={classes.privacyLinks} to="/files/ccpa">
                        {" California Consumer Privacy Act Privacy Notice "}
                    </Link>
                    (applicable only to California candidates). For all other applicants, you may disregard this notice and click confirm to submit your application.
                </p>
                <FormControlLabel
                    id="privacy_confirm"
                    label="Confirm *"
                    control={<ErrorCheckbox
                        color={errorCheck('privacy_confirm') ? 'default' : 'secondary'}
                        value={answers['privacy_confirm']}
                        onClick={() => setAnswers({ ...answers, privacy_confirm: !answers.privacy_confirm })}
                    />}
                />
            </Grid>
            <Grid item xs={12} style={{ marginBottom: '2rem' }}>
                {
                    applicationSubmited &&
                    <AdPixel id={appSubmitPixelId} />
                }
                <Button variant='contained' color="primary"
                    // disabled={!fieldsFilled(answers)}
                    onClick={handleSubmitApplication}
                    style={{ paddingLeft: '32px', paddingRight: '32px' }}
                >
                    Submit Application
                </Button>
            </Grid> */}
            <Grid item xs={12} style={{ marginBottom: '2rem' }}>
                <a
                    href={`https://boards.greenhouse.io/embed/job_app?for=liveperson&token=${job.greenhouse_public_id}#application`}
                    className={classes.link}
                    target="_blank"
                    rel="noreferrer"
                >
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ paddingLeft: '32px', paddingRight: '32px' }}
                    >
                        Apply Now
                    </Button>
                </a>
            </Grid>
        </Grid>
    );
}
const uploadStyles = makeStyles(() => ({
    container: {
        backgroundColor: 'rgba(196, 196, 196, 0.2)',
        border: '2px solid rgba(255, 255, 255, 0.22)',
        borderRadius: '16px',
        padding: '10px 32px 10px 10px',
        display: 'flex',
        alignItems: 'center',
    },
    uploadText: {
        color: colors.webKit.primary.text,
        fontWeight: 700,
        fontSize: '16px',
    },
    fileTypesText: {
        color: colors.webKit.primary.text,
        fontWeight: 300,
        fontSize: '16px',
    },
    icon: {
        padding: '10px',
        //         position: 'absolute',
        // width: '38.39px',
        // height: '25.59px',
        // left: '27.8px',
        // top: '19.2px',
    },
    iconContainer: {
        width: '60px',
        display: 'flex',
        justifyContent: 'center',
    },
    alertIcon: {
        paddingTop: '1em',
    },

    fileInfoContainer: {
        backgroundColor: 'rgba(196, 196, 196, 0.2)',
        borderRadius: '16px',
        alignItems: 'center',
        padding: '10px',
    },
}));
function ResumeUpload(props) {
    const { setAnswers, answers, errorPrompt } = props;
    const classes = uploadStyles();

    const [isMobile, setIsMobile] = useState(false);
    const ref = useRef(null);
    useEffect(() => {
        if (ref && ref.current && ref.current.clientWidth !== 0)
            setIsMobile(ref.current.clientWidth < 600);
    }, [ref]);

    const handleUpload = (file) => {
        setAnswers({ ...answers, resume: file });
    };
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: isMobile ? 'center' : 'flex-start',
                gap: '8px',
            }}
            ref={ref}
        >
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: isMobile ? 'column' : 'row',
                    gap: '8px',
                    width: isMobile ? '280px' : 'auto',
                    maxWidth: '90vw',
                }}
            >
                <FileUpload
                    id="resume"
                    handleUpload={handleUpload}
                    accept="application/pdf,.doc,.docx,application/msword,.rtf"
                >
                    <div className={classes.container}>
                        <div className={classes.iconContainer}>
                            <img
                                className={classes.icon}
                                src={UploadCloudIcon}
                                alt="upload cloud"
                            />
                        </div>
                        <div>
                            <Typography className={classes.uploadText}>
                                {answers.resume === null
                                    ? 'Upload your resume'
                                    : 'Reupload your resume'}
                            </Typography>
                            <Typography className={classes.fileTypesText}>
                                (PDF, Word, or Rich Text)
                            </Typography>
                        </div>
                    </div>
                </FileUpload>

                {errorPrompt && answers.resume === null && (
                    <span
                        style={{
                            paddingLeft: '5px',
                            color: colors.webKit.primary.alert,
                        }}
                    >
                        {' '}
                        <img
                            className={classes.alertIcon}
                            src={AlertProblem}
                            alt="error"
                        />{' '}
                        Please upload your resume{' '}
                    </span>
                )}

                {answers.resume !== null && (
                    <div
                        style={{
                            display: 'block',
                            margin: '0px 10px',
                            width: isMobile ? '100%' : 'auto',
                        }}
                    >
                        <div
                            className={classes.fileInfoContainer}
                            style={{
                                maxHeight: '52px',
                                minHeight: '52px',
                                display: 'flex',
                            }}
                        >
                            <div className={classes.iconContainer}>
                                <img
                                    className={classes.icon}
                                    src={FileIcon}
                                    alt="file"
                                />
                            </div>
                            <div>
                                <Typography>{answers.resume.name}</Typography>
                                <Typography>
                                    {new Date(
                                        answers.resume.lastModified
                                    ).toLocaleDateString()}
                                </Typography>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            *
        </div>
    );
}

function Questions(props) {
    const { questions, answers, handleChange } = props;
    const classes = useStyles();
    return (
        <>
            {questions.map((question) => {
                const { description, fields, label, required } = question;
                if (
                    label === 'LinkedIn Profile' ||
                    label === 'Resume/CV' ||
                    label === 'Cover Letter'
                )
                    return <> </>;
                return (
                    <>
                        {fields.map((field) => {
                            const { name, type, values } = field;
                            switch (type) {
                                case 'input_file':
                                    return (
                                        <Grid item style={{ width: '100%' }}>
                                            <InputLabel
                                                className={classes.inputLabel}
                                                htmlFor={name}
                                            >
                                                {`${label}${
                                                    required ? '*' : ''
                                                }`}
                                                <input
                                                    type="file"
                                                    id={name}
                                                    name="filename"
                                                />
                                            </InputLabel>
                                        </Grid>
                                    );
                                case 'textarea':
                                    return <> </>;
                                case 'input_text':
                                    return (
                                        <Grid
                                            item
                                            style={{
                                                width: ![
                                                    'first_name',
                                                    'last_name',
                                                    'phone',
                                                    'email',
                                                ].includes(name)
                                                    ? '100%'
                                                    : '50%',
                                            }}
                                        >
                                            <InputLabel
                                                className={classes.inputLabel}
                                                htmlFor={name}
                                            >
                                                {`${label}${
                                                    required ? '*' : ''
                                                }`}
                                            </InputLabel>
                                            <TextField
                                                required={required}
                                                fullWidth
                                                className={classes.inputText}
                                                id={name}
                                                value={answers[name]}
                                                onChange={(e) =>
                                                    handleChange(e)
                                                }
                                                variant="outlined"
                                            />
                                        </Grid>
                                    );
                                case 'multi_value_single_select':
                                    return (
                                        <Grid item style={{ width: '100%' }}>
                                            <InputLabel
                                                className={classes.inputLabel}
                                                htmlFor={name}
                                            >
                                                {`${label}${
                                                    required ? '*' : ''
                                                }`}
                                            </InputLabel>
                                            <TextField
                                                id={name}
                                                select
                                                value={answers[name]}
                                                onChange={(e) =>
                                                    handleChange(e)
                                                }
                                                variant="outlined"
                                            >
                                                {values.map((v) => (
                                                    <MenuItem
                                                        key={v.value}
                                                        value={v.value}
                                                    >
                                                        {v.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                    );
                                case 'multi_value_multi_select':
                                    return (
                                        <Grid item>
                                            <InputLabel
                                                className={classes.inputLabel}
                                                htmlFor={name}
                                            >
                                                {`${label}${
                                                    required ? '*' : ''
                                                }`}
                                            </InputLabel>
                                            {ReactHtmlParser(description)}
                                            {values.map((v) => {
                                                const { label, value } = v;
                                                // TODO add checkboxes
                                                return <p>{label}</p>;
                                            })}
                                        </Grid>
                                    );
                                default:
                                    return <p>{type}</p>;
                            }
                        })}
                    </>
                );
            })}
        </>
    );
}

const ErrorCheckbox = withStyles({
    root: {
        color: colors.webKit.primary.alert,
        '&$checked': {
            color: colors.webKit.primary.electricBlue,
        },
    },
    colorSecondary: {
        color: colors.webKit.primary.text,
        '&$checked': {
            color: colors.webKit.primary.electricBlue,
        },
        '&$hover': {
            backgroundColor: colors.webKit.primary.electricBlue,
        },
    },

    checked: {},
})((props) => <Checkbox {...props} />);
