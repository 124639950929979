import React, { useEffect, useState } from 'react';

import {
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Collapse,
    Divider,
    IconButton,
    Dialog,
    Typography,
    DialogContent,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch, useSelector } from 'react-redux';
import { searchActions } from '../redux/search';
import {
    Add,
    CheckBox as CheckBoxIcon,
    CheckBoxOutlineBlank,
    Close,
    Remove,
} from '@mui/icons-material';
import FilterIcon from '../assets/Filter.svg';
import { colors } from '../theme/colors';
import { LoadingIndicator } from './LoadingIndicator';

const useStyles = makeStyles(() => ({
    checkedIcon: {
        color: colors.webKit.primary.electricBlue,
    },
    unCheckedIcon: {
        color: colors.webKit.primary.text,
    },
    expandIcon: {
        color: colors.webKit.primary.text,
    },
    mobileContainer: {
        borderTop: '1px solid ' + colors.webKit.navys.navyLightest,
        borderBottom: '1px solid ' + colors.webKit.navys.navyLightest,
    },
    listItem: {
        paddingLeft: 0,
    },
    mobileCategoryTitle: {
        marginRight: '80vw',
        whiteSpace: 'nowrap',
    },
    categoryTitle: {
        marginRight: '100px',
        whiteSpace: 'nowrap',
    },
}));

const jobCategories = [
    'Engineering',
    'Security & IT',
    'Sales',
    'Sales Engineers',
    'Design',
    'Data Science & ML',
    'Operations',
    'Marketing & Comms',
    'Customer Experience',
    'Customer Success',
    'Enablement',
    'People',
    'Legal',
    'Finance & Accounting',
    'Product & Program Management',
];

const cityFilter = {
    title: 'Cities',
    type: 'dependent',
    independentKey: 'countryQuery',
    subCategories: {
        'United States': [
            'Atlanta GA, US',
            'Alpharetta GA, US',
            'Dallas TX, US',
            'Mountain View CA, US',
            'New York NY, US',
            'Seattle WA, US',
            'San Francisco CA, US',
        ],
        Australia: ['Melbourne, Australia', 'Sydney, Australia'],
        Brazil: [],
        Bulgaria: ['Sofia, Bulgaria'],
        Canada: [],
        Poland: [],
        Germany: [
            'Berlin, Germany',
            'Cologne, Germany',
            'Düsseldorf, Germany',
            'Frankfurt, Germany',
            'Hamburg, Germany',
            'Leipzig, Germany',
            'Mannheim, Germany',
            'Munich, Germany',
        ],
        India: [],
        Israel: ['Jerusalem, Israel', 'Tel-Aviv, Israel'],
        Italy: ['Milan, Italy', 'Rome, Italy'],
        Japan: ['Tokyo, Japan'],
        Mexico: [],
        Netherlands: ['Amsterdam, Netherlands'],
        Phillippines: ['Manila, Phillippines'],
        Singapore: ['Singapore City, Singapore'],
        Spain: ['Barcelona, Spain', 'Madrid, Spain'],
        'United Kingdom': [
            'Birmingham, United Kingdom',
            'Coventry, United Kingdom',
            'London, United Kingdom',
            'Manchester, United Kingdom',
            'Reading, United Kingdom',
        ],
    },
    queryKey: 'cityQuery',
};
const countryFilter = {
    title: 'Country',
    defaultOpen: true,
    dependentKey: 'cityQuery',
    dependentTags: cityFilter.subCategories,
    subCategories: [
        'United States',
        'Australia',
        'Brazil',
        'Bulgaria',
        'Canada',
        'France',
        'Germany',
        'India',
        'Israel',
        'Italy',
        'Japan',
        'Mexico',
        'Netherlands',
        'Phillippines',
        'Poland',
        'Singapore',
        'Spain',
        'United Kingdom',
    ],
    queryKey: 'countryQuery',
};
const careerStageFilter = {
    title: 'Career Stage',
    subCategories: ['Entry', 'Mid', 'Senior', 'Executive'],
    queryKey: 'careerStageQuery',
};
const categoriesFilter = {
    title: 'Categories',
    subCategories: jobCategories,
    queryKey: 'categoriesQuery',
};
const remoteFilter = {
    title: 'Show only remote jobs',
    type: 'bool',
    queryKey: 'remoteQuery',
};

const filterCategories = [
    countryFilter,
    cityFilter,
    careerStageFilter,
    categoriesFilter,
];

export function FilterList(props) {
    const classes = useStyles();

    const [filterOpen, setFilterOpen] = useState(false);
    const { results } = useSelector((state) => state.search);

    if (props.isMobile) {
        return (
            <div className={classes.mobileContainer}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Typography variant="h5">
                        {results.length}{' '}
                        <span style={{ fontWeight: '700' }}> Results </span>{' '}
                    </Typography>
                    <IconButton
                        onClick={() => setFilterOpen(!filterOpen)}
                        size="large"
                    >
                        <img
                            style={{ height: '16px' }}
                            src={FilterIcon}
                            alt="filter"
                        />
                    </IconButton>
                </div>

                <Dialog
                    fullScreen
                    open={filterOpen}
                    onClose={() => setFilterOpen(false)}
                >
                    <DialogContent>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                color: colors.webKit.primary.text,
                            }}
                        >
                            <Typography variant="h3"> Filter Jobs </Typography>
                            <IconButton
                                onClick={() => setFilterOpen(!filterOpen)}
                                size="large"
                            >
                                <Close
                                    style={{
                                        color: colors.webKit.primary.text,
                                    }}
                                />
                            </IconButton>
                        </div>
                        <FilterListContents isMobile />
                    </DialogContent>
                </Dialog>
            </div>
        );
    }

    return <FilterListContents />;
}

function FilterListContents(props) {
    const { isMobile } = props;
    const { queries } = useSelector((state) => state.search);
    const { jobs, uploadedResume } = useSelector((state) => state.jobs);
    const [showRecJobsFilter, setShowRecJobsFilter] = useState(false);
    // Update whether to show 'showRecJobs' filter when resume is uploaded
    useEffect(() => {
        if (uploadedResume !== showRecJobsFilter) {
            setShowRecJobsFilter(uploadedResume);
        }
    }, [uploadedResume]);

    if (!queries) return <LoadingIndicator />;

    return (
        <List style={{ width: '304px', paddingTop: '16px' }}>
            {/* <FilterBooleanTag key={'showSmartApply'}
                categoryTitle={"Smart Apply eligible"}
                queries={queries} jobs={jobs}
                queryKey={'showSmartApply'}
                noPaddingTop
                isMobile={isMobile}
            /> */}
            {showRecJobsFilter && (
                <FilterBooleanTag
                    key={'showRecJobs'}
                    categoryTitle={'Recommended Jobs'}
                    queries={queries}
                    jobs={jobs}
                    queryKey={'showRecJobs'}
                    defaultTrue
                    noPaddingTop
                    isMobile={isMobile}
                />
            )}
            <FilterBooleanTag
                key={'remoteQuery'}
                categoryTitle={'Show only remote jobs'}
                queries={queries}
                jobs={jobs}
                queryKey={'remoteQuery'}
                noPaddingTop
                isMobile={isMobile}
            />
            <Divider />
            {filterCategories.map((category, index) => {
                if (category.type === 'bool') {
                    return (
                        <FilterBooleanTag
                            key={category.queryKey}
                            categoryTitle={category.title}
                            queries={queries}
                            jobs={jobs}
                            queryKey={category.queryKey}
                            noPaddingTop={index === 0 && !showRecJobsFilter}
                            isMobile={isMobile}
                        />
                    );
                }
                if (category.type === 'dependent') {
                    return (
                        <FilterDependentTags
                            key={category.queryKey}
                            categoryTitle={category.title}
                            dependentTags={category.subCategories}
                            independentKey={category.independentKey}
                            defaultOpen={true}
                            queries={queries}
                            jobs={jobs}
                            queryKey={category.queryKey}
                            isMobile={isMobile}
                        />
                    );
                }

                return (
                    <FilterTags
                        key={category.queryKey}
                        categoryTitle={category.title}
                        subCategories={category.subCategories}
                        defaultOpen={true}
                        dependentKey={category.dependentKey}
                        dependentTags={category.dependentTags}
                        queries={queries}
                        jobs={jobs}
                        queryKey={category.queryKey}
                        isMobile={isMobile}
                    />
                );
            })}
        </List>
    );
}

function FilterBooleanTag(props) {
    const { categoryTitle, queries, jobs, queryKey, noPaddingTop } = props;
    const [checked, setChecked] = useState(false);

    const classes = useStyles();
    const dispatch = useDispatch();

    // When mounting, if checked state does not match the saved queries, set it
    useEffect(() => {
        if (queries[queryKey] !== undefined && checked !== queries[queryKey]) {
            setChecked(queries[queryKey]);
        }
    }, []);

    // When a checkbox is clicked, toggle
    const handleCheck = () => {
        let newQueries = {
            ...queries,
            [queryKey]: !checked,
        };
        dispatch(searchActions.setSearchQueries(newQueries));
        dispatch(searchActions.searchJobs(jobs, newQueries));
        setChecked(!checked);
    };

    return (
        <ListItem
            className={classes.listItem}
            key={categoryTitle}
            button
            onClick={() => handleCheck(categoryTitle)}
            // this style is to better vertically align remote (or whatever is first in the list)
            style={{ paddingTop: noPaddingTop ? '0px' : '8px' }}
        >
            <ListItemIcon>
                {checked ? (
                    <CheckBoxIcon className={classes.checkedIcon} />
                ) : (
                    <CheckBoxOutlineBlank className={classes.unCheckedIcon} />
                )}
            </ListItemIcon>
            <ListItemText
                id={`${categoryTitle}-label`}
                secondary={categoryTitle}
            />
        </ListItem>
    );
}

// Tags that are shown dependent on another query key
// i.e. cities are dependent on countries
function FilterDependentTags(props) {
    const {
        categoryTitle,
        dependentTags,
        independentKey,
        queries,
        jobs,
        queryKey,
        isMobile,
    } = props;
    const classes = useStyles();
    const dispatch = useDispatch();

    // This is a list of which tags are checked
    const [checked, setChecked] = useState([]);
    const [open, setOpen] = useState(props.defaultOpen);

    // IndependentQuery is the query this component is dependent on
    const independentQuery = queries[independentKey] || [];

    // When an independentQuery is removed, filter out the tags that are dependent on it
    useEffect(() => {
        if (checked !== queries[queryKey] && checked.length !== 0) {
            setChecked(queries[queryKey] || []);
        }
    }, [queries[queryKey]]);

    // When mounting, if checked state does not match the saved queries, set it
    useEffect(() => {
        if (queries[queryKey] && checked !== queries[queryKey]) {
            setChecked(queries[queryKey]);
        }
    }, []);

    // When a checkbox is clicked, toggle the filter tag
    const handleCheck = (value) => {
        let tags = queries[queryKey];
        if (tags === undefined) {
            tags = [value];
        }
        // remove tag from query
        else if (tags.includes(value)) {
            tags = tags.filter((x) => x !== value);
        }
        // remove tag from query
        else {
            tags.push(value);
        }
        setChecked(tags);

        let newQueries = {
            ...queries,
            [queryKey]: tags,
        };
        dispatch(searchActions.setSearchQueries(newQueries));
        dispatch(searchActions.searchJobs(jobs, newQueries));
    };

    const handleClickAll = () => {
        if (checked.length > 0) {
            let newChecked = [];
            let newQueries = {
                ...queries,
                [queryKey]: newChecked,
            };
            dispatch(searchActions.setSearchQueries(newQueries));
            dispatch(searchActions.searchJobs(jobs, newQueries));
            setChecked(newChecked);
        }
    };

    return (
        <>
            <ListItem
                button
                className={classes.listItem}
                dense
                key={queryKey}
                onClick={() => setOpen(!open)}
            >
                <ListItemText
                    disableTypography
                    primary={
                        <Typography
                            variant="h5"
                            className={
                                isMobile
                                    ? classes.mobileCategoryTitle
                                    : classes.categoryTitle
                            }
                        >
                            {categoryTitle}
                        </Typography>
                    }
                />
                <ListItemIcon style={{ justifyContent: 'center' }}>
                    {open ? (
                        <Remove className={classes.expandIcon} />
                    ) : (
                        <Add className={classes.expandIcon} />
                    )}
                </ListItemIcon>
            </ListItem>
            <Collapse in={open}>
                <ListItem
                    className={classes.listItem}
                    key="all"
                    button
                    onClick={() => handleClickAll()}
                >
                    <ListItemIcon>
                        {checked.length === 0 ? (
                            <CheckBoxIcon className={classes.checkedIcon} />
                        ) : (
                            <CheckBoxOutlineBlank
                                className={classes.unCheckedIcon}
                            />
                        )}
                    </ListItemIcon>
                    <ListItemText id={'all-label'} secondary={'All'} />
                </ListItem>
                {independentQuery.map((iKey) => {
                    // If the country has no specific cities
                    if (!dependentTags[iKey]) return <> </>;

                    // Display an item for each tag in the list corresponding to
                    // the above independent key
                    // i.e. if iKey is "Japan", list "Tokyo, Japan"
                    return (
                        <>
                            {dependentTags[iKey].map((tag) => {
                                return (
                                    <ListItem
                                        className={classes.listItem}
                                        key={tag}
                                        button
                                        onClick={() => handleCheck(tag)}
                                    >
                                        <ListItemIcon>
                                            {checked &&
                                            checked.indexOf(tag) !== -1 ? (
                                                <CheckBoxIcon
                                                    className={
                                                        classes.checkedIcon
                                                    }
                                                />
                                            ) : (
                                                <CheckBoxOutlineBlank
                                                    className={
                                                        classes.unCheckedIcon
                                                    }
                                                />
                                            )}
                                        </ListItemIcon>
                                        <ListItemText
                                            id={`${tag}-label`}
                                            secondary={tag}
                                        />
                                    </ListItem>
                                );
                            })}{' '}
                        </>
                    );
                })}
            </Collapse>
            <Divider
                className={classes.divider}
                style={{ width: isMobile ? '80vw' : '100%' }}
            />
        </>
    );
}

function FilterTags(props) {
    const { categoryTitle, subCategories, queries, jobs, queryKey, isMobile } =
        props;
    const [open, setOpen] = useState(props.defaultOpen);
    const [checked, setChecked] = useState([]);

    const classes = useStyles();
    const dispatch = useDispatch();

    // When mounting, if checked state does not match the saved queries, set it
    useEffect(() => {
        if (queries[queryKey] && checked !== queries[queryKey]) {
            setChecked(queries[queryKey]);
        }
    }, []);

    const handleClickAll = () => {
        if (checked.length > 0) {
            let newChecked = [];
            let newQueries = {
                ...queries,
                [queryKey]: newChecked,
            };
            if (props.dependentKey) {
                newQueries = {
                    ...newQueries,
                    [props.dependentKey]: [],
                };
            }
            dispatch(searchActions.setSearchQueries(newQueries));
            dispatch(searchActions.searchJobs(jobs, newQueries));
            setChecked(newChecked);
        }
    };

    // When a checkbox is clicked, toggle the filter tag
    const handleCheck = (value) => {
        let newChecked = checked;
        if (newChecked.includes(value)) {
            // remove tag from query
            newChecked = newChecked.filter((x) => x !== value);
        } else {
            // add tag to query
            newChecked.push(value);
        }
        setChecked(newChecked);
        let newQueries = {
            ...queries,
            [queryKey]: newChecked,
        };
        if (props.dependentKey) {
            let newDependent = queries[props.dependentKey] || [];
            let possibleChecked = [];
            newChecked.map((iKey) => {
                possibleChecked = possibleChecked.concat(
                    props.dependentTags[iKey]
                );
            });

            newDependent = newDependent.filter((tag) => {
                return possibleChecked.indexOf(tag) !== -1;
            });

            newQueries = {
                ...newQueries,
                [props.dependentKey]: newDependent,
            };
        }
        dispatch(searchActions.setSearchQueries(newQueries));
        dispatch(searchActions.searchJobs(jobs, newQueries));
    };

    return (
        <>
            <ListItem
                button
                className={classes.listItem}
                dense
                onClick={() => setOpen(!open)}
            >
                <ListItemText
                    disableTypography
                    primary={
                        <Typography
                            variant="h5"
                            className={
                                isMobile
                                    ? classes.mobileCategoryTitle
                                    : classes.categoryTitle
                            }
                        >
                            {categoryTitle}
                        </Typography>
                    }
                />
                <ListItemIcon style={{ justifyContent: 'center' }}>
                    {open ? (
                        <Remove className={classes.expandIcon} />
                    ) : (
                        <Add className={classes.expandIcon} />
                    )}
                </ListItemIcon>
            </ListItem>
            <Collapse in={open}>
                <ListItem
                    className={classes.listItem}
                    key="all"
                    button
                    onClick={() => handleClickAll()}
                >
                    <ListItemIcon>
                        {checked.length === 0 ? (
                            <CheckBoxIcon className={classes.checkedIcon} />
                        ) : (
                            <CheckBoxOutlineBlank
                                className={classes.unCheckedIcon}
                            />
                        )}
                    </ListItemIcon>
                    <ListItemText id={'all-label'} secondary={'All'} />
                </ListItem>
                {subCategories.map((tag) => {
                    return (
                        <ListItem
                            key={tag}
                            button
                            className={classes.listItem}
                            onClick={() => handleCheck(tag)}
                        >
                            <ListItemIcon>
                                {checked && checked.indexOf(tag) !== -1 ? (
                                    <CheckBoxIcon
                                        className={classes.checkedIcon}
                                    />
                                ) : (
                                    <CheckBoxOutlineBlank
                                        className={classes.unCheckedIcon}
                                    />
                                )}
                            </ListItemIcon>
                            <ListItemText id={`${tag}-label`} secondary={tag} />
                        </ListItem>
                    );
                })}
            </Collapse>
            <Divider
                className={classes.divider}
                style={{ width: isMobile ? '80vw' : '100%' }}
            />
        </>
    );
}
