import { Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import BannerImage from '../assets/BannerImage.png'
import { colors } from "../theme/colors";

const useStyles = makeStyles(() => ({
    title: {
        fontSize: '40px',
        lineHeight: '48px',
        marginBottom: '24px',
        marginTop: '24px',
        fontWeight: '400'
    },
    image: {
        backgroundImage: `linear-gradient(rgba(10, 14, 64, 0.8), rgba(10, 14, 64, 0.8)), url(${BannerImage})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        height: '160px',
        position: 'relative'
    },
    textContainer: {
        display: 'flex',
        alignItems: 'center',
        height: '160px',
    },
    text: {
        display: 'block'
    },
    gradientText: {
        marginTop: '16px',
        marginBottom: '24px',
        background: colors.webKit.gradients.bluePink1,

        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        textAlign: 'center'
    }
}))

export function Banner(props) {
    const { isMobile } = props;
    const classes = useStyles();

    if (isMobile)
        return <> </>

    return (
        <div style={{width: '100%'}}>
            <a href="/" style={{ textDecoration: 'none' }}>
                <Typography variant = "h1" className={classes.gradientText}>
                    Careers at LivePerson
                </Typography>
            </a>
        </div>
    )
}


export function ImageBanner(props) {
    const { isMobile } = props;
    const classes = useStyles();

    if (isMobile)
        return <> </>

    return (

        <div className={classes.image} >
            <div className={classes.textContainer} style={{ marginLeft: props.margin, marginRight: props.margin }}>
                <div className={classes.text} >
                    <Typography className={classes.title}>
                        Careers at LivePerson
                    </Typography>
                    {/* Removed due to Teal
                    <Typography variant='body1'>
                        Join the Conversational Future
                    </Typography> */}
                </div>
            </div>
        </div>
    )
}