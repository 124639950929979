import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'


function EnhancedLink(props){
    /*
        Enhanced Link that maintain the context of the url and all the parameter it has
        while navigating through the site
        
        i.e. localhost:3002/?gh_src=apple navigates to localhost:3002/job/4315362003?gh_src=apple
    */

    const { 
        anchor,
        to,
        className
    } = props

    const { query: param } = useSelector(state => state.queryParam)

    if (anchor){
        return <a className={className} href={to + param }> {props.children} </a>
    }else{
        return <Link className={className} to={to + param}> {props.children} </Link>
    }
}

export { EnhancedLink as Link }; 