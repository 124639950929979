import { searchConstants } from './'
import { searchService } from './search.services';

export const searchActions = {
    setSearchResults,
    setSearchQueries,
    searchJobs
}

function setSearchResults(rows) {
    return dispatch => {
        dispatch(setResults(rows))
    }

    function setResults(results) { return { type: searchConstants.SET_SEARCH_RESULTS, results } }
}

function setSearchQueries(queries) {
    return dispatch => {
        dispatch(setQueries(queries))
    }

    function setQueries(queries) { return { type: searchConstants.SET_SEARCH_QUERIES, queries } }
}

function searchJobs(jobs, queries) {
    return dispatch => {
        if (jobs === null) {
            jobs = []
        }

        searchService.searchJobs(jobs, queries, "").then(
            data => {
                let [results, resultsMsg, hasConversational] = data
                dispatch(searchActions.setSearchResults(results))
                dispatch(displayNoResults(resultsMsg))
                dispatch(setHasConversational(hasConversational))
            }
        )
    }

    function displayNoResults(message) { return { type: searchConstants.SET_RESULTS_MSG, message } }
    function setHasConversational(hasConversational) { return { type: searchConstants.SET_HAS_CONVERSATIONAL, hasConversational } }
}
