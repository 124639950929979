import { IconButton, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { colors } from '../theme/colors';
import Twitter from '../assets/Twitter.svg';
import Facebook from '../assets/Facebook.svg';
import LinkedIn from '../assets/LinkedIn.svg';
import Instagram from '../assets/Instagram.svg';
import { solutionsContent, resourcesContent, companyContent, successContent, humanContent } from './NavBar';

const useStyles = makeStyles(() => ({
    footerContainer: {
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-start',
        backgroundColor: colors.webKit.primary.footer,
        paddingTop: '40px',
        paddingBottom: '40px',
    },
    footerText: {
        fontFamily: 'Inter',
        color: colors.webKit.primary.white,
    },
    link: {
        textDecoration: 'none',
        color: colors.webKit.primary.white,
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    sectionTitle: {
        fontWeight: 700,
        color: colors.webKit.primary.pink,
        textTransform: 'uppercase',
    },
    pageLabel: {
        fontFamily: 'Space Grotesk',
        fontWeight: 700,
        margin: '16px 0px',
        color: colors.webKit.primary.white,
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    socialContainer: {
        display: 'flex',
    },
    socialIcon: {
        padding: 0,
        marginRight: '24px',
        maxHeight: '24px',
        maxWidth: '100px',
        minWidth: '24px',
    },
}));

export function Footer(props) {
    const { isMobile, margin, width } = props;
    const classes = useStyles();

    return (
        <div className={{ maxWidth: width, overflow: 'hidden' }}>
            <div className={classes.footerContainer}>
                <div style={{ paddingLeft: margin, paddingRight: margin }}>
                    {/* <NavigationContent isMobile={isMobile} /> */}
                    <Grid container style={{ marginTop: '0px' }} alignItems="flex-start">
                        <Grid item>
                            <LegalContent />
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    );
}

function NavigationContent() {
    return (
        <Grid container spacing={3}>
            <Grid item>
                <NavigationContentSection
                    content={solutionsContent.filter((e, i) => i !== solutionsContent.length - 1)}
                />
            </Grid>
            <Grid item>
                <NavigationContentSection content={productsContent} />
                <NavigationContentSection content={successContent} />
            </Grid>
            <Grid item>
                <NavigationContentSection content={resourcesContent} />
            </Grid>
            <Grid item>
                <NavigationContentSection content={humanContent} />
                <NavigationContentSection content={companyContent} />
            </Grid>
        </Grid>
    );
}

function NavigationContentSection(props) {
    const { content } = props;
    const classes = useStyles();

    return (
        <>
            {content.map((column, index) => (
                <div key={index} style={{ width: '192px', paddingLeft: '16px', paddingRight: '16px' }}>
                    {column.map((section, i) => (
                        <div key={section.footerTitle || 'section-' + i}>
                            {section.footerTitle && (
                                <Typography className={classes.sectionTitle} variant="h6">
                                    {section.footerTitle}
                                </Typography>
                            )}
                            {section.pages.map((page) => (
                                <a href={page.link} className={classes.link} key={page.label}>
                                    <Typography className={classes.pageLabel} variant="subtitle2">
                                        {page.label}
                                    </Typography>
                                </a>
                            ))}
                        </div>
                    ))}
                    {
                        // if this is the last column in this section
                        // or if the next column has a footerTitle
                        (!content[index + 1] || content[index + 1][0].footerTitle) && <div style={{ height: '40px' }} />
                    }
                </div>
            ))}
        </>
    );
}

function LegalContent(props) {
    const classes = useStyles();

    return (
        <>
            <Typography style={{ display: 'flex', gridGap: '16px' }} variant="caption">
                <div className={classes.footerText}>© 2024 LivePerson. All rights reserved.</div>
                <a href="https://policies.liveperson.com/terms-of-use" className={classes.link}>
                    Terms of Service
                </a>
                <a href="https://policies.liveperson.com/privacy" className={classes.link}>
                    Privacy Policy
                </a>
                {/* <a href="https://policies.liveperson.com/copyright" className={classes.link}>
                        {" Copyright "}
                    </a>
                    {" • "}
                    <a href="https://ir.liveperson.com/corporate-governance/governance-overview" className={classes.link}>
                        {" Coporate Governance "}
                    </a>
                    {" • "}
                    <a href="https://policies.liveperson.com/gdpr-data-privacy" className={classes.link}>
                        {" GDPR "}
                    </a> */}
            </Typography>
        </>
    );
}

function SocialContent(props) {
    const classes = useStyles();

    return (
        <div className={classes.socialContainer}>
            <a href="https://twitter.com/LivePerson">
                <IconButton className={classes.socialIcon} size="large">
                    <img src={Twitter} alt="twitter logo" />
                </IconButton>
            </a>
            <a href="https://www.facebook.com/liveperson">
                <IconButton className={classes.socialIcon} size="large">
                    <img src={Facebook} alt="facebook logo" />
                </IconButton>
            </a>
            <a href="https://www.linkedin.com/company/liveperson/">
                <IconButton className={classes.socialIcon} size="large">
                    <img src={LinkedIn} alt="linkedin logo" />
                </IconButton>
            </a>
            <a href="https://www.instagram.com/livepersoninc/">
                <IconButton className={classes.socialIcon} size="large">
                    <img src={Instagram} alt="instagram logo" />
                </IconButton>
            </a>
        </div>
    );
}

const productsContent = [
    [
        {
            footerTitle: 'Products',
            pages: [
                {
                    label: 'Conversational Cloud',
                    link: 'https://www.liveperson.com/products/conversational-cloud/',
                },
                {
                    label: 'BELLA Health',
                    link: 'https://www.liveperson.com/products/bella-health/',
                },
                {
                    label: 'Voice',
                    link: 'https://www.liveperson.com/products/voice/',
                },
                // {
                //     label: "Conversation Builder",
                //     link: "https://www.liveperson.com/products/conversation-builder",
                // },
                // {
                //     label: "Conversation Manager",
                //     link: "https://www.liveperson.com/products/conversation-manager",
                // },
                // {
                //     label: "Conversation Orchestrator",
                //     link: "https://www.liveperson.com/products/conversation-orchestrator",
                // },
                // {
                //     label: "Conversation Analytics",
                //     link: "https://www.liveperson.com/products/conversation-analytics",
                // },
                // {
                //     label: "Functions",
                //     link: "https://www.liveperson.com/products/functions",
                // },
                // {
                //     label: "Proactive Messaging",
                //     link: "https://www.liveperson.com/products/proactive-messaging",
                // },
                // {
                //     label: "Social Messaging",
                //     link: "https://www.liveperson.com/products/social-messaging",
                // }
            ],
        },
    ],
    // [{
    //     pages: [
    //         {
    //             label: "Conversation Channels",
    //             link: "https://www.liveperson.com/products/messaging-channels",
    //         },
    //         {
    //             label: "Web Messaging",
    //             link: "https://www.liveperson.com/products/web-messaging",
    //         },
    //         {
    //             label: "App Messaging",
    //             link: "https://www.liveperson.com/products/app-messaging",
    //         },
    //         {
    //             label: "Voice to Messaging",
    //             link: "https://www.liveperson.com/products/call-to-message",
    //         },
    //         {
    //             label: "Email",
    //             link: "https://www.liveperson.com/products/email",
    //         },
    //         {
    //             label: "SMS",
    //             link: "https://www.liveperson.com/products/sms-texting",
    //         },
    //         {
    //             label: "Apple Business Chat",
    //             link: "https://www.liveperson.com/products/apple-business-chat",
    //         },
    //         {
    //             label: "Google RBM",
    //             link: "https://www.liveperson.com/products/googlerbm",
    //         },
    //         {
    //             label: "Google's Business Messages",
    //             link: "https://www.liveperson.com/products/google-business-messages",
    //         },
    //         {
    //             label: "WhatsApp",
    //             link: "https://www.liveperson.com/products/whatsapp",
    //         },
    //         {
    //             label: "Facebook",
    //             link: "https://www.liveperson.com/products/facebook-messenger",
    //         },
    //         {
    //             label: "Instagram",
    //             link: "https://www.liveperson.com/products/instagram",
    //         },
    //         {
    //             label: "Twitter",
    //             link: "https://www.liveperson.com/products/twitter",
    //         },
    //         {
    //             label: "LINE",
    //             link: "https://www.liveperson.com/products/line",
    //         },
    //         {
    //             label: "WeChat",
    //             link: "https://www.liveperson.com/products/wechat",
    //         }]
    // }]
];
