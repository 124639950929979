import React, { useEffect, useRef, useState } from 'react';
import { Route, Routes, matchRoutes, useLocation } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import { HelmetProvider } from 'react-helmet-async';

import './App.css';
import { SearchResults } from './pages/SearchResults';
import { JobDetails } from './pages/JobDetails';
import { CandidateDeletion } from './pages/candidate';
import { FileUpload } from './pages/FileUpload';

import { SearchBar } from './components/SearchBar';
import NavBar from './components/NavBar';
import AdPixel from './components/AdPixel';
// import { LockScreen } from './pages/LockScreen';
import { Footer } from './components/Footer';
import { useDispatch, useSelector } from 'react-redux';
import { analyticsActions, processSearch } from './redux/analytics';
import { ErrorPage } from './components/ErrorPage';
import { ANALYTICS_ENABLED } from './settings';
import { utilityAction } from './redux/utility';
import { EmbeddedFile } from './pages/files/EmbeddedFile';
import gdpr from './assets/GDPR Candidate Notice - February 2022.pdf';
import ccpa from './assets/CCPA Candidate Notice - February 2022.pdf';
import { Banner } from './components/Banner';
import { GetRecJobsLink } from './components/GetRecJobsLink';

const desktopMargin = 'calc(20px + 8vw)';
const tabletMargin = 'calc(5px + 6vw)';
const mobileLandscapeMargin = 'calc(10px + 3vw)';
const mobileProfileMargin = 'calc(10px + 2vw)';

const useStyles = makeStyles(() => ({
    container: {
        marginTop: '1em',
        marginBottom: '1em',
        maxWidth: '1920px',
    },
}));

function App() {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [mainPixelId, setMainPixelId] = useState('5070512');

    //////////////////// SAVE SEARCH ANALYTICS ////////////////////
    const { queries, results } = useSelector((state) => state.search);
    const analytics = useSelector((state) => state.analytics);
    const { query: urlQuery } = useSelector((state) => state.queryParam);

    // We need to save the states in refs for the scroll listener
    // listeners cannot see state updates

    // GETTING SEARCH/TITLE QUERY FROM REDUX
    // this is what is in the search bar
    const [allQueries, _setAllQueries] = useState(queries);
    const allQueriesRef = useRef(allQueries);
    const setAllQueries = (data) => {
        allQueriesRef.current = data;
        _setAllQueries(data);
    };
    useEffect(() => {
        if (queries) setAllQueries({ ...queries });
    }, [queries]);

    // GETTING SAVED SEARCH FROM REDUX
    // this is what was last sent to analytics
    const [filterSearch, setFilterSearch] = useState(null);
    const [savedSearch, _setSavedSearch] = useState(analytics.savedSearch);
    const savedSearchRef = useRef(savedSearch);
    // 'Savingsearch' makes sure we don't duplicate the redux call
    const savingSearchRef = useRef(false);
    const setSavedSearch = (data) => {
        savedSearchRef.current = data;
        _setSavedSearch(data);
        savingSearchRef.current = false;
    };

    // GETTING SEARCH RESULTS FROM REDUX
    const [searchResults, _setSearchResults] = useState(results);
    const searchResultsRef = useRef(searchResults);
    const setSearchResults = (data) => {
        searchResultsRef.current = data;
        _setSearchResults(data);
    };
    useEffect(() => {
        if (results)
            setSearchResults(
                results.map((job) => {
                    return {
                        title: job.titles,
                        greenhouse_public_id: job.greenhouse_public_id,
                    };
                })
            );
        else setSearchResults([]);
    }, [results]);

    useEffect(() => {
        if (analytics.savedSearch) {
            setSavedSearch(analytics.savedSearch);
        }
    }, [analytics.savedSearch]);

    // Create listeners
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        window.addEventListener('scroll', captureScroll);
        dispatch(analyticsActions.requestSession());
    }, []);
    // We want to send each new search to analytics
    // so we assume that the user will scroll to see results after they input a new search
    const captureScroll = () => {
        const currentSaving = savingSearchRef.current;

        if (!currentSaving) {
            // Get the current states from their refs
            let currentAllQueries = allQueriesRef.current;
            const currentSavedSearch = savedSearchRef.current;

            // If the search queries have changed since the last time they was sent to analytics
            // then resend them and the results
            for (let key in currentAllQueries) {
                if (Array.isArray(currentAllQueries[key])) {
                    // If the query is an array, check if the length is different than what was saved
                    // that means a filter was added or removed
                    if (
                        currentAllQueries[key].length !==
                        currentSavedSearch[key].length
                    ) {
                        dispatch(
                            analyticsActions.enterSearch({
                                ...allQueriesRef.current,
                            })
                        ); // Saving search query into reducer
                        dispatch(
                            analyticsActions.sendAnalytics(
                                'submit-search-query',
                                'submission',
                                'career',
                                processSearch({ ...allQueriesRef.current }, [
                                    ...searchResultsRef.current,
                                ])
                            )
                        );
                        savingSearchRef.current = true;
                        break;
                    }
                }
                // If the query is not an array, just check if it is different than what was saved
                else if (currentAllQueries[key] !== currentSavedSearch[key]) {
                    dispatch(
                        analyticsActions.enterSearch(...allQueriesRef.current)
                    ); // Saving search query into reducer
                    dispatch(
                        analyticsActions.sendAnalytics(
                            'submit-search-query',
                            'submission',
                            'career',
                            processSearch({ ...allQueriesRef.current }, [
                                ...searchResultsRef.current,
                            ])
                        )
                    );
                    savingSearchRef.current = true;
                    break;
                }
            }
        }
    };
    //////////////////// END OF SAVE SEARCH ANALYTICS ////////////////////

    //////////////////// RESPONSIVE DESIGN WIDTHS ////////////////////
    const [width, setWidth] = useState(window.innerWidth);
    // Get window size for responsive design
    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    };
    const isMobileProfile = width < 478;
    const isMobile = width < 790;
    const isTablet = width < 991;

    let margins = desktopMargin;
    if (isMobileProfile) {
        margins = mobileProfileMargin;
    } else if (isMobile) {
        margins = mobileLandscapeMargin;
    } else if (isTablet) {
        margins = tabletMargin;
    }
    //////////////////// END OF RESPONSIVE DESIGN WIDTHS ////////////////////

    const { uploadedResume } = useSelector((state) => state.jobs);

    //////////////////// LOCK SCREEN ////////////////////
    // Not locked currently
    // const [lock, setLock] = useState(true)
    // if (ENV !== 'development' && lock) {
    //     // Password Protected Page
    //     return (
    //         <LockScreen
    //             isMobile={isMobile}
    //             password={"marlonteam"}
    //             setLock={setLock}
    //         />
    //     )
    // }
    //////////////////// END OF LOCK SCREEN ////////////////////

    // Wrapper to save the current url parameter to redux
    const location = useLocation();
    if (location['search'] !== urlQuery) {
        dispatch(utilityAction.setQuery(location['search']));
    }

    return (
        <HelmetProvider>
            <div className="App" style={{ height: '100%' }}>
                <NavBar margin={margins} width={width} />
                <div
                    className="Body"
                    style={{ height: '100%', minHeight: '70vh' }}
                >
                    <AdPixel id={mainPixelId} />

                    {!location.pathname.includes('files') && (
                        <>
                            <Banner
                                isMobile={isMobile}
                                width={width}
                                margin={margins}
                                location={location}
                            />
                            <SearchBar
                                isMobile={isMobile}
                                width={width}
                                margin={margins}
                                location={location}
                                filterSearch={filterSearch}
                            />
                            {!location.pathname.includes('upload') &&
                                !uploadedResume && (
                                    <GetRecJobsLink
                                        isMobile={isMobile}
                                        width={width}
                                        margin={margins}
                                        location={location}
                                    />
                                )}
                        </>
                    )}

                    <div
                        className={classes.container}
                        style={{ marginRight: margins, marginLeft: margins }}
                    >
                        <Routes>
                            <Route
                                path="/upload"
                                element={
                                    <FileUpload
                                        isMobile={isMobile}
                                        width={width}
                                    />
                                }
                            />
                            <Route
                                path="/job/SDE/:greenhouse_public_id"
                                element={
                                    <JobDetails
                                        isMobile={isMobile}
                                        width={width}
                                        botType={'SDE'}
                                    />
                                }
                            />
                            <Route
                                path="/job/SDR/:greenhouse_public_id"
                                element={
                                    <JobDetails
                                        isMobile={isMobile}
                                        width={width}
                                        botType={'SDR'}
                                    />
                                }
                            />
                            <Route
                                path="/job/CSM/:greenhouse_public_id"
                                element={
                                    <JobDetails
                                        isMobile={isMobile}
                                        width={width}
                                        botType={'CSM'}
                                    />
                                }
                            />
                            <Route
                                path="/job/DSML/:greenhouse_public_id"
                                element={
                                    <JobDetails
                                        isMobile={isMobile}
                                        width={width}
                                        botType={'DSML'}
                                    />
                                }
                            />
                            <Route
                                path="/job/TPM/:greenhouse_public_id"
                                element={
                                    <JobDetails
                                        isMobile={isMobile}
                                        width={width}
                                        botType={'TPM'}
                                    />
                                }
                            />
                            <Route
                                path="/job/SDM/:greenhouse_public_id"
                                element={
                                    <JobDetails
                                        isMobile={isMobile}
                                        width={width}
                                        botType={'SDM'}
                                    />
                                }
                            />
                            <Route
                                path="/job/BusinessAnalyst/:greenhouse_public_id"
                                element={
                                    <JobDetails
                                        isMobile={isMobile}
                                        width={width}
                                        botType={'businessAnalyst'}
                                    />
                                }
                            />
                            <Route
                                path="/job/accountExec/:greenhouse_public_id"
                                element={
                                    <JobDetails
                                        isMobile={isMobile}
                                        width={width}
                                        botType={'accountExec'}
                                    />
                                }
                            />
                            <Route
                                path="/job/UI/:greenhouse_public_id"
                                element={
                                    <JobDetails
                                        isMobile={isMobile}
                                        width={width}
                                        botType={'UI'}
                                    />
                                }
                            />
                            <Route
                                path="/job/projectCoordinator/:greenhouse_public_id"
                                element={
                                    <JobDetails
                                        isMobile={isMobile}
                                        width={width}
                                        botType={'projectCoordinator'}
                                    />
                                }
                            />
                            <Route
                                path="/job/compensationAnalyst/:greenhouse_public_id"
                                element={
                                    <JobDetails
                                        isMobile={isMobile}
                                        width={width}
                                        botType={'compensationAnalyst'}
                                    />
                                }
                            />
                            <Route
                                path="/job/accountant/:greenhouse_public_id"
                                element={
                                    <JobDetails
                                        isMobile={isMobile}
                                        width={width}
                                        botType={'accountant'}
                                    />
                                }
                            />

                            <Route
                                path="/job/:greenhouse_public_id"
                                element={
                                    <JobDetails
                                        isMobile={isMobile}
                                        width={width}
                                    />
                                }
                            />

                            <Route
                                path="/files/gdpr"
                                element={
                                    <EmbeddedFile
                                        width={width}
                                        margin={margins}
                                        file={gdpr}
                                    />
                                }
                            />
                            <Route
                                path="/files/ccpa"
                                element={
                                    <EmbeddedFile
                                        width={width}
                                        margin={margins}
                                        file={ccpa}
                                    />
                                }
                            />

                            <Route
                                exact
                                path="/"
                                element={
                                    <SearchResults
                                        isMobile={isMobile}
                                        width={width}
                                    />
                                }
                            />
                            <Route
                                path="/candidateAction/:email"
                                component={CandidateDeletion}
                            />

                            <Route
                                path="/"
                                element={
                                    <ErrorPage
                                        isMobile={isMobile}
                                        pageType={'notFound'}
                                    />
                                }
                            />
                        </Routes>
                    </div>
                </div>
                <Footer margin={margins} isMobile={isMobile} width={width} />
            </div>
        </HelmetProvider>
    );
}

export default App;
