import { jobConstants } from './'

const initialState = {}

export function job(state = initialState, action){
    switch(action.type){
        case jobConstants.GET_JOB_REQUEST:
            return{
                ...state,
                job: null,
                loading: true,
                error: false,
            }
        case jobConstants.GET_JOB_SUCCESS:
            return {
                ...state,
                job: action.data.job,
                loading: false
            }
        case jobConstants.GET_JOB_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }

        default:
            return state
    }
}

export function jobs(state = initialState, action){
    switch(action.type){
        case jobConstants.GET_ALL_JOBS_REQUEST:
            return{
                ...state,
                jobs: null,
                loading: true,
                error: false,
            }
        case jobConstants.GET_ALL_JOBS_SUCCESS:
            return{
                ...state,
                jobs: action.data.jobs,
                uploadedResume: action.data.uploadedResume,
                loading: false
            }
        case jobConstants.GET_ALL_JOBS_FAILURE:
            return {
                ...state,
                error: action.error.message,
                loading: false
            }

        default:
            return state 
    }
}