export const analyticsConstants = { 
    SAVE_SEARCH: 'SAVE_SEARCH',
    SEND_SEARCH_REQUEST: 'SEND_SEARCH_REQUEST',
    SEND_SEARCH_SUCCESS: 'SEND_SEARCH_SUCCESS',
    SEND_SEARCH_FAILURE: 'SEND_SEARCH_FAILURE',

    TRACK_APPLY_REQUEST: 'TRACK_APPLY_REQUEST',
    TRACK_APPLY_SUCCESS: 'TRACK_APPLY_SUCCESS',
    TRACK_APPLY_FAILURE: 'TRACK_APPLY_FAILURE',

    TRACK_CLICK_JOB_REQUEST: 'TRACK_CLICK_JOB_REQUEST',
    TRACK_CLICK_JOB_SUCCESS: 'TRACK_CLICK_JOB_SUCCESS',
    TRACK_CLICK_JOB_FAILURE: 'TRACK_CLICK_JOB_FAILURE',

    SEND_ANALYTICS_REQUEST: 'SEND_ANALYTICS_REQUEST',
    SEND_ANALYTICS_SUCCESS: 'SEND_ANALYTICS_SUCCESS',
    SEND_ANALYTICS_FAILURE: 'SEND_ANALYTICS_FAILURE',

    // initializing new session
    SESSION_REQUEST: 'SESSION_REQUEST',
    SESSION_SUCCESS: 'SESSION_SUCCESS',
    SESSION_FAILURE: 'SESSION_FAILURE',
}