import { Button, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from "react-redux"
import { colors } from "../theme/colors";
import File from '../assets/file.svg'
import UploadCloud from '../assets/UploadCloudBlue.svg'
import { Link } from '../components/Utility/Link'

const useStyles = makeStyles(() => ({
    container: {
        border: '2px solid rgba(255, 255, 255, 0.22)',
        borderRadius: '16px',
        padding: '10px 32px 10px 10px',
        display: 'flex',
        alignItems: 'center',
    },
    uploadImg: {
        padding: '10px'
    },
    fileInfoContainer: {
        backgroundColor: colors.webKit.transparencies.darkTransparent,
        borderRadius: '16px',
        alignItems: 'center',
        padding: '10px',
        display: 'flex'
    },
    fileNameText: {
        fontWeight: 700
    },
    fileIcon: {
        margin: '0px 10px',
        height: '25px'
    },
    reuploadButton: {
        width: '100%',
        marginTop: '24px',
        marginBottom: '16px',
        height: '50px',
        '&:hover': {
            backgroundColor: colors.webKit.transparencies.lightTransparent,
            color: colors.webKit.primary.text
        }
    },
    link: {
        textDecoration: 'none'
    }
}))

export function CurrentResume(props) {
    const { blobURL, fileName, fileModified } = useSelector(state => state.resume)
    const classes = useStyles();

    if (!fileName)
        return <> </>

    return (
        <div>
            <div className={classes.fileInfoContainer}
                style={{ maxHeight: '52px', minHeight: '52px' }}>
                <img src={File} className={classes.fileIcon} />

                <div style={{ display: 'block' }}>
                    <Typography className={classes.fileNameText}>
                        {fileName}
                    </Typography>
                    {fileModified &&
                        <Typography>
                            {new Date(fileModified).toLocaleDateString()}
                        </Typography>
                    }
                </div>
            </div>
            <Link to="/upload" className={classes.link}>
                <Button variant="outlined" color="primary" className={classes.reuploadButton}
                    startIcon={<img src={UploadCloud} />}
                >
                    Re-upload resume
                </Button>
            </Link>
        </div>
    )
}