import { candidateActionConstants } from './constants'

export function queryAvailableAction(state = {}, action){
    switch(action.type){
        
        case candidateActionConstants.QUERY_ACTION_REQUEST:
            return {
                loading: true
            }

        case candidateActionConstants.QUERY_ACTION_SUCCESS:
            return {
                loading: false,
                success: true,  
                titleList: action.payload,
                error: false,
            }       

        case candidateActionConstants.QUERY_ACTION_FAILURE:
            return {
                loading: false,
                error: action.error
            }

        default: 
            return state 
    }
}

export function deleteAvailableApplications(state = {}, action){
    switch(action.type){
        
        case candidateActionConstants.DELETE_APPLICATIONS_REQUEST:
            return {
                loading: true
            }

        case candidateActionConstants.DELETE_APPLICATIONS_SUCCESS:
            return {
                loading: false,
                success: true,
            }       

        case candidateActionConstants.DELETE_APPLICATIONS_FAILURE:
            return {
                loading: false,
                error: action.error
            }

        default: 
            return state 
    }
}
