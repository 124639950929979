export const colors = {
    darkTheme: {
        blue:  {
            default: "#0C50FF",
            dark: "#0841D4",
            darker: "#0632A1",
            darkest: "#2F325D",
        },
        orange: {
            default: "#FF6900"
        },
        focus: {
            inner: "#0C1836",
            outer: "#FFFFFF"
        }
    },
    webKit: {
        primary: {
            text: '#1C1D22',

            veryLightGrey: "#F4F4F6",
            lightGrey: "#AFB3C0",
            grey: "#E7E8EC",

            footer: "#101040",

            liveOrange: '#FF6900',
            electricBlue: '#3863E5',
            lightBlue: "rgb(56, 99, 229, 0.3)",
            pink: '#EBB7D4',
            divider: '#D1D4DB',

            periwinkle: '#4667C8',
            periwinkleLight: '#8BA3EA',
            periwinkleLighter: '#A5B9F3',
            white: '#FCFCFC',
            alert: '#F7A2B5',
        },
        gradients: {
            bluePink1: 'linear-gradient(90deg, #3863E5 0%, #8D46EB 43.18%, #E849B7 86.36%)',
        },
        lavenders: {
            lavender60: '#D0D0F6',
            lavender40: '#E0E0F9',
            lavender20: '#EFEFFC',
        },
        hover: {
            orangeDark: '#DB5A00',
            electricBlueDark: '#0040D6',
            periwinkleDark: '#3955A7',
            periwinkle: '#4667C8',
        },
        grays: {
            cloudLighter: '#F0F2FA',
            cloudLight: '#D6DAE6',
            cloud: '#A7ACBE',
            cloudDark: '#6C7189',
            cloudDarker: '#485065',
            cloudDarkest: '#2C3249',
        },
        navys: {
            navy: '#090C43',
            navyLight: '#1C1F52',
            navyLighter: '#2E305F',
            navyLightest: '#3F416C',
        },
        transparencies: {
            darkTransparent: 'rgba(0, 0, 0, 0.08)',
            mediumTransparent: 'rgba(0, 0, 0, 0.15)',
            lightTransparent: 'rgba(0, 0, 0, 0.22)',
        }
    },
    linkedIn: {
        blue: '#2E72AC',
        white: '#FFFFFF'
    }
}