import { Button, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Link } from './Utility/Link'
import ErrorBot from "../assets/ErrorBot.png"

const useStyles = makeStyles(() => ({
    actionButton: {
        width: '160px',
        height: '40px'
    },
    mobileActionButton: {
        width: '140px',
        height: '40px'
    },
    link: {
        textDecoration: 'none'
    }
}))

const homeUrl = "https://liveperson.com/company/careers"
const jobBoardUrl = "/"

export function ErrorPage(props) {
    const { isMobile, pageType } = props;
    const classes = useStyles();
    let title
    let subTitle

    if (pageType === 'homeNotFound' || pageType === 'notFound') {
        title = "Oops, we can't seem to find the page you are looking for."
        subTitle = "The page you are looking for doesn't exist, please try again in a few minutes"
    }
    else if (pageType === 'skillsAi') {
        title = "Sorry, I'm in Beta"
        subTitle = "I'm still learning right now and some formats give me trouble.  Please use our search or filters to help locate the right opportunity for you."
    }

    return (
        <div
            style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginTop: isMobile ? '50px' : '3vh',
                marginBottom: isMobile ? '50px' : '3vh',
            }}>
            <div style={{ textAlign: 'center', maxWidth: '850px' }}>
                <Typography variant="h2">{title}</Typography>
                <Typography variant="body1" style={{ marginTop: '24px' }}>{subTitle}</Typography>
                <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: '34px' }}>
                    <SecondaryButton pageType={pageType} 
                        className={isMobile ? classes.mobileActionButton : classes.actionButton}
                    />
                    <PrimaryButton pageType={pageType} 
                        className={isMobile ? classes.mobileActionButton : classes.actionButton}
                    />
                </div>
            </div>
        </div>
    )
}

function SecondaryButton(props) {
    const {pageType, className} = props;
    const classes = useStyles();
    if (pageType === 'homeNotFound' || pageType === 'notFound')
        return (
            <Button variant='outlined' color="secondary"
                onClick={() => window.location.reload(true)}
                className={className}
            >
                Try Again
            </Button>
        )
    else if (pageType === 'skillsAi')
        return (
            <a href={homeUrl} className={classes.link}>
                <Button variant='outlined' color="secondary"
                    className={className}
                >
                    Back to Home
                </Button>
            </a>
        )
    return (<> </>)
}

function PrimaryButton(props) {
    const {pageType, className} = props;
    const classes = useStyles();

    if (pageType === 'homeNotFound')
        return (
            <a href={homeUrl} className={classes.link}>
                <Button variant='contained' color="primary"
                    className={className}
                >
                    Back to Home
                </Button>
            </a>
        )
    else if (pageType === 'notFound')
        return (
            <Link anchor to={jobBoardUrl} className={classes.link}>
                <Button variant='contained' color="primary"
                    className={className}
                >
                    Back to Home
                </Button>
            </Link>
        )
    else if (pageType === 'skillsAi')
        return (
            <Link to={jobBoardUrl} className={classes.link}>
                <Button variant='contained' color="primary"
                    className={className}
                >
                    Search
                </Button>
            </Link>
        )
    return (<> </>)
}