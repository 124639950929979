import axios from 'axios'

import { ANALYTICS_ENABLED, ANALYTICS_ROOT } from "../../settings"

export const analyticsService = {
    sendSearch,
    trackApply,
    trackClickMatchedJob,
    sendAnalytics,
    requestSession
}

//DEPRECATED
//correspond to searchAnalytics in firestore and not searchRequests (which itself is not linked to anything)
async function sendSearch(queries, results) {
    let payload = {
        queries: queries,
        results: results,
    }
    if (!ANALYTICS_ENABLED)
        return {}

    // Using fetch because axios was having problems
    let response = await fetch (`${ANALYTICS_ROOT}/search_analytics`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload)
    })

    return response.data
}

//DEPRECATED
//correspond to appliedJobs on firestore
async function trackApply(payload) {
    if (!ANALYTICS_ENABLED)
        return {}

    // Using fetch because axios was having problems
    let response = await fetch (`${ANALYTICS_ROOT}/applied_jobs`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload)
    })

    return response.data
}

//DEPRECATED
//correspond to matchClicks on firestore 
async function trackClickMatchedJob(payload) {
    if (!ANALYTICS_ENABLED)
        return {}

    let response = await axios.post(
        `${ANALYTICS_ROOT}/click_matched_jobs`,
        payload,
        {
            headers: {
                'Content-Type': 'application/json',
            },
        }
    )

    return response.data
}

/**
 * Request for a new analytics session from the backend
 *  @param {Object} sessionContext Context to link relevant session with uid
    @returns {Object} JSON response with session data, uid, and access_token to be used in sendAnalytics call
*/
async function requestSession(sessionContext = {}){
    if (!ANALYTICS_ENABLED)
        return { "msg": "Analytics is disabled" }

    // Retrieving uid from localStorage to link session to analyticsUser profile. 
    // If no uid exists, set to null to generate new user
    sessionContext['uid'] = localStorage.getItem('uid')
    if (sessionContext['uid'] === null)
        sessionContext = null

    let response = await axios.post(
        `${ANALYTICS_ROOT}/request_session`,
        sessionContext,
        {
            headers: {
                'Content-Type': 'application/json',
            },
        }
    )

    return response.data
}

/**
 * The post request to send analytics to the backend
 * 
 * @param {Object} payload any analytics data that have passed the eventBuilder obj creation
 *  
 * @returns {Object} response object from the api request
 */
async function sendAnalytics(payload){
    if (!ANALYTICS_ENABLED)
        return { "msg": "Analytics is disabled" }

    
    let token = localStorage.getItem('analytics_token') // Access Token generated by request_session

    let response = await axios.post(
        `${ANALYTICS_ROOT}/save_events`,
        payload,
        {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`
            },
        }
    )

    return response.data
}
