import { applicationConstants, applicationService } from './'

export const applicationActions = {
    applyToJob,
    resetAppForm
}

function applyToJob(greenhouse_public_id, data, eeoc) {
    return dispatch => {
        dispatch(request())

        applicationService.applyToJob(greenhouse_public_id, data, eeoc).then(
            data => {
                dispatch(success(data))
            }
        ).catch(
            error => {
                dispatch(failure(error))
            }
        )
    }

    function request(){return {type: applicationConstants.APPLY_TO_JOB_REQUEST, }}
    function success(data){return {type: applicationConstants.APPLY_TO_JOB_SUCCESS, data}}
    function failure(error){return {type: applicationConstants.APPLY_TO_JOB_FAILURE, error}}
}

function resetAppForm(){
    return dispatch => {
        dispatch(resetApp())
    }

    function resetApp(){return {type: applicationConstants.APPLY_RESET}}
}