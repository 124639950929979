import React, { useEffect, useRef, useState } from 'react';
import {
    Route,
    useLocation,
    BrowserRouter as Router,
    Switch,
} from 'react-router-dom';
import axios from 'axios';

import { API_ROOT } from '../../settings';
import { job } from '.';

export const jobService = {
    getJob,
    getAllJobs,
    getSkillAIJobs,
};

async function getJob(greenhouse_public_id) {
    // let response = await axios.get(
    //     `${API_ROOT}/job/${greenhouse_public_id}`,
    // )
    let response = await axios.get(
        `https://boards-api.greenhouse.io/v1/boards/liveperson/jobs/${greenhouse_public_id}`
    );

    let parsedJob = await tagJobLocations([response.data]);
    let job = parsedJob[0];

    return {
        ...response.data,
        job: job,
    };
}

async function getAllDepartments() {
    let response = await axios.get(
        `https://boards-api.greenhouse.io/v1/boards/liveperson/departments`
    );
    let departments = [];
    if (response.data.departments) {
        response.data.departments.forEach((department) => {
            const jobs = [];
            if (department.jobs.length > 0) {
                department.jobs.forEach((job) => {
                    jobs.push(job.id);
                });
            }
            delete department.jobs;
            departments.push({
                ...department,
                job_ids: jobs,
            });
            return '';
        });
    }
    return departments;
}

async function getAllJobs() {
    // let response = await axios.get(
    //     `${API_ROOT}/job`,
    // )

    let response = await axios.get(
        `https://boards-api.greenhouse.io/v1/boards/liveperson/jobs`
    );

    let jobs = await tagJobLocations(response.data.jobs);

    jobs = jobs.sort((a, b) => {
        if (a.titles > b.titles) return 1;
        if (a.titles < b.titles) return -1;
        return 0;
    });

    return {
        ...response.data,
        jobs: jobs,
        uploadedResume: false,
    };
}

async function getSkillAIJobs(data) {
    console.log(data);
    // let response = await axios.post(
    //     `${API_ROOT}/job`,
    //     data
    // )
    let response = await axios.get(
        `https://boards-api.greenhouse.io/v1/boards/liveperson/jobs`
    );

    let jobs = tagJobLocations(response.data.jobs);
    for (let index in jobs) {
        let job = jobs[index];
        if (index === 6) break;
        else if (index < 2) job.match = 'best';
        else if (index < 6) job.match = 'Good';
    }

    return {
        ...response.data,
        jobs: jobs,
        uploadedResume: true,
    };
}

async function tagJobLocations(jobs) {
    let getDepartments = await getAllDepartments();

    for (let i = 0; i < jobs.length; i++) {
        const job = jobs[i];
        job.greenhouse_public_id = job.greenhouse_public_id || job.id;
        job.jid = job.jid || job.id;

        // This is because for some weird reason, there was a job whose title started with a " "
        if (!job.titles && job.title) job.titles = [job.title];
        if (job.titles[0] === ' ') {
            job.titles = job.titles.replace(/ /, '');
        }

        let departments = new Set();
        getDepartments.forEach((department) => {
            if (department.job_ids.includes(job.id)) {
                departments.add(department);
            }
        });

        let location = '';
        if (job['location'][0]) location = job['location'][0];
        else location = job['location']['name'];

        let countries = new Set();
        let cities = new Set();

        if (location) {
            location = location.replace(/,/g, '');
            location = location.replace(/-/g, '');
            location = location.replace(/\./g, '');

            if (location.includes('US')) {
                countries.add('United States');
                countries.add('America');
                countries.add('USA');
                countries.add('The US');
            } else if (location.includes('UK')) {
                countries.add('United Kingdom');
                countries.add('UK');
            }

            location = location.toLowerCase();
            location = location.replace(/ /g, '');

            if (location.includes('alpharetta')) {
                cities.add('Alpharetta GA, US');
                countries.add('United States');
                countries.add('The US');
                countries.add('America');
                countries.add('USA');
            }
            if (location.includes('atlanta')) {
                cities.add('Atlanta GA, US');
                countries.add('United States');
                countries.add('The US');
                countries.add('America');
                countries.add('USA');
            }
            if (location.includes('colorado')) {
                cities.add('Colorado, US');
                countries.add('United States');
                countries.add('The US');
                countries.add('America');
                countries.add('USA');
            }
            if (location.includes('dallas')) {
                cities.add('Dallas TX, US');
                countries.add('United States');
                countries.add('The US');
                countries.add('America');
                countries.add('USA');
            }
            if (location.includes('mountainview')) {
                cities.add('Mountain View, US');
                countries.add('United States');
                countries.add('The US');
                countries.add('America');
                countries.add('USA');
            }
            if (location.includes('newyork')) {
                cities.add('New York NY, US');
                countries.add('United States');
                countries.add('The US');
                countries.add('America');
                countries.add('USA');
            }
            if (location.includes('sanfrancisco')) {
                cities.add('San Francisco CA, US');
                countries.add('United States');
                countries.add('The US');
                countries.add('America');
                countries.add('USA');
            }
            if (location.includes('seattle')) {
                cities.add('Seattle WA, US');
                countries.add('United States');
                countries.add('The US');
                countries.add('America');
                countries.add('USA');
            }
            if (location.includes('unitedstates')) {
                countries.add('United States');
                countries.add('The US');
                countries.add('America');
                countries.add('USA');
            }

            if (location.includes('melbourne')) {
                cities.add('Melbourne, Australia');
                countries.add('Australia');
            }
            if (location.includes('sydney')) {
                cities.add('Sydney, Australia');
                countries.add('Australia');
            } else if (location.includes('australia')) {
                countries.add('Australia');
            }

            if (location.includes('telaviv')) {
                cities.add('Tel-Aviv, Israel');
                countries.add('Israel');
            } else if (location.includes('jerusalem')) {
                cities.add('Jerusalem, Israel');
                countries.add('Israel');
            } else if (location.includes('israel')) {
                countries.add('Israel');
            }

            if (location.includes('singaporecity')) {
                cities.add('Singapore City, Singapore');
                countries.add('Singapore');
            } else if (location.includes('singapore')) {
                countries.add('Singapore');
            }

            if (
                location.includes('poland') ||
                location.indexOf('poland') > -1
            ) {
                countries.add('Poland');
            }

            if (location.includes('berlin')) {
                cities.add('Berlin, Germany');
                countries.add('Germany');
            } else if (location.includes('cologne')) {
                cities.add('Cologne, Germany');
                countries.add('Germany');
            } else if (location.includes('sseldorf')) {
                cities.add('Düsseldorf, Germany');
                countries.add('Germany');
            } else if (location.includes('frankfurt')) {
                cities.add('Frankfurt, Germany');
                countries.add('Germany');
            } else if (location.includes('hamburg')) {
                cities.add('Hamburg, Germany');
                countries.add('Germany');
            }
            // because some people spell it different
            else if (
                location.includes('mannheim') ||
                location.includes('manheim')
            ) {
                cities.add('Mannheim, Germany');
                cities.add('Manheim, Germany');
                countries.add('Germany');
            } else if (location.includes('munich')) {
                cities.add('Munich, Germany');
                countries.add('Germany');
            } else if (location.includes('leipzig')) {
                cities.add('Leipzig, Germany');
                countries.add('Germany');
            } else if (location.includes('germany')) {
                countries.add('Germany');
            }

            if (location.includes('tokyo')) {
                cities.add('Tokyo, Japan');
                countries.add('Japan');
            } else if (location.includes('japan')) {
                countries.add('Japan');
            }

            if (location.includes('canada')) {
                countries.add('Canada');
            }

            if (location.includes('birmingham')) {
                cities.add('Birmingham, United Kingdom');
                countries.add('United Kingdom');
                countries.add('UK');
            } else if (location.includes('london')) {
                cities.add('London, United Kingdom');
                countries.add('United Kingdom');
                countries.add('UK');
            } else if (location.includes('manchester')) {
                cities.add('Manchester, United Kingdom');
                countries.add('United Kingdom');
                countries.add('UK');
            } else if (location.includes('reading')) {
                cities.add('Reading, United Kingdom');
                countries.add('United Kingdom');
                countries.add('UK');
            } else if (location.includes('coventry')) {
                cities.add('Coventry, United Kingdom');
                countries.add('United Kingdom');
                countries.add('UK');
            } else if (location.includes('unitedkingdom')) {
                countries.add('United Kingdom');
                countries.add('UK');
            }

            if (location.includes('madrid')) {
                cities.add('Madrid, Spain');
                countries.add('Spain');
            } else if (location.includes('barcelo')) {
                cities.add('Barcelona, Spain');
                countries.add('Spain');
            } else if (location.includes('spain')) {
                countries.add('Spain');
            }

            if (location.includes('milan')) {
                cities.add('Milan, Italy');
                countries.add('Italy');
            } else if (location.includes('rome')) {
                cities.add('Rome, Italy');
                countries.add('Italy');
            } else if (location.includes('italy')) {
                countries.add('Italy');
            }

            if (location.includes('amsterdam')) {
                cities.add('Amsterdam, Netherlands');
                countries.add('Netherlands');
            } else if (location.includes('netherlands')) {
                countries.add('Netherlands');
            }

            if (
                location.includes('mexico') &&
                !location.includes('newmexico')
            ) {
                countries.add('Mexico');
            }

            if (location.includes('brazil')) {
                countries.add('Brazil');
            }

            if (location.includes('france')) {
                countries.add('France');
            }

            if (location.includes('manila')) {
                cities.add('Manila, Phillippines');
                countries.add('Phillippines');
            } else if (location.includes('phillippines')) {
                countries.add('Phillippines');
            }

            if (location.includes('sofia')) {
                cities.add('Sofia, Bulgaria');
                countries.add('Bulgaria');
            } else if (location.includes('bulgaria')) {
                countries.add('Bulgaria');
            }

            if (location.includes('india')) {
                countries.add('India');
            }

            job['departments'] = Array.from(departments);
            job['countries'] = Array.from(countries);
            job['cities'] = Array.from(cities);
            if (job['cities'].length === 0) job['cities'] = [''];
            if (job['countries'].length === 0) job['countries'] = [''];
        }

        let sanitizedTitle = job.title
            .toLowerCase()
            .trim()
            .replace(/[^\w\s-]/g, '')
            //   .replace(/[\s_-]+/g, '')
            .replace(/[\[\]']+/g, '')
            .replace(/\(|\)/g, '')
            .replace(/^-+|-+$/g, '')
            .replace(/-/g, '')
            .replace(/ +(?= )/g, '');

        const matchedCats = matchCategories(sanitizedTitle);
        job['categories'] = matchedCats || [];

        const matchedSenority = matchSenority(sanitizedTitle);
        job['career_stage'] = matchedSenority || '';
    }

    return jobs;
}

// Match categories
function titleContains(title, match) {
    return title.indexOf(match) > -1;
}

function matchCategories(title) {
    const catTitles = {
        sales: ['Sales'],
        customer_success: ['Customer Success'],
        sales_eng: ['Sales Engineers'],
        operations: ['Operations'],
        prod_prog_manager: ['Product & Program Management'],
        engineering: ['Engineering'],
        data_ml: ['Data Science & ML'],
        design: ['Design'],
        marketing_comms: ['Marketing & Comms'],
        enablement: ['Enablement'],
        finance_accounting: ['Finance & Accounting'],
        people: ['People'],
        legal: ['Legal'],
        sec_IT: ['Security & IT'],
        customer_exp: ['Customer Experience'],
    };

    if (titleContains(title, 'recruit') || titleContains(title, 'talent'))
        if (titleContains(title, 'operations')) return catTitles['operations'];
        else return catTitles['people'];

    if (titleContains(title, 'strategic account manager'))
        return catTitles['customer_success'];


    if (titleContains(title, ' hr ') || titleContains(title, 'hrbp'))
        return catTitles['people'];

    if (titleContains(title, 'security')) return catTitles['sec_IT'];

    if (
        titleContains(title, 'product man') ||
        titleContains(title, 'program man') ||
        titleContains(title, 'project manag') ||
        titleContains(title, 'project coord')
    )
        return catTitles['prod_prog_manager'];

    if (
        titleContains(title, 'implementation manag') &&
        titleContains(title, 'project')
    )
        return catTitles['prod_prog_manager'];

    if (titleContains(title, 'engineer'))
        if (titleContains(title, 'solutions'))
            return catTitles['customer_success'];
        else if (titleContains(title, 'director'))
            return catTitles['prod_prog_manager'];
        else if (titleContains(title, 'big data'))
            if (
                titleContains(title, 'backend') ||
                titleContains(title, 'back end')
            )
                return [
                    ...catTitles['engineering'],
                    ...catTitles['data_ml'],
                    'Machine Learning',
                ];
            else if (titleContains(title, 'site reliabil'))
                if (titleContains(title, 'software dev'))
                    return catTitles['engineering'];
                else return catTitles['sec_IT'];
            else if (titleContains(title, 'ux')) return catTitles['design'];
            else if (titleContains(title, 'sales'))
                return catTitles['sales_eng'];
            else if (
                titleContains(title, ' ai ') &&
                !titleContains(title, 'convers')
            )
                return [...catTitles['data_ml'], 'Machine Learning'];
            else if (titleContains(title, 'demo'))
                return [...catTitles['sales_eng'], 'Demo Engineer'];
            else return catTitles['engineering'];

    if (titleContains(title, 'sde'))
        return [
            ...catTitles['engineering'],
            'Software Developer',
            'Software',
            'Computer Science',
            'SWE',
        ];

    if (titleContains(title, 'develop'))
        if (titleContains(title, 'sales')) return catTitles['sales'];
        else
            return [
                ...catTitles['engineering'],
                'Software Developer',
                'Software',
                'Computer Science',
                'SWE',
            ];
    if (titleContains(title, 'r&d')) return catTitles['engineering'];

    if (titleContains(title, 'consultant')) return catTitles['sales_eng'];

    if (titleContains(title, 'sales '))
        if (titleContains(title, 'enablement')) return catTitles['enablement'];
        else if (titleContains(title, 'engineer'))
            return catTitles['sales_eng'];
        else if (titleContains(title, 'operations'))
            return catTitles['operations'];
        else return catTitles['sales'];

    if (titleContains(title, 'devops')) return catTitles['engineering'];

    if (
        titleContains(title, 'marketing') ||
        titleContains(title, 'content') ||
        titleContains(title, 'digital strat') ||
        titleContains(title, 'growth')
    )
        return [...catTitles['engineering'], 'Communications', 'Branding'];

    if (titleContains(title, 'account'))
        if (
            titleContains(title, 'accounting') ||
            titleContains(title, 'accounts receiv') ||
            titleContains(title, 'accountant')
        )
            return catTitles['finance_accounting'];
        else if (
            titleContains(title, 'exec') ||
            titleContains(title, 'director')
        )
            // account director, account exec
            return catTitles['sales'];
        else if (titleContains(title, 'account manager'))
            if (titleContains(title, 'technical'))
                return catTitles['customer_exp'];
            else return catTitles['sales'];

    if (titleContains(title, 'social media'))
        return catTitles['marketing_comms'];

    if (titleContains(title, 'creative')) return catTitles['marketing_comms'];

    if (titleContains(title, 'client partner')) return catTitles['sales'];

    if (titleContains(title, 'service knowledge'))
        return catTitles['customer_exp'];

    if (titleContains(title, 'support')) return catTitles['customer_exp'];

    if (titleContains(title, 'solution'))
        if (titleContains(title, 'consultant')) return catTitles['sales_eng'];
        else if (titleContains(title, 'managed'))
            return catTitles['marketing_comms'];
        else return catTitles['customer_success'];

    if (titleContains(title, 'customer'))
        if (titleContains(title, 'journey')) return catTitles['customer_exp'];
        else return catTitles['customer_success'];

    if (
        titleContains(title, 'implementation') ||
        titleContains(title, 'transcript') ||
        titleContains(title, 'transf')
    )
        return catTitles['customer_success'];

    if (titleContains(title, 'scien') || titleContains(title, 'taxonomist'))
        return [...catTitles['data_ml'], 'Machine Learning'];

    if (titleContains(title, 'analy'))
        if (titleContains(title, 'data'))
            // analyst || analytics
            return [...catTitles['data_ml'], 'Machine Learning'];
    if (titleContains(title, 'relations')) return catTitles['marketing_comms'];

    if (titleContains(title, 'design') || titleContains(title, 'ux'))
        return catTitles['design'];

    if (titleContains(title, 'chief of staff'))
        return catTitles['prod_prog_manager'];

    if (titleContains(title, 'operation') || titleContains(title, 'bi manager'))
        return catTitles['operations'];

    if (titleContains(title, 'legal') || titleContains(title, 'counsel'))
        return catTitles['legal'];

    if (
        titleContains(title, 'billing') ||
        titleContains(title, 'financ') ||
        titleContains(title, 'revenue')
    )
        return catTitles['finance_accounting'];

    if (
        titleContains(title, 'regional') ||
        titleContains(title, 'partner director') ||
        titleContains(title, 'global' && titleContains(title, 'partner'))
    )
        return catTitles['sales'];

    if (titleContains(title, 'data architect')) return catTitles['sec_IT'];

    if (titleContains(title, 'vp') && titleContains(title, 'platform'))
        return catTitles['prod_prog_manager'];

    if (titleContains(title, 'monitor')) return catTitles['operations'];

    if (titleContains(title, 'grc')) return catTitles['people'];

    return '';
}

// Match senority
function matchSenority(title) {
    const levelTitles = [
        {
            label: 'Executive',
            matches: [
                'head',
                'top',
                'vp',
                'vice',
                'director',
                'dr',
                'chief',
                'ceo',
                'coo',
                'evp',
                'svp',
                'president',
            ],
        },
        {
            label: 'Senior',
            matches: [
                'sdeiii',
                'sr.',
                'sr',
                'managing',
                'principal',
                'iii',
                'executive',
                'senior',
                '(sr.)',
                'staff',
            ],
        },
        {
            label: 'Mid',
            matches: ['manager', 'lead', 'specialist', 'sdeii', 'ii'],
        },
    ];
    for (let i = 0; i < levelTitles.length; i++) {
        const level = levelTitles[i];
        for (let j = 0; j < level.matches.length; j++) {
            if (title.indexOf(level.matches[j]) > -1) return level.label;
        }
    }

    return 'Entry';
}
