export const ENV = process.env.REACT_APP_ENV

// GOOGLE ANALYTICS ENABLED TODO: 

const ENDPOINTS = {
    "SKILL_ROOT": "",
    "API_ROOT": "",
    "ANALYTICS_ROOT": "",
    "ANALYTICS_ENABLED": "",
    "ENGAGEMENT_ID": "",
    "GOOGLE_ANALYTICS_ENABLED": false
}

switch(ENV){
    case "development":
        ENDPOINTS['SKILL_ROOT'] = "http://localhost:5001"
        ENDPOINTS['API_ROOT'] = "http://localhost:5004/career"
        ENDPOINTS['ANALYTICS_ROOT'] = "http://localhost:5004/analytics"
        ENDPOINTS['ANALYTICS_ENABLED'] = true
        ENDPOINTS['GOOGLE_ANALYTICS_ENABLED'] = false
        ENDPOINTS['ENGAGEMENT_ID'] = ""
        break
    case "testing":
        // TODO: No testing environment rn
        ENDPOINTS['SKILL_ROOT'] = "http://localhost:5001"
        ENDPOINTS['API_ROOT'] = "https://marlon-staging-irfrsqnwrq-uc.a.run.app/career"
        ENDPOINTS['ANALYTICS_ROOT'] = "https://marlon-staging-irfrsqnwrq-uc.a.run.app/analytics"
        ENDPOINTS['ANALYTICS_ENABLED'] = false
        ENDPOINTS['GOOGLE_ANALYTICS_ENABLED'] = false
        ENDPOINTS['ENGAGEMENT_ID'] = ""
        break
    case "staging":
        ENDPOINTS['SKILL_ROOT'] = "https://skillsai-staging-irfrsqnwrq-uc.a.run.app"
        ENDPOINTS['API_ROOT'] = "https://marlon-staging-irfrsqnwrq-uc.a.run.app/career"
        ENDPOINTS['ANALYTICS_ROOT'] = "https://marlon-staging-irfrsqnwrq-uc.a.run.app/analytics"
        ENDPOINTS['ANALYTICS_ENABLED'] = true
        ENDPOINTS['GOOGLE_ANALYTICS_ENABLED'] = false
        ENDPOINTS['ENGAGEMENT_ID'] = {
            "SDE": "LP_DIV_1636144714227",
            "SDR": "LP_DIV_1636144714228",
            "CSM": "LP_DIV_1636144714229",
            "businessAnalyst": "LP_DIV_1636144714230",
            "DSML": "LP_DIV_1636144714231",
            "TPM": "LP_DIV_1636144714232",
            "SDM": "LP_DIV_1636144714233",
            "accountExec": "LP_DIV_1636144714234",
            "UI": "LP_DIV_1636144714235",
            "projectCoordinator": "LP_DIV_1636144714236",
            "compensationAnalyst": "LP_DIV_1636144714237",
            "accountant": "LP_DIV_1636144714238",
        }
        break
    case "production":
    default: 
        ENDPOINTS['SKILL_ROOT'] = "https://skillsai-irfrsqnwrq-uc.a.run.app"
        ENDPOINTS['API_ROOT'] = "https://marlonmongo-irfrsqnwrq-uc.a.run.app/career"
        ENDPOINTS['ANALYTICS_ROOT'] = "https://marlonmongo-irfrsqnwrq-uc.a.run.app/analytics"
        ENDPOINTS['ANALYTICS_ENABLED'] = true
        ENDPOINTS['GOOGLE_ANALYTICS_ENABLED'] = true
        ENDPOINTS['ENGAGEMENT_ID'] = {
            "SDE": "LP_DIV_1629306636188",
            "CSM": "LP_DIV_1629306636189",
            "SDR": "LP_DIV_1629306636190",
            "businessAnalyst": "LP_DIV_1629306636191",
            "DSML": "LP_DIV_1629306636192",
            "TPM": "LP_DIV_1629306636193",
            "SDM": "LP_DIV_1629306636194",
            "accountExec": "LP_DIV_1629306636195",
            "UI": "LP_DIV_1629306636196",
            "projectCoordinator": "LP_DIV_1629306636197",
            "compensationAnalyst": "LP_DIV_1629306636198",
            "accountant": "LP_DIV_1629306636199",
        }
}

export const SKILL_ROOT = ENDPOINTS['SKILL_ROOT']
export const API_ROOT = ENDPOINTS['API_ROOT']
export const ANALYTICS_ROOT = ENDPOINTS['ANALYTICS_ROOT']
export const ANALYTICS_ENABLED = ENDPOINTS['ANALYTICS_ENABLED']
export const GOOGLE_ANALYTICS_ENABLED = ENDPOINTS['GOOGLE_ANALYTICS_ENABLED']
export const ENGAGEMENT_ID = ENDPOINTS['ENGAGEMENT_ID']
