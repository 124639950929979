import React, { useEffect, useState } from 'react';
import ReactHtmlParser from 'html-react-parser';
import { Link } from '../components/Utility/Link';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { jobActions } from '../redux/job';
import { applicationActions } from '../redux/application';
import { analyticsActions } from '../redux/analytics';

// MUI
import { Divider, Grid, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

// Custom
import { ApplyButton } from '../components/ApplyButton';
import { colors } from '../theme/colors';
import RelatedRoles from '../components/JobDetails/RelatedRoles';
import { PageLoading } from '../components/LoadingIndicator';
import { BreadCrumbs } from '../components/BreadCrumbs';
import { LocationOn } from '@mui/icons-material';
import People from '../assets/People.svg';
import { ErrorPage } from '../components/ErrorPage';
import { ApplyOptions } from '../components/JobDetails/ApplyOptions';

import { GOOGLE_ANALYTICS_ENABLED } from '../settings';

const useStyles = makeStyles((theme) => ({
    titleBar: {},
}));

const useMobileStyles = makeStyles(() => ({
    titleBar: {},
}));

export function JobDetails(props) {
    const { isMobile, botType } = props;
    const dispatch = useDispatch();
    const [fetchedJob, setFetchedJob] = useState(null);

    // Get jid from url
    let params = useParams();
    const greenhouse_public_id = params.greenhouse_public_id;

    const appForm = useSelector((state) => state.applyToJob);
    const { job, error, loading } = useSelector((state) => state.job);
    const { jobs, jobsError } = useSelector((state) => state.jobs);

    const [relatedRoles, setRelatedRoles] = useState([]);

    const [gaRoleViewed, setRoleViewed] = useState(false);

    const initializePage = () => {
        dispatch(applicationActions.resetAppForm());
        // Refreshes the job based on the current job ID in url
        dispatch(jobActions.getJob(greenhouse_public_id));
    };

    // When the page loads, initiliaze the redux if necessary
    useEffect(() => {
        if (!job) {
            initializePage();
        }
    }, []);

    // When the job id in the url changes, re initialize the redux
    useEffect(() => {
        if (job) setFetchedJob(job);
        if (
            job &&
            job.greenhouse_public_id !== greenhouse_public_id &&
            !loading &&
            !appForm.loading
        ) {
            initializePage();
        }
    }, [greenhouse_public_id]);

    useEffect(() => {
        if (job) setFetchedJob(job);
        if (
            job &&
            job.greenhouse_public_id === greenhouse_public_id &&
            !gaRoleViewed
        ) {
            // there is no ga in window on staging or local
            if (job.conversational) {
                // Track view smart apply job with Google Analytics
                if (GOOGLE_ANALYTICS_ENABLED)
                    window.ga(
                        'send',
                        'event',
                        'apply',
                        'click',
                        'view-smart-apply-role-description'
                    );
                dispatch(
                    analyticsActions.sendAnalytics(
                        'view-smart-apply-role-description',
                        'click',
                        'career',
                        {}
                    )
                );
            } else {
                // Track view regular job with Google Analytics
                if (GOOGLE_ANALYTICS_ENABLED)
                    window.ga(
                        'send',
                        'event',
                        'apply',
                        'click',
                        'view-regular-role-description'
                    );
                dispatch(
                    analyticsActions.sendAnalytics(
                        'view-regular-role-description',
                        'click',
                        'career',
                        {}
                    )
                );
            }

            setRoleViewed(true);
        }
    }, [job]);

    useEffect(() => {
        if (job) setFetchedJob(job);
        // TODO make this more sophisticated
        if (jobs && jobs.length > 0 && job) {
            const categories = job.categories || [];
            const career_stage = job.career_stage || '';
            const cities = job.cities || [];
            const countries = job.countries || [];

            let sameCategoryJobs = [];
            let sameCareerStageJobs = [];
            let sameCitiesJobs = [];
            let sameCountriesJobs = [];

            // if (categories) {
            // sameCategoryJobs = jobs.filter(j => j.categories[0] === categories[0]);
            // }

            // if(career_stage){
            //     sameCareerStageJobs = jobs.filter(j => j.career_stage === career_stage);
            // }
            // if(cities){
            //     sameCitiesJobs = jobs.filter(j => j.cities[0].map( (city) => {
            //         return(city === cities[0]);
            //     }));
            // }
            // if(countries){
            //     sameCountriesJobs = jobs.filter(j => j.countries[0] === countries[0]);
            // }

            jobs.forEach((job, i) => {
                job.categories.forEach((jobCat) => {
                    if (jobCat === categories[0]) {
                        sameCategoryJobs.push(job);
                    }
                });
                if (career_stage === job.career_stage) {
                    sameCareerStageJobs.push(job);
                }
                job.cities.forEach((jobCity) => {
                    if (cities.includes(jobCity)) {
                        sameCategoryJobs.push(job);
                    }
                });
                job.countries.forEach((jobCountry) => {
                    if (countries.includes(jobCountry)) {
                        sameCountriesJobs.push(job);
                    }
                });
            });

            // logic for deciding what related roles will be there
            // start with jobs of the same category

            let relatedRolesJobs = [];

            if (sameCategoryJobs.length > 0) {
                // if any of the jobs are of the same category and career stage
                let sameCatAndStageJobs = sameCategoryJobs.filter(
                    (j) => j.career_stage === career_stage && j.jid !== job.jid
                );
                // if there are more than 3 shared category and stage jobs, choose those as the recommended roles
                if (sameCatAndStageJobs.length >= 3) {
                    relatedRolesJobs = sameCatAndStageJobs.slice(0, 3);
                    relatedRolesJobs.forEach((relatedRole) => {
                        relatedRoles.push({
                            id: relatedRole.jid,
                            titles: relatedRole.title,
                            location: relatedRole.location[0],
                            career_stage: relatedRole.career_stage,
                            greenhouse_public_id:
                                relatedRole.greenhouse_public_id,
                        });
                    });
                }
            }
            setRelatedRoles(relatedRolesJobs);
        }
    }, [job, jobs]);

    if (jobsError || error || appForm.error)
        return <ErrorPage isMobile={isMobile} pageType="notFound" />;

    if (
        !fetchedJob ||
        loading ||
        parseInt(fetchedJob.greenhouse_public_id) !==
            parseInt(greenhouse_public_id) ||
        appForm.loading
    )
        return <PageLoading />;

    if (isMobile) {
        return (
            <>
                <Helmet>
                    <title>{fetchedJob.title} | LivePerson Careers</title>
                    <link rel="canonical" href={`${document.location}`} />
                    {/* <meta
                                name="description"
                                content="Beginner friendly page for learning React Helmet."
                            /> */}
                </Helmet>
                <BreadCrumbs isMobile currentPageTitle={fetchedJob.title} />
                <Grid container spacing={4} direction="column">
                    <Grid item>
                        {appForm.success ? (
                            <CompletedApp isMobile />
                        ) : (
                            <JobDescription
                                job={fetchedJob}
                                isMobile
                                botType={botType}
                            />
                        )}
                    </Grid>
                    <Grid item>
                        {jobs && <RelatedRoles relatedRoles={relatedRoles} />}
                    </Grid>
                </Grid>
                {ReactHtmlParser(
                    `<script type="application/ld+json">
                                {
                                    "@context": "https://schema.org",
                                    "@type": "JobPosting",
                                    "title": "${fetchedJob.title}",
                                    "hiringOrganization": {
                                        "@type": "Organization",
                                        "name": "LivePerson, Inc"
                                    },
                                    "industry": "${fetchedJob.categories[0]}",
                                    "jobLocation": {
                                        "@type": "Place",
                                            "address": {
                                            "@type": "PostalAddress",
                                            "addressCountry": "${fetchedJob.location['name']}"
                                            }
                                    },
                                    "directApply": "${fetchedJob.absolute_url}"
                                }
                            </script>`
                )}
            </>
        );
    } else {
        return (
            <>
                {appForm.success ? (
                    <>
                        <CompletedApp />
                        <RelatedRoles horizontal relatedRoles={relatedRoles} />
                    </>
                ) : (
                    <>
                        <Helmet>
                            <title>
                                {fetchedJob.title} | LivePerson Careers
                            </title>
                            <link
                                rel="canonical"
                                href={`${document.location}`}
                            />
                            {/* <meta
                                name="description"
                                content="Beginner friendly page for learning React Helmet."
                            /> */}
                        </Helmet>
                        <BreadCrumbs currentPageTitle={fetchedJob.title} />
                        {console.log(fetchedJob)}
                        <Grid container spacing={4}>
                            <Grid item xs={12} md={7}>
                                <JobDescription
                                    job={fetchedJob}
                                    botType={botType}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={5}
                                style={{ paddingLeft: 'calc(1em + 4vw)' }}
                            >
                                <RelatedRoles relatedRoles={relatedRoles} />
                            </Grid>
                        </Grid>
                        {ReactHtmlParser(
                            `<script type="application/ld+json">
                                {
                                    "@context": "https://schema.org",
                                    "@type": "JobPosting",
                                    "title": "${fetchedJob.title}",
                                    "hiringOrganization": {
                                        "@type": "Organization",
                                        "name": "LivePerson, Inc"
                                    },
                                    "industry": "${fetchedJob.categories[0]}",
                                    "jobLocation": {
                                        "@type": "Place",
                                            "address": {
                                            "@type": "PostalAddress",
                                            "addressCountry": "${fetchedJob.location['name']}"
                                            }
                                    },
                                    "directApply": "${fetchedJob.absolute_url}"
                                }
                            </script>`
                        )}
                    </>
                )}
            </>
        );
    }
}

function JobDescription(props) {
    const { job, isMobile } = props;
    const classes = useStyles();
    const mobileClasses = useMobileStyles();

    const htmlDecode = (input) => {
        var e = document.createElement('div');
        e.innerHTML = input;
        return e.childNodes[0].nodeValue;
    };

    const contentAndApply = () => {
        return (
            <>
                <Divider />
                {ReactHtmlParser(htmlDecode(job.content))}
                <Divider />
                <ApplyOptions {...props} />
            </>
        );
    };

    const applyButton = () => {
        return (
            // <a href="#application" style={{ textDecoration: 'none' }}>
            //     <ApplyButton jobID={job.greenhouse_public_id} quickApply={job.conversational} />
            // </a>
            <a
                href={`https://boards.greenhouse.io/embed/job_app?for=liveperson&token=${job.greenhouse_public_id}#application`}
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: 'none' }}
            >
                <ApplyButton
                    jobID={job.greenhouse_public_id}
                    quickApply={job.conversational}
                />
            </a>
        );
    };

    if (isMobile)
        return (
            <>
                <div className={mobileClasses.titleBar}>
                    <Typography variant="h3" style={{ marginBottom: '16px' }}>
                        {job.title}
                    </Typography>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <LocationOn
                            fontSize="small"
                            style={{
                                marginRight: '6px',
                                width: '24px',
                                color: colors.webKit.primary.white,
                            }}
                        />
                        <Typography variant="subtitle1">
                            {job.location.name}
                        </Typography>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <img
                                src={People}
                                alt="three people"
                                style={{
                                    marginRight: '6px',
                                    width: '24px',
                                    color: colors.webKit.primary.white,
                                    height: '24px',
                                    width: '24px',
                                }}
                            />
                            <Typography variant="subtitle1">
                                {job.departments[0] && job.departments[0].name}
                            </Typography>
                        </div>
                        {applyButton()}
                    </div>
                </div>
                {contentAndApply()}
            </>
        );

    return (
        <>
            <Grid
                container
                justifyContent="space-between"
                className={classes.titleBar}
            >
                <Grid item style={{ marginBottom: '0.75rem' }}>
                    <Typography variant="h3">{job.title}</Typography>
                    <Typography variant="subtitle1">
                        {/* {job.location.name + " | " + (job.career_stage) + "-Level | " + (job.categories && job.categories[0])} */}
                        {/* {job.location.name + " | " + (job.categories && job.categories[0])} */}
                        {job.location.name}
                        {job.location.name &&
                            job.departments &&
                            job.departments[0].name &&
                            ' | '}
                        {job.departments && job.departments[0].name
                            ? job.departments[0].name
                            : ''}
                    </Typography>
                </Grid>
                <Grid item>{applyButton()}</Grid>
            </Grid>
            {contentAndApply()}
        </>
    );
}

const useCompletedAppStyles = makeStyles(() => ({
    link: {
        textDecoration: 'none',
    },
    title: {
        fontWeight: 'bold',
        fontSize: '32px',
        lineHeight: '36px',
        marginBottom: '24px',
    },
    body: {
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        marginBottom: '32px',
    },
}));

function CompletedApp(props) {
    const { isMobile } = props;
    const classes = useCompletedAppStyles();

    return (
        <>
            <Typography variant="h4" style={{ marginBottom: '24px' }}>
                {' '}
                Thank you for applying!{' '}
            </Typography>
            <Typography
                variant="h5"
                style={{ marginBottom: isMobile ? '0px' : '32px' }}
            >
                Your application has been received. If there is a fit, someone
                will be getting back to you.
            </Typography>
            {!isMobile && (
                <Link to="/" className={classes.link}>
                    <Typography
                        variant="subtitle1"
                        style={{ marginBottom: '80px' }}
                    >
                        Back to Job Board
                    </Typography>
                </Link>
            )}
        </>
    );
}
