import { analyticsConstants } from './constants'
import { analyticsService } from './service'
import { eventBuilder } from './utility'

export const analyticsActions = {
    enterSearch,
    applyToJob,
    clickMatchedJob,
    sendAnalytics,
    requestSession
}

/**
 * Action to preserve backward compatability in storing search result as to limit what is being send as analytics event
 * (i.e. we don't want to send analytics event every time a key is pressed, but only when user scroll the page)
 * 
 * @param { Object } queries The object that contains all the filter and parameters to be searched for 
 * @returns 
 */
function enterSearch(queries) {
    return dispatch => {
        dispatch(saveSearch(queries))
    }

    function saveSearch(data){return {type: analyticsConstants.SAVE_SEARCH, data}} 
}

// DEPRECATED
function applyToJob(application) {
    return dispatch => {
        dispatch(request())
        analyticsService.trackApply(application).then(
            data => {
                dispatch(success(data))
            }
        ).catch(
            error => {
                dispatch(failure(error))
            }
        )
    }

    function request(){return {type: analyticsConstants.TRACK_APPLY_REQUEST, }} 
    function success(data) {return {type: analyticsConstants.TRACK_APPLY_SUCCESS, data}}
    function failure(error) {return {type: analyticsConstants.TRACK_APPLY_FAILURE, error}}
}

// DEPRECATED
/**
 * Action that record the state of the match click result, only recording the first result that the user clicked
 * @returns 
 */
function clickMatchedJob() {

    return dispatch => {
        dispatch(save())
    }

    function save(){return { type: analyticsConstants.TRACK_CLICK_JOB_SAVE }} 
}

/**
 * Action to request new session and to save the uid and analytics_token to localStorage
 * @param {function} func The action to be reattempt in case analytics_token has expired
 * @param {Object} funcArgs The function action's parameter if any 
 */
 function requestSession(func, funcArgs){
    return dispatch => {

        let payload = {}

        dispatch(request(payload))
        analyticsService.requestSession(payload).then(
            data => {

                // Saving UID to localstorage to be used later to link sessions
                let uid = data['uid']
                localStorage.setItem('uid', uid)
                localStorage.setItem("session_id", data['session_id'])
                localStorage.setItem('analytics_token', data['access_token'])

                dispatch(success(data))

                if(func && funcArgs){
                    // Re-requesting any analytics send because of failed sessions
                    dispatch(func(...Object.values(funcArgs)))
                }
            }
        ).catch(
            error => {
                dispatch(failure(error))
            }
        )
    }


    function request(payload){return {type: analyticsConstants.SESSION_REQUEST, payload}} 
    function success(data){return {type: analyticsConstants.SESSION_SUCCESS, data}}
    function failure(error){return {type: analyticsConstants.SESSION_FAILURE, error}}
}

/**
 * Action to send any / all analytics data to the backend and updating the analytics reducer with status of the analytics send requests
 * @param {String} name The name of the event
 * @param {String} type The type of the event ( i.e. click, result )
 * @param {String} source The current source of the event ( i.e. career )
 * @param {Object} custom_parameters Any additional data that accompanies the event
 */
function sendAnalytics(
    name,
    type,
    source,
    custom_parameters = {}
){
    
    return dispatch => {
        let payload = eventBuilder(name, type, source,custom_parameters)
        dispatch(request(payload))
        analyticsService.sendAnalytics(payload).then(
            data => {
                dispatch(success(data))
            }
        ).catch(
            error => {
                dispatch(failure(error))
                dispatch(requestSession(sendAnalytics, {name: name, type: type, source: source, custom_parameters: custom_parameters}))
            }
        )
    }

    function request(payload){return {type: analyticsConstants.SEND_ANALYTICS_REQUEST, payload}} 
    function success(data){return {type: analyticsConstants.SEND_ANALYTICS_SUCCESS, data}}
    function failure(error){return {type: analyticsConstants.SEND_ANALYTICS_FAILURE, error}}
}
