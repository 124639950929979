import React, { useState, useEffect, useRef } from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { analyticsActions } from '../redux/analytics'

// MUI 
import { Typography, Chip, Divider } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import { FilterList } from '../components/FilterList';
import { ApplyButton } from '../components/ApplyButton';
import { LocationOn } from '@mui/icons-material';
import { colors } from '../theme/colors';
import { PageLoading } from '../components/LoadingIndicator';
import { SmartApplyHero } from '../components/SmartApplyHero';
import { BreadCrumbs } from '../components/BreadCrumbs';

import People from '../assets/People.svg'
import { Link } from '../components/Utility/Link'
import { ErrorPage } from '../components/ErrorPage';
import { Pagination } from '../components/Pagination';
import { CurrentResume } from '../components/CurrentResume';

import {GOOGLE_ANALYTICS_ENABLED} from '../settings'

const useStyles = makeStyles((theme) => ({
    pagination: {
        float: 'right',
        '& .MuiPaginationItem-root': {
            color: colors.webKit.transparencies.lightTransparent,
            border: 'none',
            fontSize: '1.15em',
            fontWeight: 'bold',
        },
        '& .Mui-selected': {
            color: colors.webKit.primary.text,
        },

    },
    recTag: {
        margin: '1em 0 1em 0'
    },
    inputField: {
        width: '100%',
        margin: '1em',
        borderRadius: '5px',
        padding: '0.3em',
        // border: '1px solid #DFE0E5',
    },
    tableContainer: {
        minWidth: '50vw'
    },
    link: {
        textDecoration: 'none',
        color: colors.webKit.primary.text,
    },
    row: {
        width: '100%',
    },
    navlink: {
        textDecoration: 'none'
    },
    navLinkCurrent: {
        color: colors.webKit.primary.text,
    },
}));


export function SearchResults(props) {
    // Get search results from redux
    const { results: rows } = useSelector(state => state.search);
    const { error } = useSelector(state => state.jobs)
    const { resultsMsg, hasConversational } = useSelector(state => state.search);
    const { isMobile, width } = props;

    const classes = useStyles();

    const ENT_PP = isMobile ? 5 : 10; // short for Entries Per Page
    const [page, setPage] = useState(1)

    const handlePageChange = (value) => {
        setPage(value);
    }

    // whenever a user changes the parameters, set the page count back to 1.
    useEffect(() => {
        setPage(1);
    }, [rows])

    if (error)
        return (<ErrorPage isMobile={isMobile} pageType="homeNotFound" />)

    if (!rows)
        return <PageLoading />

    // Testing:
    // return ( <ul> {rows
    //     .sort((a,b) => {
    //     if (a.titles > b.titles)
    //         return 1
    //     if (a.titles < b.titles)
    //         return -1
    //     return 0
    // })
    // .map((row) => {return (<li>{row.titles}</li>)})} </ul> )
    // return ( <ul> {rows.map((row) => {return (<li> <a href={`https://careers.liveperson.com/job/${row.greenhouse_public_id}`}> {row.titles} </a> </li>)})} </ul> )


    // TODO move loading symbol into results, so that the filter is still visible?

    if (isMobile)
        return (
            <>
                <BreadCrumbs isMobile />
                <div>
                    <SmartApplyHero show={hasConversational} isMobile />
                    <FilterList {...props} />
                    <div style={{ display: 'block', width: '100%' }}>
                        <p> {resultsMsg} </p>
                        {rows.slice(page * ENT_PP - ENT_PP, page * ENT_PP).map((row) =>
                            <JobRow data={row} key={row.jid} />
                        )}
                        {
                            rows.length > ENT_PP &&
                            <Pagination isMobile
                                boundaryCount={1}
                                size={'large'}
                                className={classes.pagination}
                                onChange={handlePageChange}
                                page={page}
                                variant={"outlined"}
                                style={{ float: 'right' }}
                                // dividing rows.length (amount of jobs in the query) by ENT_PP gives the correct 
                                // amount of pages according to the amount of jobs. also, count does not need to be defined 
                                // in the state as its own variable as it's an extension of rows
                                count={Math.ceil(rows.length / ENT_PP)}
                            />
                        }
                    </div>
                </div>
            </>

        )

    return (
        <>
            <BreadCrumbs />
            <div style={{ display: 'flex', justifyItems: 'stretch' }} >
                <div style={{ display: 'block', width: '100%' }}>
                    <p>{rows.length} <span style={{ fontWeight: '700', maxHeight: 800 }}> Jobs </span> </p>
                    <p> {resultsMsg} </p>
                    {rows.slice(page * ENT_PP - ENT_PP, page * ENT_PP).map((row) =>
                        <JobRow data={row} key={row.jid} />
                    )}
                    {rows.length > 0 &&
                        <Pagination
                            boundaryCount={1}
                            size={'large'}
                            onChange={handlePageChange}
                            page={page}
                            className={classes.pagination}
                            variant={"outlined"}
                            // dividing rows.length (amount of jobs in the query) by ENTRIES_PER_PAGE gives the correct 
                            // amount of pages according to the amount of jobs. also, count does not need to be defined 
                            // in the state as its own variable as it's an extension of rows
                            count={Math.ceil(rows.length / ENT_PP)}
                        />
                    }
                </div>
                <div style={{ display: 'block', marginLeft: 'calc(1em + 4vw)' }}>
                    <CurrentResume />
                    <SmartApplyHero show={hasConversational} isMobile />
                    <Typography variant='h4'>
                        Advanced Filters
                    </Typography>
                    <FilterList {...props} />
                </div>
            </div>
        </>
    )
}


function JobRow(props) {
    const { data } = props;
    const classes = useStyles();
    const dispatch = useDispatch();

    const [width, setWidth] = useState(window.innerWidth)
    const ref = useRef(null)
    // Get window size for responsive design
    const handleWindowSizeChange = () => {
        if (ref && ref.current && ref.current.clientWidth !== 0) {
            setWidth(ref.current.clientWidth)
        }
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange)
    }, [])

    const { matchClicked } = useSelector(state => state.analytics)

    if (!data)
        return <> </>
    
    const handleJobClick = () => {
        let job = data;
        let matched = !!data.match
        if (matched && !matchClicked){
            // Send Analytics to backend and record the first result that was clicked on
            dispatch(analyticsActions.matchClicked())
            dispatch(analyticsActions.sendAnalytics("view-resumeai-matched-role", "click", "career", {"greenhouse_public_id": job['greenhouse_public_id']}))
        }
        // there is no ga in window on staging or local
        if (job.conversational) {
            // Track click on smart apply job with Google Analytics
            if(GOOGLE_ANALYTICS_ENABLED)
                window.ga('send', 'event', 'apply', 'click', 'view-smart-apply-role')
            dispatch(analyticsActions.sendAnalytics("view-smart-apply-role", "click", "career", {}))
        }
        else {
            // Track click on regular job with Google Analytics
            if(GOOGLE_ANALYTICS_ENABLED)
                window.ga('send', 'event', 'apply', 'click', 'view-regular-role')
            dispatch(analyticsActions.sendAnalytics("view-regular-role", "click", "career", {}))
        }

    }

    if (width < 450)
    return (
        <div ref={ref} onClick={handleJobClick}>
            <Link to={`/job/${data.greenhouse_public_id}`} className={classes.link} >
                <div className={classes.row} ref={ref} onClick={() => window.scrollTo(0, 0)}>
                    <div className={classes.recTag}>
                        {/* <Typography variant="subtitle2">{data.career_stage} </Typography> */} {/* removed on request by Dev */}
                        <MatchTags match={data.match} />
                    </div>
                    <Typography variant="h3" style={{ paddingBottom: '8px' }}> {data.titles} </Typography>

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <LocationOn fontSize='small' style={{ marginRight: '6px', width: '24px', color: colors.webKit.primary.lightGrey }} color={colors.webKit.primary.lightGrey} />
                        <Typography variant="subtitle1" style={{ fontWeight: 400 }}>
                            {data.location.name ? data.location.name : data.location }
                        </Typography>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img src={People} style={{ marginRight: '6px', width: '24px', color: colors.webKit.primary.text, height: '24px', width: '24px' }} alt="three people" />
                        <Typography variant="subtitle1" style={{ fontWeight: 400 }}>
                            {data.departments.map((d) => { return (d.name) })}
                        </Typography>
                    </div>
                    <div style={{ marginTop: '10px' }}>
                        <ApplyButton quickApply={data.conversational} />
                    </div>
                </div>
            </Link>
            <Divider />
        </div>
    )

    return (
        <div ref={ref} onClick={handleJobClick} >
            <Link to={`/job/${data.greenhouse_public_id}`} className={classes.link} style={{ flexDirection: 'row' }} >
                
                <div className={classes.recTag}>
                    {/* <Typography variant="subtitle2">{data.career_stage} </Typography> */} {/* removed on request by Dev */}
                    <MatchTags match={data.match} />
                </div>
                <div className={classes.row} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '0px' }} >
                    <div onClick={() => window.scrollTo(0, 0)}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography variant="h3"> {data.title} </Typography>
                        </div>

                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <LocationOn fontSize='small' style={{ marginRight: '6px', width: '24px', color: colors.webKit.primary.lightGrey }} color={colors.webKit.primary.lightGrey} />
                            <Typography variant="subtitle1" style={{ fontWeight: 400 }}>
                                {data.location.name}
                            </Typography>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <img src={People} style={{ marginRight: '6px', color: colors.webKit.primary.lightGrey, height: '24px', width: '24px' }} alt="three people" />
                            <Typography variant="subtitle1" style={{ fontWeight: 400 }}>
                                {data.departments.map((d) => { return (d.name) })}
                            </Typography>
                        </div>
                    </div>
                    <div>
                        <ApplyButton quickApply={data.conversational} />
                    </div>
                </div>

            </Link>
            <Divider />
        </div>
    )
}

function MatchTags(props) {
    const { match } = props

    if (match === 'best') {
        return <Chip label="Best Match" variant="outlined" color="secondary" />
    } else if (match === 'Good') {
        // TODO change to not return 'good' in skillsai
        return <Chip label="Good Match" variant="outlined" color="primary" />
    }


    return (<></>)
}