import React from "react"
import { Link } from '../Utility/Link'

// MUI
import { Card, CardActions, CardContent, CardHeader, Typography, Button } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { LocationOn } from "@mui/icons-material";

// Custom
import { colors } from "../../theme/colors";
import People from "../../assets/PeopleDark.svg"

const useStyles = makeStyles(() => ({
    card: {
        padding: '25px 32px'
    },
    cardHeader: {
        padding: 0
    },
    cardContent: {
        padding: '16px 0px'
    },
    cardActions: {
        padding: '0px'
    },
    titleText: {
        color: colors.webKit.primary.text
    },
    descriptionText: {
        color: colors.webKit.primary.text
    },
    detailsLinkButton: {
        paddingLeft: '20px',
        paddingRight: '20px',
    },
    link: {
        textDecoration: 'none'
    }
}))

export default function RelatedRoles(props) {
    const { horizontal, relatedRoles } = props;

    if (relatedRoles.length === 0)
        return (<> </>)
    return (<>

        <Typography variant="h4" style={{ marginBottom: '24px' }}>
            Related Roles
        </Typography>
        {/* removed because the functionality doesn't exist */}
        {/* <Typography variant="subtitle1" style={{ marginBottom: '20px' }}>
                (View All)
            </Typography> */}
        {horizontal ?
            <div style={{ display: 'flex', gap: '32px' }}>
                <RelatedRolesContent {...props} />
            </div>
            :
            <RelatedRolesContent {...props} />
        }
    </>)
}

function RelatedRolesContent(props) {
    const { relatedRoles } = props
    const classes = useStyles();

    return (<>
        {relatedRoles.map((job) => {
            return (
                <>
                    <Card className={classes.card} key={job.jid}>
                        <CardHeader className={classes.cardHeader}
                            title={
                                <Typography variant="h5" className={classes.titleText}>
                                    {job.title}
                                </Typography>
                            } />

                        <CardContent className={classes.cardContent}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <LocationOn fontSize='small' style={{ marginRight: '6px', width: '24px', color: colors.webKit.primary.text }} />
                                <Typography variant="subtitle2" style={{fontWeight: 400}} >
                                    {job.location.name}
                                </Typography>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <img src={People} alt="three people" style={{ marginRight: '6px', width: '24px', height: '24px', width: '24px' }} />
                                <Typography variant="subtitle2" style={{fontWeight: 400}} >
                                    {job.departments[0] && job.departments[0].name}
                                </Typography>
                            </div>
                        </CardContent>

                        <CardActions className={classes.cardActions}>
                            <Link to={`/job/${job.greenhouse_public_id}`} className={classes.link}>
                                <Button variant="outlined" color="primary"
                                    // Scroll user to top of page on new job
                                    onClick={() => window.scrollTo(0, 0)}
                                    style={{ height: '42px', padding: '0 16px' }}
                                >
                                    Job Details
                                </Button>
                            </Link>
                        </CardActions>
                    </Card>
                </>
            )
        })}
    </>)
}