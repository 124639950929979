import React, { useEffect, useRef, useState } from 'react';
import { Button, Grid, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { searchActions } from '../redux/search';
import { jobActions } from '../redux/job';
import { Search } from '@mui/icons-material';

import { colors } from '../theme/colors';
import { analyticsActions, processSearch } from '../redux/analytics';
import { ANALYTICS_ENABLED } from '../settings';

// DESKTOP
const useStyles = makeStyles((theme) => ({
    textFieldInput: {
        height: '48px',
        backgroundColor: colors.webKit.primary.white,
        color: colors.webKit.primary.text,
        border: '1px solid ' + colors.webKit.primary.lightGrey,
        borderRadius: '30px',
        fontSize: '20px',
        paddingLeft: '24px',
        marginBottom: '40px'
    },
    placeholder: {
        '&::placeholder': {
            textAlign: 'left',
            color: colors.webKit.primary.text,
            opacity: '100%'
        }
    },
    link: {
        textDecoration: 'none'
    },
    searchIcon: {
        color: colors.webKit.primary.text,
        height: '33px',
        width: '33px',
    },
    mobileSearchIcon: {
        color: colors.webKit.primary.text,
        height: '25px',
        width: '25px',
    }
}));

export function SearchBar(props) {
    const [searchQuery, setSearchQuery] = useState('');
    const { isMobile } = props;
    const timer = useRef(null);

    const dispatch = useDispatch();
    const classes = useStyles();
    const navigate = useNavigate();

    // get jobs from selector
    const { jobs, loading } = useSelector(state => state.jobs)
    const { queries, results } = useSelector(state => state.search)

    const { query: queryParam } = useSelector(state => state.queryParam)

    // Getting jobs when site starts up
    useEffect(() => {
        // If there are no jobs, and jobs are not currently loading in
        if (!jobs && !loading) {
            dispatch(jobActions.getAllJobs())

            // Get the query from the url `?q=foo`
            let urlSearchParam = new URLSearchParams(props.location.search);
            if (urlSearchParam) {
                let q = urlSearchParam.get("q");
                setSearchQuery(q)
            }
        }
    }, [])

    // Initialize search results after the jobs have loaded in
    useEffect(() => {
        if (jobs && !loading) {
            let newQuery = { ...queries }

            // If there is a url query, add it to queries
            // so that it affects search results
            if (searchQuery) {
                newQuery.titleQuery = searchQuery
            }
            dispatch(searchActions.setSearchQueries(newQuery))
            dispatch(searchActions.searchJobs(jobs, newQuery))
        }
    }, [jobs])

    // Send initial search query from URL to search analytics
    // This can tell us what people searched or clicked on in the landing page
    const [initialAnalyticsSent, setInitialAnalyticsSent] = useState()
    useEffect(() => {
        if (!initialAnalyticsSent && queries && results !== null) {
            if (queries.titleQuery && queries.titleQuery !== '' && queries.titleQuery !== 'null') {
                dispatch(analyticsActions.enterSearch(queries))  // Saving search query into reducer
                dispatch(analyticsActions.sendAnalytics( 
                    "submit-search-query",
                    "submission",
                    "career",
                    processSearch(queries, results.map((job) => { return ({greenhouse_public_id: job.greenhouse_public_id })})))
                )
            }
            setInitialAnalyticsSent(true)
        }
    }, [results])

    // Called each time the query string changes (live)
    const handleQueryChange = (e) => {
        let newQuery = e.target.value
        setSearchQuery(newQuery)

        let newQueries = { ...queries, titleQuery: newQuery }

        if (timer.current) {
            clearTimeout(timer.current)
        }
        if (newQuery === "" && queries && jobs) {
            dispatch(searchActions.setSearchQueries(newQueries))
            dispatch(searchActions.searchJobs(jobs, newQueries))
        }
        else {
            let timeout = setTimeout(() => {
                dispatch(searchActions.setSearchQueries(newQueries))
                dispatch(searchActions.searchJobs(jobs, newQueries))
            }, 300)
            timer.current = timeout
        }
    }

    // Called on clicking 'Search' button or when pressing 'Enter'
    const handleSearchSubmit = () => {
        let newQueries = { ...queries, titleQuery: searchQuery }
        dispatch(searchActions.setSearchQueries(newQueries))
        dispatch(searchActions.searchJobs(jobs, newQueries))

        // Add search query to url
        let encodedQuery = encodeURIComponent(searchQuery).toString()
        if(encodedQuery === 'null') encodedQuery = ''
        let uri = "/?q=" + encodedQuery
        if (queryParam) {
            // Keeping the queryParam consistent across all pages
            // In case such as referral link

            let query = new URLSearchParams(queryParam)
            let questionParam = query.get('q', null)

            if (questionParam === null) {
                uri = queryParam + "&q=" + encodeURIComponent(searchQuery)
            }
            else {
                query.delete('q')
                query.set('q', searchQuery)
                uri = "/?" + query.toString()
            }

        }

        // history.push(uri)
        navigate(uri)
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSearchSubmit();
        }
    }

    return (
        <div style={{display: 'flex', justifyContent: 'center'}}>
            <div style={{ maxWidth: '1920px', marginRight: props.margin, marginLeft: props.margin, }} >
                <Grid container wrap='nowrap' alignItems="center" >
                    <Grid item style={{ flexGrow: 1 }} >
                        <TextField
                            // label={(props.width < 440) ? "KEYWORD" : "KEYWORD, CATEGORY, OR JOB TITLE"}
                            placeholder='Keyword, category, or job title'
                            style={{ 
                                width: '600px',
                                maxWidth: props.width - 20
                            }}

                            InputProps={{
                                classes: { input: classes.placeholder },
                                className: classes.textFieldInput,
                                // style: { marginTop: isMobile ? '20px 0px': '40px 0px' },
                                disableUnderline: true,
                                endAdornment:
                                    <InputAdornment>
                                        <IconButton onClick={handleSearchSubmit} size="large">
                                            <Search className={isMobile ? classes.mobileSearchIcon : classes.searchIcon} />
                                        </IconButton>
                                    </InputAdornment>
                            }}
                            InputLabelProps={{ className: classes.textFieldLabel }}
                            onChange={handleQueryChange}
                            value={searchQuery || ""}
                            onKeyPress={handleKeyPress}
                        />
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}