export const searchService = {
    searchJobs,
};
// var thesaurus = require("thesaurus");
var FuzzyMatching = require('fuzzy-matching');

async function searchJobs(jobs, queries, resultsMsg) {
    let {
        titleQuery = '',
        categoriesQuery = [],
        remoteQuery = false,
        countryQuery = [],
        cityQuery = [],
        careerStageQuery = [],
        showRecJobs = undefined,
        showSmartApply = false,
    } = queries;

    //////////// PROCESS QUERIES ////////////
    let generalQuery =
        ' ' + (titleQuery || '').toLowerCase().replace(/.,\(\)-_!/g, '') + ' ';

    // Remove certain keywords that people might use
    generalQuery = generalQuery.replace(' jobs', ' ');
    generalQuery = generalQuery.replace(' job', ' ');
    generalQuery = generalQuery.replace(' positions', ' ');
    generalQuery = generalQuery.replace(' position', ' ');
    generalQuery = generalQuery.replace(' roles', ' ');
    generalQuery = generalQuery.replace(' role', ' ');
    generalQuery = generalQuery.replace(' work', ' ');
    generalQuery = generalQuery.replace(' opening', ' ');
    generalQuery = generalQuery.replace(' posting', ' ');
    generalQuery = generalQuery.replace(' career', ' ');
    generalQuery = generalQuery.replace(' post', ' ');

    generalQuery = generalQuery.replace(' the ', ' ');
    generalQuery = generalQuery.replace(' of ', ' ');
    generalQuery = generalQuery.replace(' at ', ' ');
    generalQuery = generalQuery.replace(' a ', ' ');
    generalQuery = generalQuery.replace(' in ', ' ');

    generalQuery = generalQuery.replace(' chatbot ', 'chat bot');

    // If the general query includes any text surrounded by double quotes
    // then that text inside the quotes must be exactly matched
    let exactQueries = [];
    if (generalQuery && generalQuery.includes('"')) {
        let count = (generalQuery.match(/"/g) || []).length;
        if (count % 2 === 0) {
            exactQueries = extractExactText(generalQuery);
        }
        generalQuery = generalQuery.replace(/"/g, '').replace(/'/g, '');
    }

    // Split general query into an array of words
    generalQuery = generalQuery.split(' ');
    // If there were extra space characters, remove the resulting empty strings
    generalQuery = generalQuery.filter((word) => word !== '');

    let processedQueries = {
        titleQuery: titleQuery,
        remoteQuery: remoteQuery,
        countryQuery: countryQuery,
        cityQuery: cityQuery,
        categoriesQuery: categoriesQuery,
        exactQueries: exactQueries,
        generalQuery: [generalQuery.join(' ')],
        showRecJobs: showRecJobs,
        careerStageQuery: careerStageQuery,
        showSmartApply: showSmartApply,
        synonyms: false,
        fuzzy: false,
    };
    //////////// END PROCESS QUERIES ////////////

    return searchJobsProcessed(jobs, queries, processedQueries, resultsMsg);
}

async function searchJobsProcessed(
    jobs,
    queries,
    processedQueries,
    resultsMsg
) {
    const {
        titleQuery,
        countryQuery,
        cityQuery,
        categoriesQuery,
        generalQuery,
        synonyms,
        fuzzy,
    } = processedQueries;

    /////////// SORT and FILTER jobs by given queries and sort by relevance ////////////
    let relevances = {};
    let irrelevant = [];
    let results = jobs
        .sort((jobA, jobB) => {
            let relevanceA = jobRelevanceToQuery(jobA, processedQueries);
            let relevanceB = jobRelevanceToQuery(jobB, processedQueries);

            relevances[jobA.jid] = relevanceA;
            relevances[jobB.jid] = relevanceB;

            if (relevanceA <= 0) irrelevant.push(jobA.jid);
            if (relevanceB <= 0) irrelevant.push(jobB.jid);

            let compare = relevanceA - relevanceB;
            if (compare < 0) return 1;
            if (compare > 0) return -1;
            if (jobA.titles > jobB.titles) return 1;
            if (jobA.titles < jobB.titles) return -1;
            return 0;
        })
        .filter((job) => {
            if (!irrelevant.includes(job.jid)) return true;
        });
    /////////// END SORT and FILTER jobs by given queries ////////////

    ////////// LOOSEN search queries if no results //////////
    if (results.length === 0) {
        // if the entire phrase is not found, then split it by word
        if (generalQuery[0].includes(' ') && Array.isArray(generalQuery)) {
            let msg =
                'No matching "' +
                titleQuery +
                '" jobs found. Showing jobs matching part of your query.';
            return searchJobsProcessed(
                jobs,
                queries,
                {
                    ...processedQueries,
                    generalQuery: generalQuery[0].split(' '),
                },
                msg
            );
        }

        // Get jobs that match the category the generalQuery's text would match
        if (categoriesQuery.length === 0 && generalQuery) {
            let generalCategory = deduceJobCategory(generalQuery.join(' '))[0];
            if (generalCategory !== 'Other') {
                let msg =
                    'No matching "' +
                    titleQuery +
                    '" jobs found. Showing similar ' +
                    generalCategory +
                    ' jobs.';
                return searchJobsProcessed(
                    jobs,
                    queries,
                    {
                        ...processedQueries,
                        titleQuery: titleQuery,
                        categoriesQuery: [generalCategory],
                    },
                    msg
                );
            }
            return [results, 'No matching "' + titleQuery + '" jobs found'];
        }

        // then go through removing locations as needed
        if (cityQuery.length > 0) {
            let msg =
                'No matching "' +
                titleQuery +
                '" jobs found in "' +
                cityQuery +
                '". Showing jobs in other locations.';
            if (!titleQuery) {
                msg =
                    'No matching jobs found in "' +
                    cityQuery +
                    '". Showing jobs in other locations.';
            }
            return searchJobsProcessed(
                jobs,
                queries,
                { ...processedQueries, titleQuery: titleQuery, cityQuery: [] },
                msg
            );
        } else if (countryQuery.length > 0) {
            let msg =
                'No matching "' +
                titleQuery +
                '" jobs found in "' +
                countryQuery +
                '". Showing jobs in other locations.';
            if (!titleQuery) {
                msg =
                    'No matching jobs found in "' +
                    countryQuery +
                    '". Showing jobs in other locations.';
            }

            return searchJobsProcessed(
                jobs,
                queries,
                {
                    ...processedQueries,
                    titleQuery: titleQuery,
                    countryQuery: [],
                },
                msg
            );
        } else if (!fuzzy) {
            let msg =
                'No matching "' +
                titleQuery +
                '" jobs found. Showing similar jobs.';
            return searchJobsProcessed(
                jobs,
                queries,
                { ...processedQueries, fuzzy: true },
                msg
            );
        } else if (!synonyms) {
            let msg =
                'No matching "' +
                titleQuery +
                '" jobs found. Showing similar jobs.';
            return searchJobsProcessed(
                jobs,
                queries,
                { ...processedQueries, synonyms: true },
                msg
            );
        } else {
            // changed from generalQuery to titleQuery to match the user's input instead of force-lowercasing it as we are using quotes
            return [results, 'No matching "' + titleQuery + '" jobs found'];
        }
    }
    ////////// END LOOSEN search queries //////////

    let hasConversational = false;
    for (let job of results) {
        if (job.conversational) {
            hasConversational = true;
            break;
        }
    }

    return [results, resultsMsg, hasConversational];
}

function jobRelevanceToQuery(job, queries) {
    let { exactQueries, generalQuery, fuzzy, synonyms } = queries;

    // Above 0 by default so that if no queries, it is still returned
    let RELEVANCE = 10;

    let location = job.location.name
        ? job.location.name
              .toLowerCase()
              .replace('-', '')
              .replace('(', '')
              .replace(')', '')
              .replace('.', '')
              .replace(',', '')
              .replace('_', '')
              .replace(',', '')
        : ' ';
    let title = job.title
        ? job.title
              .toLowerCase()
              .replace('-', '')
              .replace('(', '')
              .replace(')', '')
              .replace('.', '')
              .replace(',', '')
              .replace('_', '')
              .replace(',', '')
        : ' ';

    if (
        !jobMatchesFilters(
            { ...job, title: title, location: location },
            queries
        )
    )
        return 0;

    /*
        At this point, if there are filters enabled in the query, the job matches all of them
        Now we must get the relevance based on the search bar contents

        If a job does not match the search bar contents in any way, it is removed from the results
    */

    ////////// Search Bar Matching //////////
    let type = job.type
        ? job.type
              .toLowerCase()
              .replace('-', '')
              .replace('(', '')
              .replace(')', '')
              .replace('.', '')
              .replace(',', '')
              .replace('_', '')
              .replace(',', '')
        : ' ';

    // Departments must be processed because it is written by recruiters
    let departments = '';
    if (job.departments && job.departments[0]) {
        departments = job.departments[0].name
            ? job.departments[0].name
                  .toLowerCase()
                  .replace('-', '')
                  .replace('(', '')
                  .replace(')', '')
                  .replace('.', '')
                  .replace(',', '')
                  .replace('_', '')
                  .replace(',', '')
            : ' ';
    }

    // set job property arrays to lowercase
    let jobCategories = [];
    if (job.categories) {
        for (let category of job.categories) {
            jobCategories.push(category.toLowerCase());
        }
    }

    let jobCountries = [];
    if (job.countries) {
        for (let country of job.countries)
            jobCountries.push(country.toLowerCase());
    }

    let jobCities = [];
    if (job.cities) {
        for (let city of job.cities) jobCities.push(city.toLowerCase());
    }

    let careerStage = job.career_stage || '';

    const processedJob = {
        location: location,
        jobCategories: jobCategories,
        careerStage: careerStage,
        title: title,
        type: type,
        departments: departments,
        jobCountries: jobCountries,
        jobCities: jobCities,
    };

    if (exactQueries.length > 0) {
        for (let phrase of exactQueries) {
            let [matched, relevance] = phraseInJob(phrase, processedJob);
            RELEVANCE += relevance;

            // If the exact phrase was not found in any of the job attributes
            // then it should not be returned in the search
            if (!matched) return 0;
        }
    }

    let generalMatched = false;
    if (generalQuery.length > 0) {
        for (let index in generalQuery) {
            let word = generalQuery[index];
            let [matched, relevance] = phraseInJob(word, processedJob);

            if (matched) {
                // index in generalQuery increases the relevance
                RELEVANCE += relevance * 0.5 * (generalQuery.length - index);
                generalMatched = true;
            } else {
                if (fuzzy) {
                    // If the word didn't match, try fuzzy matching
                    let [fuzzMatch, fuzzRelevance] = phraseInJob(
                        word,
                        processedJob,
                        true
                    );
                    if (fuzzMatch) {
                        // index in generalQuery increases the relevance
                        RELEVANCE +=
                            fuzzRelevance * 0.5 * (generalQuery.length - index);
                        generalMatched = true;
                    }
                } else {
                    if (synonyms) {
                        // // If the word didn't fuzzy match, try some synonyms
                        // let synonyms = thesaurus.find(word)
                        // for (let j in synonyms) {
                        //     if (j > 5)
                        //         break
                        //     let synonym = synonyms[j]
                        //     let [synMatch, synRelevance] = phraseInJob(synonym, processedJob)
                        //     // index in generalQuery increases the relevance
                        //     // i.e. the first words matter more
                        //     if (synMatch) {
                        //         RELEVANCE += synRelevance * 0.5 * (generalQuery.length - index)
                        //         generalMatched = true
                        //         break
                        //     }
                        // }
                    }
                }
            }
        }

        if (!generalMatched) return 0;
    }

    return RELEVANCE;
}

function jobMatchesFilters(job, filters) {
    let {
        remoteQuery,
        countryQuery,
        cityQuery,
        categoriesQuery,
        showRecJobs,
        showSmartApply,
        careerStageQuery,
    } = filters;

    // Filters are exact matches, so they return 0 if not a match
    if (showRecJobs === true) {
        // If the job does not have a 'match' attribute,
        // then it was not matched to the given resume
        // in other words, it was not given by the resume match AI
        if (job.match === undefined) return false;
    }

    if (showSmartApply === true) {
        if (!job.conversational) return false;
    }

    if (remoteQuery) {
        // Job must be remote
        let remoteMatch =
            job.location.includes('remote') || job.title.includes('remote');
        if (!remoteMatch) return false;
    }
    if (careerStageQuery.length > 0) {
        // Job must match at least one of the careers stages in the career stage filter's list
        let careerStageMatch = true;
        careerStageMatch = careerStageQuery.includes(job.career_stage || '');
        if (!careerStageMatch) return false;
    }
    if (countryQuery.length > 0) {
        // Job must match at least one of the countries in the country filter's list
        let countryMatch = false;
        if (job.countries) {
            for (let country of countryQuery) {
                if (job.countries.includes(country)) {
                    countryMatch = true;
                    break;
                }
            }
        }
        if (!countryMatch) return false;
    }
    if (cityQuery.length > 0) {
        // Job must match at least one of the cities in the city filter's list
        let cityMatch = false;
        if (job.cities) {
            for (let city of cityQuery) {
                if (job.cities.includes(city)) {
                    cityMatch = true;
                    break;
                }
            }
        }
        if (!cityMatch) return false;
    }
    if (categoriesQuery.length > 0) {
        // Job must match at least one of the catgories in the category filter's list
        let categoriesMatch = false;
        if (job.categories) {
            for (let category of categoriesQuery) {
                if (job.categories.includes(category)) {
                    categoriesMatch = true;
                    break;
                }
            }
        }
        if (!categoriesMatch) return false;
    }
    return true;
}

function phraseInJob(phrase, processedJob, fuzzy = false) {
    const {
        title,
        departments,
        location,
        jobCategories,
        jobCountries,
        jobCities,
    } = processedJob;

    let matched = false;
    let relevance = 0;

    if (fuzzy) {
        let titleArr = title.split(' ');
        let fm = new FuzzyMatching(titleArr);
        if (fm.get(phrase).distance > 0.8) {
            relevance += 20 * fm.get(phrase).distance;
            matched = true;
            return [matched, relevance];
        }
        fm = new FuzzyMatching(jobCategories);
        if (fm.get(phrase).distance > 0.8) {
            relevance += 10;
            matched = true;
            return [matched, relevance];
        }
        fm = new FuzzyMatching(
            jobCountries.concat(jobCities).concat(location.split(' '))
        );
        if (fm.get(phrase).distance > 0.8) {
            relevance += 10;
            matched = true;
            return [matched, relevance];
        }
        let depArr = departments.split(' ');
        fm = new FuzzyMatching(depArr);
        if (fm.get(phrase).distance > 0.8) {
            relevance += 3;
            matched = true;
            return [matched, relevance];
        }
    } else {
        if (title.includes(phrase)) {
            // Give more relevance if the word search bar is an entire word
            // i.e. "account" instead of "acc"
            if (title.includes(' ' + phrase + ' ')) relevance += 20;
            // Give more relevance based on the length of the word if it is
            // only part of a word
            else relevance += 2 * phrase.length;
            matched = true;

            return [matched, relevance];
        }
        for (let category of jobCategories) {
            if (category.includes(phrase)) {
                relevance += 10;
                matched = true;
                return [matched, relevance];
            }
        }
        if (location.includes(phrase)) {
            relevance += 10;
            matched = true;
            return [matched, relevance];
        }
        for (let country of jobCountries) {
            if (country.includes(phrase)) {
                relevance += 10;
                matched = true;
                return [matched, relevance];
            }
        }
        for (let city of jobCities) {
            if (city.toLowerCase().includes(phrase)) {
                relevance += 10;
                matched = true;
                return [matched, relevance];
            }
        }
        if (departments.includes(phrase)) {
            relevance += 3;
            matched = true;
            return [matched, relevance];
        }
    }

    return [matched, relevance];
}

// Gets an array of all phrases that are between double quotes from a string
function extractExactText(str) {
    const re = /"(.*?)"/g;
    const result = [];
    let current;
    while ((current = re.exec(str))) {
        result.push(current.pop().replace(/'/g, ''));
    }
    return result.length > 0 ? result : [str.replace(/'/g, '')];
}

function deduceJobCategory(title) {
    const sales = 'Sales';
    const customer_success = 'Customer Success';
    const sales_eng = 'Sales Engineers';
    const operations = 'Operations';
    const prod_prog_manager = 'Product & Program Management';
    const engineering = 'Engineering';
    const data_ml = 'Data Science & ML';
    const design = 'Design';
    const marketing_comms = 'Marketing & Comms';
    const enablement = 'Enablement';
    const finance_accounting = 'Finance & Accounting';
    const people = 'People';
    const legal = 'Legal';
    const sec_IT = 'Security & IT';
    const customer_exp = 'Customer Experience';

    title =
        ' ' +
        title.toLowerCase().replace('-', '').replace('(', '').replace(')', '') +
        ' ';

    if (title.includes('recruiter') || title.includes('talent')) {
        if (title.includes('operations')) return [operations];
        else return [people];
    }

    if (title.includes(' hr ')) {
        return [people];
    }

    if (title.includes('security')) return [sec_IT];

    if (
        title.includes('product man') ||
        title.includes('program man') ||
        title.includes('project man') ||
        title.includes('project coord') ||
        (title.includes('implementation man') && title.includes('project'))
    )
        return [prod_prog_manager];

    if (title.includes('engineer')) {
        if (title.includes('solutions')) return [customer_success];
        else if (title.includes('director')) return [prod_prog_manager];
        else if (title.includes('big data')) {
            if (title.includes('backend') || title.includes('back end'))
                return [engineering];
            return [data_ml];
        } else if (title.includes('site reliabil')) {
            if (title.includes('software dev')) return [engineering];
            else return [sec_IT];
        } else if (title.includes('ux')) return [design];
        else if (title.includes('sales')) return [sales_eng];
        else if (title.includes(' ai ') && !title.includes('convers'))
            return [data_ml];
        else return [engineering];
    }

    if (title.includes('sde'))
        return [
            engineering,
            'Software Developer',
            'Software',
            'Computer Science',
        ];

    if (title.includes('develop'))
        if (title.includes('sales')) return [sales];
        else
            return [
                engineering,
                'Software Developer',
                'Software',
                'Computer Science',
            ];
    if (title.includes('r&d')) return [engineering];

    if (title.includes('consultant')) return [sales_eng];

    if (title.includes('sales '))
        if (title.includes('enablement')) return [enablement];
        else if (title.includes('engineer')) return [sales_eng];
        else if (title.includes('operations')) return [operations];
        else return [sales];

    if (
        title.includes('marketing') ||
        title.includes('content') ||
        title.includes('digital strat') ||
        title.includes('growth')
    )
        return [marketing_comms];

    if (title.includes('account'))
        if (
            title.includes('accounting') ||
            title.includes('accounts receive') ||
            title.includes('accountant')
        )
            return [finance_accounting];
        else if (title.includes('exec')) return [sales];
        else if (title.includes('account manager'))
            if (title.includes('technical')) return [customer_exp];
            else return [sales];

    if (title.includes('client partner')) return [sales];

    if (title.includes('service knowledge')) return [customer_exp];

    if (title.includes('support')) return [customer_exp];

    if (title.includes('solution'))
        if (title.includes('consultant')) return [sales_eng];
        else return [customer_success];

    if (title.includes('customer'))
        if (title.includes('journey')) return [customer_exp];
        else return [customer_success];

    if (
        title.includes('implementation') ||
        title.includes('transcript') ||
        title.includes('tranf')
    )
        return [customer_success];

    if (title.includes('scien') || title.includes('taxonomist'))
        return [data_ml];
    if (title.includes('data') && title.includes('analy')) return [data_ml];

    if (title.includes('design') || title.includes('ux')) return [design];

    if (title.includes('chief of staff')) return [prod_prog_manager];

    if (title.includes('operations')) return [operations];

    if (title.includes('legal') || title.includes('counsel')) return [legal];

    if (title.includes('billing') || title.includes('financ'))
        return [finance_accounting];

    if (
        title.includes('regional') ||
        title.includes('partner director') ||
        (title.includes('global') && title.includes('partner'))
    )
        return [sales];

    if (title.includes('data architect')) return [sec_IT];

    if (title.includes('vp') && title.includes('platform'))
        return [prod_prog_manager];

    if (title.includes('monitor')) return [operations];

    if (title.includes('grc')) return [people];

    return ['Other'];
}
