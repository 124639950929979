import { Button } from "@mui/material";
import { useEffect, useRef, useState } from "react"
import { useNavigate } from 'react-router-dom';

export function EmbeddedFile(props) {
    const containerRef = useRef();
    const [width, setWidth] = useState(500);
    let navigate = useNavigate();

    useEffect(() => {
        if (containerRef && containerRef.current && containerRef.current.clientWidth) {
            setWidth(containerRef.current.clientWidth)
        }
        window.addEventListener('resize', handleWindowSizeChange)
    }, [])

    const handleWindowSizeChange = () => {
        if (containerRef && containerRef.current && containerRef.current.clientWidth) {
            setWidth(containerRef.current.clientWidth)
        }
    }

    return (
        <div ref={containerRef}>
            <Button
                variant="contained"
                color="primary"
                onClick={() => {
                    // if (navigate.length > 1)
                    navigate(-1)
                    // else
                    // navigate('/')
                }}
                style={{ marginBottom: '10px' }}
            >
                Back
            </Button>
            <embed src={props.file} width={width} height={1000}></embed>
        </div>
    )
}