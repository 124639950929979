import { Button } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

import ChatIcon from '../assets/chatIcon.svg'

const useStyles = makeStyles((theme) => ({
    applyButton: {
        wrap: 'nowrap',
        minWidth: '84px',
        maxWidth: '84px',
        maxHeight: '40px'
    },
    smartApplyButton: {
        wrap: 'nowrap',
        minWidth: '150px',
        maxWidth: '150px',
        maxHeight: '40px'
    },
    chatIcon: {
        height: '20px',
        width: '20px',
        margin: '10px 0px 10px 10px'
    }
}))

export function ApplyButton(props) {
    const classes = useStyles()
    const { quickApply } = props;

    const handleApply = () => {
        // jobID
    }

    const handleQuickApply = () => {
        // jobID
    }

    // if (quickApply) {
    //     return (
    //         <Button className={classes.smartApplyButton}
    //             variant="contained" color="primary"
    //             onClick={handleQuickApply} 
    //             startIcon={<img src={ChatIcon} alt="chat bubble" className={classes.chatIcon}/>}
    //             >
    //             Smart Apply
    //         </Button>
    //     )
    // }

    return (
        <Button className={classes.applyButton}
            variant="outlined" color="primary"
            onClick={handleApply} >
            Apply
        </Button>
    )
}