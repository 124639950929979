import { Grid, InputLabel, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { DropDown } from "../DropDown";

const useStyles = makeStyles(() => ({
    inputLabel: {
        fontWeight: 400,
        whiteSpace: 'normal',
        paddingBottom: '4px'
    }
}));

export function EEOSurvey(props) {
    const classes = useStyles();
    const { answers, setAnswers } = props;

    const handleChange = (event) => {
        let value = event.target.value
        let field = event.target.name
        let newAnswers = {
            ...answers,
            [field]: value
        }
        setAnswers(newAnswers)
    }

    const handleMultiselect = (event) => {
        let value = event.target.value
        let field = event.target.name
        let newAnswers
        if (value.includes(noSelfIdentify)) {
            if (value[value.length - 1] === noSelfIdentify) {
                newAnswers = {
                    ...answers,
                    [field]: [noSelfIdentify]
                }
            }
            else {
                value.splice(value.indexOf(noSelfIdentify), 1)

                newAnswers = {
                    ...answers,
                    [field]: value
                }
            }
        }
        else {
            newAnswers = {
                ...answers,
                [field]: value
            }
        }
        setAnswers(newAnswers)
    }

    const dropDownProps = {
        placeholder: placeholder,
        fullWidth: true,
        onChange: handleChange
    }

    return (
        <>
            <Grid item xs={12}>
                <Typography variant="h2">
                    Voluntary Self Identification
                </Typography>
                <p style={{ marginBottom: '0' }}>
                    If you’d like, please share with us some additional information about yourself (e.g. your race, ethnicity, sexual orientation, gender identity, and disability information). It’s completely voluntary. This information will be used to help us know more about people who interview with us and ensure representation in who we consider in our efforts to provide an equal opportunity to everyone who wants to work at LivePerson.
                </p>
            </Grid>
            <Grid container item xs={12} md={6} justifyContent='space-between' direction='column'>
                <InputLabel className={classes.inputLabel} htmlFor={'gender'}>
                    {/* What gender identity do you most closely identify with? */}
                    Gender
                </InputLabel>
                <DropDown id="gender"
                    value={answers.gender}
                    options={genders}
                    {...dropDownProps}
                />
            </Grid>
            {/* <Grid container item xs={12} md={6} justifyContent='space-between' direction='column'>
                <InputLabel className={classes.inputLabel} htmlFor={'transgender'}>
                    Are you a person of transgender experience?
                </InputLabel>
                <DropDown id="transgender"
                    value={answers.transgender}
                    options={yesNo}
                    {...dropDownProps}
                />
            </Grid> */}
            <Grid container item xs={12} md={6} justifyContent='space-between' direction='column'>
                <InputLabel className={classes.inputLabel} htmlFor={'ethnicity'}>
                    {/* Please select the race and/or ethnic identity you identify with. */}
                    Please identify your race

                </InputLabel>
                <DropDown id="ethnicity"
                    value={answers.ethnicity}
                    // value={answers.ethnicity || []}
                    options={ethnicity}
                    // multiselect
                    // onChange={handleMultiselect}
                    {...dropDownProps}
                />
            </Grid>
            {/* <Grid container item xs={12} md={6} justifyContent='space-between' direction='column'>
                <InputLabel className={classes.inputLabel} htmlFor={'orientation'}>
                    What sexual orientation do you most closely identify with?
                </InputLabel>
                <DropDown id="orientation"
                    value={answers.orientation}
                    options={orientation}
                    {...dropDownProps}
                />
            </Grid> */}
            <Grid container item xs={12} md={6} justifyContent='space-between' direction='column'>
                <InputLabel className={classes.inputLabel} htmlFor={'disability'}>
                    {/* Do you live with a disability (as outlined by the ADA)? */}
                    Disability Status
                </InputLabel>
                <DropDown id="disability"
                    value={answers.disability}
                    options={disability}
                    {...dropDownProps}
                />
            </Grid>
            <Grid container item xs={12} md={6} justifyContent='space-between' direction='column'>
                <InputLabel className={classes.inputLabel} htmlFor={'veteran'}>
                    {/* Are you a veteran/have you served in the military? */}
                    Veteran Status
                </InputLabel>
                <DropDown id="veteran"
                    value={answers.veteran}
                    options={veteran}
                    {...dropDownProps}
                />
            </Grid>
        </>
    )
}

const placeholder = "Please Select"
const noSelfIdentify = "Decline To Self Identify"
const noAnswer = "I don’t wish to answer"

const genders = [
    {
        label: "Male",
        value: 1
    },
    {
        label: "Female",
        value: 2
    },
    {
        label: noSelfIdentify,
        value: 3
    },
    // "Non-binary",
]

// const yesNo = [
//     "Yes",
//     "No",
//     noAnswer
// ]

const ethnicity = [
    {
        label: "American Indian or Alaskan Native",
        value: 1
    },
    {
        label: "Asian",
        value: 2
    },
    {
        label: "Black or Afrian American",
        value: 3
    },
    {
        label: "Hispanic or Latino",
        value: 4
    },
    {
        label: "White",
        value: 5
    },
    {
        label: "Native Hawaiian or Other Pacific Islander",
        value: 6
    },
    {
        label: "Two or More Races",
        value: 7
    },
    {
        label: noSelfIdentify,
        value: 8
    },
    // "Middle Eastern or North African",
]

const veteran = [
    {
        label: "I am not a protected veteran",
        value: 1
    },
    {
        label: "I identify as one or more of the classifications of a protected veteran",
        value: 2
    },
    {
        label: noAnswer,
        value: 3
    }

    // "Active Reserve",
    // "Inactive Reserve",
    // "Other Protected Veteran",
    // "Retired",
    // "Unspecified Veteran",
    // "Vietnam Era Veteran",
    // "Vietnam Veteran and Other Protected Veteran",
    // "No military service",
    // nonDisclose
    
]

const disability = [
    {
        label: "Yes, I have a disability, or have a history/record of having a disability",
        value: 1
    },
    {
        label: "No, I don’t have a disability, or a history/record of having a disability",
        value: 2
    },
    {
        label: noAnswer,
        value: 3
    }
]

// const orientation = [
//     "Bisexual",
//     "Gay",
//     "Heterosexual",
//     "Lesbian",
//     "Queer",
//     "Other",
//     nonDisclose
// ]