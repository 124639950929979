import { Grid, Icon, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { NavigateBefore, NavigateNext } from "@mui/icons-material";
import { colors } from "../theme/colors";

import { Link } from '../components/Utility/Link'

const useStyles = makeStyles(() => ({
    navLink: {
        textDecoration: 'none',
        color: colors.webKit.primary.text,
    },
    navLinkCurrent: {
        color: colors.webKit.primary.text,
        fontWeight: 400
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '32px',
        marginTop: '32px',
    },
    mobileContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '16px',
        marginTop: '16px',
    },
    arrowAndText: {
        display: 'flex',
        alignItems: 'center'
    },
    arrowIcon: {
        color: colors.webKit.primary.text,
        opacity: '50%'
    }
}))

const useMobileStyles = makeStyles(() => ({
    link: {
        textDecoration: 'none',
    },
    navLink: {
        color: colors.webKit.primary.text,
        fontWeight: 300
    }
}))

// const webflowURL = "https://marlon-career.webflow.io/company/careers"
const webflowURL = "https://liveperson.com/company/careers"

export function BreadCrumbs(props) {
    const { currentPageTitle, isMobile } = props
    const classes = useStyles();
    const mobileClasses = useMobileStyles();

    // If currentPageTitle is not defined, we are on the 'Job Board' page

    if (isMobile) {
        return (
            <Grid container className={classes.mobileContainer}>
                <Grid item className={classes.arrowAndText}>
                    <Icon><NavigateBefore /></Icon>
                    {currentPageTitle ?
                        <Link to="/" className={mobileClasses.link} >
                            <Typography variant="h5" className={mobileClasses.navLink} >
                                Job Board
                            </Typography>
                        </Link>
                        :
                        <a href={webflowURL} className={mobileClasses.link} >
                            <Typography variant="h5" className={mobileClasses.navLink} >
                                LivePerson Careers
                            </Typography>
                        </a>
                    }
                </Grid>
            </Grid>
        )
    }

    return (
        <Grid container className={classes.container}>
            <Grid item className={classes.arrowAndText}>
                <a href={webflowURL} className={classes.navLink}>
                    <Typography variant="h5"> LivePerson Careers </Typography>
                </a>
                {currentPageTitle ?
                    <Icon className={classes.arrowIcon}><NavigateNext /></Icon>
                    :
                    <Icon><NavigateNext /></Icon>
                }
            </Grid>

            <Grid item className={classes.arrowAndText}>
                {currentPageTitle ?
                    <>
                        <Link to="/" className={classes.navLink}>
                            <Typography variant="h5"> Job Board </Typography>
                        </Link>
                        <Icon><NavigateNext /></Icon>
                    </>
                    :
                    <Typography variant="h5" className={classes.navLinkCurrent}>
                        Job Board
                    </Typography>
                }
            </Grid>

            {currentPageTitle &&
                <Grid item>
                    <Typography variant="h5" className={classes.navLinkCurrent}>
                        {currentPageTitle}
                    </Typography>
                </Grid>
            }
        </Grid>
    )
}