import { candidateActionConstants } from './constants'
import { candidateActionServices } from './services'

export const candidateActions = {
    queryAvailableAction,
    deleteAvailableData,
}

function queryAvailableAction (email){

    return dispatch => {
        dispatch(request())
    
        candidateActionServices.queryCandidateAction(email).then(
            payload => {
                dispatch(success(payload))
            }
        ).catch(
            error => {
                dispatch(failure(error))
            }
        )
    }

    function request(){return {type: candidateActionConstants.QUERY_ACTION_REQUEST}}
    function success(payload){return {type: candidateActionConstants.QUERY_ACTION_SUCCESS, payload}}
    function failure(error){return {type: candidateActionConstants.QUERY_ACTION_FAILURE, error}}
}

function deleteAvailableData(email){

    return dispatch => {
        dispatch(request())

        candidateActionServices.deleteAvailableData(email).then(
            payload => {
                dispatch(success(payload))
                dispatch(queryAvailableAction(email))
            }
        ).catch(
            error => {
                dispatch(failure(error))
            }
        )
    }

    function request(){return {type: candidateActionConstants.DELETE_APPLICATIONS_REQUEST}}
    function success(payload){return {type: candidateActionConstants.DELETE_APPLICATIONS_SUCCESS, payload}}
    function failure(error){return {type: candidateActionConstants.DELETE_APPLICATIONS_FAILURE, error}}
}