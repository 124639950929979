import { Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { colors } from "../../theme/colors";
import FastForward from '../../assets/fast-forward-button.svg'
import TargetHit from '../../assets/target-hit.svg'
import SpeechBallon from '../../assets/speech-balloon.svg'

const useStyles = makeStyles(() => ({
    triangle: {
        // overflow: 'hidden',
        width: 0,
        height: 0,
        borderTop: '15px solid transparent',
        borderBottom: '15px solid ' + colors.webKit.lavenders.lavender20,
        borderRight: '20px solid transparent',
        borderLeft: '20px solid transparent',
    },
    container: {
        backgroundColor: colors.webKit.lavenders.lavender20,
        margin: '0px 0px 10px 0px',
        borderRadius: '10px'
    },
    titleBar: {
        padding: '10px',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        textAlign: 'center',
    },
    content: {
        padding: '24px 32px',
        display: 'flex',
        flexDirection: 'column',
        gap: '8px'
    },
    iconContainer: {
        backgroundColor: colors.webKit.primary.white,
        borderRadius: 100,
        display: 'flex',
        padding: '3px',
        marginRight: '10px'
    },
    icon: {
        margin: '3px',
        height: '18px',
        width: '18px',
    },
    bulletPoint: {
        display: 'flex',
        alignItems: 'center',
    }


}))

export function SmartApplyExplainer() {
    const classes = useStyles();
    return (<>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <div className={classes.triangle}></div>
        </div>
        <div className={classes.container}>
            <div className={classes.titleBar}>
                <Typography variant="h3" style={{ fontSize: '20px' }}>
                    In a 3 minute conversation, you:
                </Typography>
            </div>
            <div className={classes.content}>
                <Bullet bulletIcon={FastForward}
                    text="Fast track your initial screening"
                />
                <Bullet bulletIcon={TargetHit}
                    text="Automatically apply to similar roles"
                />
                <Bullet bulletIcon={SpeechBallon}
                    text="Get answers to your basic questions"
                />
            </div>
        </div>
    </>)
}

function Bullet(props) {
    const { bulletIcon, text } = props;
    const classes = useStyles();
    return (
        <div className={classes.bulletPoint}>
            <div className={classes.iconContainer}>
                <img src={bulletIcon} className={classes.icon} />
            </div>
            <Typography>
                {text}
            </Typography>
        </div>
    )
}