import { Button, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { useEffect, useState } from 'react'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate } from 'react-router'

import { SKILL_ROOT } from '../settings'
import { LoadingIndicator } from '../components/LoadingIndicator'
import { jobActions } from '../redux/job'

import { FileUpload } from '../components/FileUpload'
import UploadCloudIcon from "../assets/UploadCloud.svg"
import { colors } from "../theme/colors"
import { ErrorPage } from "./ErrorPage"
import { resumeActions } from "../redux/resume"

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
    },
    uploadButtonContainer: {
        marginTop: '32px',
        width: '100%',
        background: colors.webKit.gradients.bluePink1,
        borderRadius: '16px',
        padding: '2px',
        maxWidth: '1000px',
    },
    uploadButton: {
        backgroundColor: colors.webKit.primary.white,
        borderRadius: '16px',
        maxWidth: '1000px',
        width: '100%',
        ':&hover': {
            backgroundColor: colors.webKit.primary.white,
            opacity: '100%'
        }
    },
    uploadImg: {
        width: '160px',
        marginBottom: '38px'
    },
    uploadDescriptionText: {
        whiteSpace: 'pre-wrap'
    },
    boldText: {
        fontWeight: 700
    },
    browseButtonContainer: {
        background: colors.webKit.gradients.bluePink1,
        padding: '2px',
        borderRadius: 100,
        marginTop: '24px',
        width: '270px',
        height: '40px',
        '& .MuiButton-label': {
            '&:hover': {
                background: 'none'
            }
        }
    },
    browseButton: {
        backgroundColor: colors.webKit.primary.white,
        borderRadius: 100,
        width: '100%',
        height: '100%',
        '&:hover': {
            color: colors.webKit.primary.white
        },
    },
    subtitle: {
        fontSize: '20px',
        lineHeight: '28px',
        fontWeight: 300,
        marginTop: '24px'
    },
    fileUpload: {
        padding: '72px 32px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    }
}))

const useMobileStyles = makeStyles(() => ({
    uploadButton: {
        backgroundColor: colors.webKit.primary.white,
        borderRadius: '16px',
    },
    browseButtonContainer: {
        background: colors.webKit.gradients.bluePink1,
        padding: '2px',
        borderRadius: 100,
        marginTop: '24px',
        width: '170px',
        height: '40px'
    },
    browseButton: {
        backgroundColor: colors.webKit.primary.white,
        borderRadius: 100,
        width: '100%',
        height: '100%'
    },
    fileUpload: {
        padding: '32px 32px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    }
}));

export function ResumeMatchBanner(props) {
    const { isMobile } = props;
    const [loading, setLoading] = useState(false)
    const [redirect, setRedirect] = useState(false)
    const [gotJobList, setGotJobList] = useState(false)
    const classes = useStyles();
    const mobileClasses = useMobileStyles();
    const dispatch = useDispatch()

    const { jobs, loading: jobsLoading } = useSelector(state => state.jobs)
    const [error, setError] = useState(false)

    const getResponse = async (formData) => {
 
        // Retrieving UID and Analytics Token to send to skillsAI such that
        // It can submit events and link it to relevant sessions and user
        let uid = localStorage.getItem('uid')
        let analytics_token = localStorage.getItem('analytics_token')
        
        return axios.post(`${SKILL_ROOT}/magic`,
            formData,
            {
                headers:{
                    "UID": uid,
                    "Token": analytics_token
                }
            }
        )
    }

    const handleUpload = (currentFile) => {
        let url = window.URL.createObjectURL(currentFile)

        dispatch(resumeActions.setResume(url, currentFile))
        let formData = new FormData()

        formData.append("resume", currentFile)

        setLoading(true)

        getResponse(formData).then(
            response => {
                let data = response.data.data
                let job_list = []
                for (let key in data) {
                    job_list.push(data[key])
                }
                let payload = {
                    job_list: job_list
                }

                setGotJobList(true)

                // // FOR TESTING VIEWS
                // let payload = {
                //     job_list: ['3291076', '3189439', '2278298', '2890984', '3422914', '3422915', '3422912', '3422909', '3422916', '3422913', '3400956', '3422910', '3587247']
                // }

                dispatch(jobActions.getSkillAIJobs(payload, jobs))

            }
        ).catch(
            err => {
                setError(err)
            }
        )
    }

    useEffect(() => {
        if (gotJobList && !jobsLoading && jobs) {
            setLoading(false)
            setRedirect(true)
        }
    }, [jobsLoading])

    if (error)
        return <ErrorPage isMobile={isMobile} pageType="skillsAi" />

    if (redirect)
        return <Navigate push to="/" />

    return (
        <div className={classes.container}>
            <Typography variant="h1">
                Get Matched by your Resume
            </Typography>
            <Typography variant="body1" className={classes.subtitle}>
                Upload your resume and we will curate roles for you
            </Typography>

            <div className={classes.uploadButtonContainer}>
                <div className={isMobile ? mobileClasses.uploadButton : classes.uploadButton}>
                    {loading ?
                        <div className={isMobile ? mobileClasses.fileUpload : classes.fileUpload}>
                            <LoadingIndicator />
                            <Typography className={classes.uploadDescriptionText} variant="body1">
                                This will take one to two minutes
                            </Typography>
                        </div>
                        :
                        <FileUpload handleUpload={handleUpload} accept="application/pdf" fullWidth>
                            <div className={isMobile ? mobileClasses.fileUpload : classes.fileUpload}>
                                <img src={UploadCloudIcon} className={classes.uploadImg} alt="upload cloud" />
                                <Typography className={classes.uploadDescriptionText} variant="body1">
                                    {isMobile ?
                                        <> Click to browse and choose a <span className={classes.boldText}>pdf</span> file </>
                                        :
                                        <> Drag and drop or click to browse and choose a <span className={classes.boldText}>pdf</span> file </>
                                    }
                                </Typography>
                                <Typography className={classes.uploadDescriptionText} variant="body1">
                                    This will take one to two minutes
                                </Typography>
                                <div className={isMobile ? mobileClasses.browseButtonContainer : classes.browseButtonContainer} >
                                    <Button component="span" className={isMobile ? mobileClasses.browseButton : classes.browseButton} >
                                        Upload
                                    </Button>
                                </div>
                            </div>
                        </FileUpload>
                    }
                </div>
            </div>
        </div>
    )
}