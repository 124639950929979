import makeStyles from '@mui/styles/makeStyles';
import { colors } from "../theme/colors";


const useStyles = makeStyles(() => ({
    text: {
        width: '100%',
        textAlign: 'center',
        borderBottom: '1px solid ' + colors.webKit.primary.white,
        lineHeight: '0.1em',
        margin: '10px 0 20px'
    },
    span: {
        padding: '0 10px',
    }
}))


export function TextDivider(props) {
    const { text, width, color, margin } = props;
    const classes = useStyles();

    return (<>
        {/* margin breaks the line, this works though */}
        <div style={{ height: margin }} />
        <div className={classes.text}
            style={{
                borderBottom: '1px solid ' + color,
                width: width || '100%'
            }}
        >
            <span className={classes.span} >
                {text}
            </span>
        </div>
        <div style={{ height: margin }} />
    </>)
}