import React from 'react';

// MUI 
import { Typography, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import usePagination from "@mui/material/usePagination/usePagination";

// custom
import { colors } from "../theme/colors";

const useStyles = makeStyles(() => ({
    pageButton: {
        padding: '0px 10px',
        fontWeight: 700,
        '&:hover': {
            color: colors.webKit.primary.electricBlue,
            textDecoration: 'underline'
        }
    },
    prevNext: {
        color: colors.webKit.primary.text,
        fontWeight: 400,
        opacity: 1,
        cursor: 'pointer',
        '&:hover': {
            color: colors.webKit.primary.electricBlue,
            textDecoration: 'underline',
            "text-shadow": '1px 0px ' + colors.webKit.primary.electricBlue
        }
    },
    prevNextDisabled: {
        cursor: 'default',
        opacity: 0.5,
    },
    ellipsis: {
        padding: '0px 10px',
        fontWeight: 400,
        cursor: 'default'
    },
    mobileEllipsis: {
        padding: '0px',
        fontWeight: 400,
        cursor: 'default'
    },
}));

export function Pagination(props) {
    const { count, isMobile } = props;
    const classes = useStyles();

    const { items } = usePagination({
        count: count,
        handleChange: props.onChange
    })

    return (
        <Grid container style={{ display: 'flex', justifyContent: 'flex-end' }} >
            {items.map(({ page, type, selected, onClick, ...item }, index) => {
                let children = null;

                if (type === 'start-ellipsis' || type === 'end-ellipsis') {
                    if (isMobile)
                        children = (<Typography className={classes.mobileEllipsis}>...</Typography>)
                    else
                        children = (<Typography className={classes.ellipsis}> ... </Typography>)
                } else if (type === 'page') {
                    if (isMobile && index > 2 && index < 6 && !selected)
                        children = (<> </>)
                    else
                        children = (
                            <Typography
                                className={classes.pageButton}
                                onClick={(e) => { onClick(e); window.scrollTo(0, 0); props.onChange(page) }}
                                style={{
                                    color: selected ? colors.webKit.primary.electricBlue
                                        : colors.webKit.primary.text,
                                    cursor: selected ? 'default' : 'pointer'
                                }}
                                {...item}
                            >
                                {page}
                            </Typography>
                        );
                } else {
                    children = (
                        <Typography
                            className={`${item.disabled ? classes.prevNextDisabled : classes.prevNext}`}
                            onClick={(e) => {
                                onClick(e);
                                if (!item.disabled){
                                    e.preventDefault();
                                    // window.scrollTo(0, 0);
                                    props.onChange(page)
                                }
                            }}
                            style={{
                                padding: type === 'previous' ?
                                    "0px 20px 0px 0px"
                                    : "0px 0px 0px 20px"
                            }}
                            {...item}>
                            {captitalizeFirstLetter(type)}
                        </Typography>
                    );
                }
                return <Grid item key={index}>{children}</Grid>;
            })}
        </Grid>
    )
}

function captitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
}