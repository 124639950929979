import axios from 'axios'

import { API_ROOT } from '../../settings'

export const candidateActionServices = { 
    deleteAvailableData,
    queryCandidateAction,
}

async function deleteAvailableData(email){
    let response = await axios.get(
        `${API_ROOT}/updateCandidatePreference`,
        {
            params: {
                email: email
            }
        }
    )

    return response.data

}

async function queryCandidateAction(email){
    let response = await axios.get(
        `${API_ROOT}/queryCandidateAction`,
        {
            params: {
                email: email
            }
        })
    return response.data.title_list
}