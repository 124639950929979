import { createTheme, responsiveFontSizes, adaptV4Theme } from "@mui/material";
import { colors } from "./colors";

export const theme = responsiveFontSizes(createTheme(adaptV4Theme({
    shape: {
        borderRadius: 5,
    },
    pallette: {
        type: 'dark',
        primary: {
            main: 'blue'
        },
        secondary: {
            main: 'red'
        }
    },
    typography: {
        h1: {
            fontFamily: 'Space Grotesk',
            fontWeight: 700,
            fontSize: '48px',
            lineHeight: '1.083' // 52
        },
        h2: {
            fontFamily: 'Space Grotesk',
            fontWeight: 700,
            fontSize: '36px',
            lineHeight: '1.222'// 44
        },
        h3: {
            fontFamily: 'Space Grotesk',
            fontWeight: 700,
            fontSize: '24px',
            lineHeight: '1.333' //32
        },
        h4: {
            fontFamily: 'Space Grotesk',
            fontWeight: 700,
            fontSize: '21px',
            lineHeight: '1.333' // 28
        },
        h5: {
            fontFamily: 'Space Grotesk',
            fontWeight: 700,
            fontSize: '18px',
            lineHeight: '1.556' // 28
        },
        h6: {
            fontFamily: 'Space Grotesk',
            fontWeight: 700,
            fontSize: '12px',
            lineHeight: '1.333', // 16
        },
        subtitle1: {
            fontFamily: 'Inter',
            fontSize: '16px',
            lineHeight: '1.5', // 24
            fontWeight: 700
        },
        subtitle2: {
            fontFamily: 'Inter',
            fontSize: '14px',
            lineHeight: '1.429', // 20
            fontWeight: 700
        },
        caption: {
            fontFamily: 'Inter',
            fontSize: '12px',
            lineHeight: '1.333', // 16
            fontWeight: 400
        },
        body1: {
            fontFamily: 'Inter',
            fontSize: '18px',
            lineHeight: '1.556', // 28
            fontWeight: 400
        },
        body2: {
            fontFamily: 'Inter',
            fontSize: '16px',
            lineHeight: '1.5', // 24
            fontWeight: 400,
        },
    },
    overrides: {
        MuiDivider: {
            root: {
                backgroundColor: colors.webKit.primary.divider,
                height: '1px',
                marginTop: '32px',
                marginBottom: '32px',
            },
            light: {
                backgroundColor: colors.webKit.primary.divider,
                height: '1px',
                marginTop: '8px',
                marginBottom: '8px',
            }
        },
        MuiListItemIcon: {
            root: {
                minWidth: '2rem'
            }
        },
        MuiListItem: {
            dense: {
                margin: 0,
            },
            button: {
                margin: 0,
                padding: 0,
            },
        },
        MuiListItemText: {
            primary: {
                color: colors.webKit.primary.text,
                // h5
                fontFamily: 'Space Grotesk',
                fontWeight: 700,
                fontSize: '18px',
                lineHeight: '1.556' // 28
            },
            secondary: {
                color: colors.webKit.primary.text,
                // subtitle1
                fontFamily: 'Inter',
                fontSize: '16px',
                lineHeight: '1.5', // 24
                fontWeight: '400',
                margin: '0px 0'
            }
        },
        MuiButton: {
            root: {
                padding: '12px 8px',
                whiteSpace: 'nowrap',

                textTransform: 'none',
                borderRadius: '4px',
                letterSpacing: 'normal',
                boxShadow: 'none'
            },
            label: {
                fontFamily: 'Space Grotesk',
                fontWeight: 700,
                fontSize: '16px',
                lineHeight: '1.5', // 25
            },
            containedPrimary: {
                backgroundColor: colors.darkTheme.blue.default,
                borderRadius: '100px',
                boxShadow: 'none',
                '&:hover': {
                    backgroundColor: colors.darkTheme.blue.dark
                },
                '&:focus': {
                    backgroundColor: colors.darkTheme.blue.dark,
                    // boxShadow: `0px 0px 0px 2px ${colors.darkTheme.focus.inner}, 0px 0px 0px 4px ${colors.darkTheme.focus.outer}`
                },
                '&:active': {
                    backgroundColor: colors.darkTheme.blue.darker,
                    boxShadow: 'none',
                },
                '&:disabled': {
                    color: colors.webKit.primary.text,
                    backgroundColor: 'rgba(12, 80, 255, 0.53)'
                }
            },
            containedSecondary: {
                borderRadius: '100px',
                backgroundColor: colors.webKit.primary.liveOrange,
                boxShadow: 'none',
                '&:hover': {
                    backgroundColor: colors.webKit.hover.orangeDark
                },
            },
            containedSizeLarge: {
                fontSize: '1rem'
            },
            outlinedSecondary: {
                color: colors.webKit.primary.text,
                border: '1px solid ' + colors.webKit.primary.text,
                borderRadius: '100px',
                textDecoration: 'none',
                padding: '9px',
                '&:hover': {
                    backgroundColor: colors.webKit.primary.white,
                    color: colors.darkTheme.blue.darkest,
                    border: ''
                },
            },
            outlinedPrimary: {
                border: '2px solid ' + colors.webKit.primary.electricBlue,
                borderRadius: '100px',
                color: colors.webKit.primary.electricBlue,
                textTransform: 'none',
            }
        },

        // For Text Fields
        MuiFormLabel: {
            root: {
                // When focused, label remains white instead of blue, and underlined
                '&$focused': {
                    color: colors.webKit.primary.text,
                    borderBottom: '1px solid' + colors.darkTheme.focus.outer,
                },
            },
        },
        MuiInputLabel: {
            root: {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                fontSize: '16px',
                // height: '21px'
            },
            // Center the placeholder/label vertically
            formControl: {
                // original: transform: 'translate(0, 24px) scale(1)'
                // transform: 'translate(0, 100%) scale(1)',
            }
        },
        MuiFormControl: {
            root:  {
                border: 'none'
            }

        },
        MuiInput: {
            // Make input bolded
            input: {
                fontSize: '16px',
                lineHeight: '24px',
            }
        },
        MuiOutlinedInput: {
            root: {
                color: colors.webKit.primary.text,
                borderRadius: '4px',
                border: '2px solid ' + colors.webKit.primary.lightGrey,
                minWidth: '244px',
            },
            input: {
                padding: "0.5em",
            },
            notchedOutline: {
                border: 'none'
            }
        },
        MuiMenu: {
            list: {
                padding: 0,
            }
        },
        MuiMenuItem: {
            root: {
                whiteSpace: 'normal',
                padding: '0px 10px',
                border: '1px solid ' + colors.webKit.primary.lightGrey,

                '&:focus': {
                    backgroundColor: colors.webKit.primary.veryLightGrey,
                    border: '2px solid ' + colors.webKit.primary.lightBlue,
                },
                '&:hover': {
                    backgroundColor: colors.webKit.primary.lightBlue,
                },
                '&$selected': {
                    backgroundColor: colors.webKit.primary.veryLightGrey,
                }
            }
        },
        MuiCheckbox: {
            root: {
                color: colors.webKit.primary.text,
                '&$checked': {
                    color: colors.webKit.primary.electricBlue
                },
            },
            colorSecondary: {
                '&$checked': {
                    color: colors.webKit.primary.electricBlue
                },
            },
        },

        MuiRadio: {
            root: {
                color: colors.webKit.primary.text,
                '&$checked': {
                    color: colors.webKit.primary.electricBlue
                },
            },
            colorSecondary: {
                '&$checked': {
                    color: colors.webKit.primary.electricBlue
                },
            },
        },
        MuiFormHelperText: {
            root: {
                color: colors.webKit.primary.text
            }
        },
        MuiPaper: {
            // For fullscreen dialog on mobile
            root: {
                backgroundColor: colors.webKit.primary.white
            }
        },
        MuiCard: {
            root: {
                backgroundColor: colors.webKit.transparencies.darkTransparent,
                padding: '10px',
                marginBottom: '32px',
                borderRadius: '16px',
                boxShadow: 'none'
            }
        },
        MuiChip: {
            root: {
                backgroundColor: colors.webKit.transparencies.lightTransparent,
                border: "none"
            },
            label: {
                color: colors.webKit.primary.white,
            },
            outlinedPrimary: {
                backgroundColor: colors.webKit.transparencies.lightTransparent,
                border: 'none'
            },
            outlinedSecondary: {
                backgroundColor: colors.webKit.primary.liveOrange,
                border: 'none'
            },
        }
    },
})),
    {
        factor: 3,
    })