import { searchConstants } from './'

const initialState = {
    queries: {
        // For testing:
        // categoriesQuery: ['Other'],

        categoriesQuery: [],
        careerStageQuery: [],
        cityQuery: [],
        countryQuery: [],
        remoteQuery: false,
        titleQuery: '',
        showRecJobs: null,
        showSmartApply: null
    },
    results: null,
    resultsMsg: '',
    hasConversational: false
}

export function search(state = initialState, action){
    switch(action.type){
        case searchConstants.SET_SEARCH_RESULTS:
            return{
                ...state,
                results: action.results,
                resultsMsg: '',
            }
        case searchConstants.SET_SEARCH_QUERIES:
            return{
                ...state,
                queries: action.queries,
                resultsMsg: '',
            }
        case searchConstants.SET_RESULTS_MSG:
            return {
                ...state,
                resultsMsg: action.message,
            }
        case searchConstants.SET_HAS_CONVERSATIONAL:
            return {
                ...state,
                hasConversational: action.hasConversational
            }

        default:
            return state
    }
}