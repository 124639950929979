import { 
    utilityConstants
} from './'


const initialState = {
    query: ""
}

export function queryParam(state = initialState, action){
    switch(action.type){
        case utilityConstants.SET_QUERY_PARAM:
            return {
                query: action.query
            }

        default:
            return state

    }
}