import React from 'react'

import { CircularProgress } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    loading: {  
        top: "50%", 
        left: "50%"
    },
    pageLoading: { 
        position: "fixed", 
        top: "50%", 
        left: "50%"
    }
}))

export function LoadingIndicator(props) {
    const classes = useStyles();

    return <CircularProgress className={classes.loading} {...props}/>
}

export function PageLoading() {
    const classes = useStyles();

    return <div style={{height: '300px'}} ><CircularProgress className={classes.pageLoading} /> </div>
}