import { resumeConstants } from './'

const initialState = {
    blobURL: null,
    fileName: null,
    fileModified: null,
}

export function resume(state = initialState, action) {
    switch(action.type) {
        case resumeConstants.SET_RESUME:
            return {
                ...state,
                blobURL: action.data.blobURL,
                fileName: action.data.fileName,
                fileModified: action.data.fileModified
            }
        default:
            return state
    }
}